import { ClickAwayListener, Tooltip } from "@mui/material"
import { useState } from "react";
import { Link } from "react-router-dom"

const Footer = () => {

    const [open, setOpen] = useState(false);

    const handleToggleTolTip = () => {
        setOpen(!open);
    };

    return (
        <div className="p-3 bg-light">
            <div style={{ overflowX: 'hidden', backgroundImage: 'linear-gradient(transparent, transparent)', borderTopLeftRadius: '2rem', borderTopRightRadius: '2rem' }}>
                <footer className="w-100 py-4 flex-shrink-0 p-3">
                    <div className="container-fluid py-4">
                        <div className="row justify-content-evenly gy-4 gx-5">
                            <div className="col-lg-2 col-md-6">
                                <h4 className="mb-3 fw-bold">devSemble</h4>
                                <ul className="list-unstyled text-muted">
                                    <li>
                                        <Link to="/about-us" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">About Us</h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">Blogs</h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">Contact Us</h6>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h4 className="mb-3 fw-bold">Community</h4>
                                <ul className="list-unstyled text-muted">
                                    <li>
                                        <Link to="/students" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">Students</h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/freelancers" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">Freelancers</h6>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/project-listers" className="text-decoration-none text-dark">
                                            <h6 className="m-0 mx-auto mb-3">Project Listers</h6>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h4 className="mb-3 fw-bold">Support</h4>
                                <ul className="list-unstyled text-muted">
                                    {/* <ClickAwayListener onClickAway={handleToggleTolTip}>
                                        <li>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleToggleTolTip}
                                                open={open}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Coming Soon"
                                            >
                                                <button onClick={() => handleToggleTolTip()} className="btn bg-transparent border-none shadow-none m-0 p-0 btn-sm text-decoration-none text-dark">
                                                    <h6 className="m-0 mx-auto mb-3">Help</h6>
                                                </button>
                                            </Tooltip>
                                        </li>
                                    </ClickAwayListener> */}
                                    <li>
                                        <button className="btn bg-transparent border-none shadow-none m-0 p-0 btn-sm text-decoration-none text-dark">
                                            <Link to="/contact-us" className="text-decoration-none text-dark">
                                                <h6 className="m-0 mx-auto mb-3">Help</h6>
                                            </Link>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn bg-transparent border-none shadow-none m-0 p-0 btn-sm text-decoration-none text-dark">
                                            <Link to="/terms-and-conditions" className="text-decoration-none text-dark">
                                                <h6 className="m-0 mx-auto mb-3">Terms of Servies</h6>
                                            </Link>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="btn bg-transparent border-none shadow-none m-0 p-0 btn-sm text-decoration-none text-dark">
                                            <Link to="/privacy-policy" className="text-decoration-none text-dark">
                                                <h6 className="m-0 mx-auto mb-3">Privacy Policy</h6>
                                            </Link>
                                        </button>
                                    </li>
                                    <li>
                                        <div className='text-dark'>
                                            <h6 className=''>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h4 className="mb-3 fw-bold">Contact</h4>
                                <h6 className="mb-3">DEVSEMBLE SOLUTIONS PRIVATE LIMITED</h6>
                                <h6 className="mb-3">Somaiya Vidyavihar, Room No. 520, Bhaskarachraya Building, Vidyavihar, Mumbai, Maharashtra 400077</h6>
                                <h6 className="mb-3">Mobile +91 81081 27266</h6>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer