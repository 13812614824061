import projectListers from '../authFlow/assets/project_listers_page.png'
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

const ProjectListers = () => {

    return (
        <>
            <Helmet>
                <title>DevSemble Community - A Network of Innovators</title>
                <meta
                    name="description"
                    content="Connect with students, freelancers, and project listers in the DevSemble community. Collaborate, share knowledge, and build projects that make an impact."
                />
            </Helmet>
            <div className=''>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-11">
                    <div className='fs-1 d-lg-block d-none pb-3 fw-bold text-center'>
                        Project Listers
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-5 px-md-5">
                            <img src={projectListers} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            <div className='fs-1 d-lg-none d-block pb-3 fw-bold text-center' data-aos="fade-up" data-aos-delay="00">
                                Project Listers
                            </div>
                        </div>
                        <div className="col-lg-6 px-md-5">
                            <div className="fs-3">
                                <div className='py-2' data-aos="fade-up" data-aos-delay="200">Project listers can use <span className='text-primary fw-bolder'><b>devSemble</b></span> to find top-tier talent for their projects.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                    Post detailed project listings, specify the required skills, and get matched with the best candidates.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="400">
                                    Our platform streamlines the talent acquisition process, making it easy for you to find and collaborate with skilled freelancers and students.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <h2>Coming Soon!!!</h2>
                <Link to={'/auth'}>
                    <button className='btn btn-primary fw-bold rounded-pill my-3'>
                        Get Started
                    </button>
                </Link>
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default ProjectListers