import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Safely retrieve user
    return user?.token || ""; // Return token or fallback to empty string
};

export const postGitCreds = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/post-git-creds`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log("GIT TOKEN Err: ", err)
    )
}

export const getRepos = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-git-repos/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}