import { useState, useEffect } from "react"
import { useAuthStore } from '../../store/store';
import { getUserProfile } from "../../actions/userProfileActions";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const TeamRemoved = () => {

    const data = useAuthStore(state => state.user)
    const [userData, setUserData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUserProfile(data.user._id).then(res => {
            setUserData(res)
            setLoading(false)
        })
    }, [refresh])

    return (
        <div className="col-md-10 col-12">
            {loading ?
                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                        <div className="text-center">
                            <CircularProgress />
                            <div>
                                Loading Tasks please wait
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="d-flex flex-wrap justify-content-center">
                    {userData?.teamRemoved?.length !== 0 ? <div style={{ width: '100%' }}>{userData?.teamRemoved?.map((a, i) => (
                        <div key={i} className="py-2 col-12">
                            <div className="shadow bg-white rounded-3 my-3 p-2">
                                <div className="d-flex col-12 justify-content-between align-items-center">
                                    <h3 className="fw-bold">{a?.projectId?.projectTitle}
                                        {a.projectId?.isDeleted ? <span> (Deleted)</span> : null}</h3>
                                </div>
                                <div className="col-12 d-flex flex-wrap ">
                                    {a?.projectId?.projectTech?.map((p, i) => (
                                        <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                            <span className="fs-6 fw-bold m-0 text-white">{p}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="pt-3">
                                    <h4 className="fw-bold m-0">Reason</h4>
                                    <h5 className="">{a?.removalReason}</h5>
                                </div>
                            </div>
                        </div>
                    ))}</div> : <div className="shadow bg-white col-12 text-center p-2 my-2">
                        <h4>No Teams</h4>
                    </div>}
                </div>
            }
        </div>
    )
}

export default TeamRemoved