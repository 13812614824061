import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import {
    getDiscussionBySlug,
    addThreadToDiscussion,
    addCommentToThread,
    toggleUpvoteThread,
} from "../../actions/discussionActions";
import Navbar from "../../freeAccess/Navbar";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faComments, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useAuthStore } from "../../store/store";
import Footer from "../../freeAccess/Footer";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "code-block"],
        ["clean"],
    ],
    syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
    },
};

const SingleDiscussion = () => {
    const { slug } = useParams();
    const [discussion, setDiscussion] = useState(null);
    const [newThreadContent, setNewThreadContent] = useState("");
    const [newCommentContent, setNewCommentContent] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [showThreadForm, setShowThreadForm] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState({});
    const [showComments, setShowComments] = useState({});

    const data = useAuthStore(state => state.user)

    useEffect(() => {
        fetchDiscussion();
    }, [slug]);

    const fetchDiscussion = () => {
        const userId = data ? data.user._id : null;
        getDiscussionBySlug(slug, userId)
            .then((res) => {
                setDiscussion(res)
            })
            .catch((err) => console.error("Error fetching discussion:", err));
    };

    const handleAddThread = () => {
        if (!newThreadContent.trim()) {
            setErrorMessage("Thread content cannot be empty.");
            return;
        }

        const threadData = { content: newThreadContent };

        addThreadToDiscussion(discussion._id, threadData)
            .then((res) => {
                if (res.message === "Thread added") {
                    setDiscussion((prev) => ({
                        ...prev,
                        threads: [...prev.threads, res.thread],
                    }));
                    setNewThreadContent("");
                    setErrorMessage("");
                    setShowThreadForm(false);
                } else {
                    setErrorMessage("Failed to add thread. Please try again.");
                }
            })
            .catch((err) => setErrorMessage("An error occurred while adding the thread."));
    };

    const handleAddComment = (threadId) => {
        const content = newCommentContent[threadId];
        if (!content || !content.trim()) {
            alert("Comment content cannot be empty.");
            return;
        }

        const commentData = { content };

        addCommentToThread(discussion._id, threadId, commentData)
            .then((res) => {
                if (res.message === "Comment added") {
                    setDiscussion((prev) => ({
                        ...prev,
                        threads: prev.threads?.map((thread) =>
                            thread._id === threadId
                                ? { ...thread, comments: [...thread.comments, res.comment] }
                                : thread
                        ),
                    }));
                    setNewCommentContent((prev) => ({ ...prev, [threadId]: "" }));
                    setShowCommentForm((prev) => ({ ...prev, [threadId]: false }));
                } else {
                    alert("Failed to add comment. Please try again.");
                }
            })
            .catch((err) => alert("An error occurred while adding the comment."));
    };

    const handleToggleUpvoteThread = (threadId) => {
        toggleUpvoteThread({ discussionId: discussion._id, threadId: threadId })
            .then((res) => {
                if (res) {
                    const updatedThread = res.thread;
                    setDiscussion((prev) => ({
                        ...prev,
                        threads: prev.threads?.map((thread) =>
                            thread._id === threadId ? updatedThread : thread
                        ),
                    }));
                } else {
                    alert("Failed to toggle upvote. Please try again.");
                }
            })
            .catch((err) => {
                console.log("An error occurred while toggling upvote:", err);
            });
    };
    


    if (!discussion) {
        return (
            <div className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Loading discussion...</p>
            </div>
        );
    }

    return (
        <>
            <Navbar />
            <div className="container-fluid col-md-8 col-12 mt-5">
                <div className="row" style={{ minHeight: '50vh' }}>
                    <div className="col-md-3">
                        <div className="card border-0 shadow mb-4" style={{ position: "sticky", top: "5vh" }}>
                            <div className="card-body">
                                <h5 className="card-title">Project Details</h5>
                                <div className="card-text">
                                    <div>
                                        <strong>Title:</strong>{" "}
                                        {discussion?.projectId?.projectTitle || "N/A"}
                                    </div>
                                    <div>
                                        <strong>Host:</strong>{" "}
                                        {discussion?.projectId?.projectHost?.firstName || "N/A"}{" "}
                                        {discussion?.projectId?.projectHost?.lastName || ""}
                                    </div>
                                    <div>
                                        <strong>Co-Host:</strong>{" "}
                                        {discussion?.projectId?.projectCoHost?.firstName || "N/A"}{" "}
                                        {discussion?.projectId?.projectCoHost?.lastName || ""}
                                    </div>
                                    <div>
                                        <strong>Team Members:</strong>{" "}
                                        {discussion?.projectId?.projectTeam?.length > 0 ? (
                                            discussion?.projectId?.projectTeam.map((member) => (
                                                <div key={member?._id || Math.random()}>
                                                    {member?.firstName || "N/A"}{" "}
                                                    {member?.lastName || ""}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No team members available.</p>
                                        )}
                                    </div>
                                    <hr />
                                    <div>
                                        <strong>Tech used:</strong>{" "}
                                        {discussion?.projectId?.projectTech?.length > 0 ? (
                                            discussion?.projectId?.projectTech.map((tech, i) => (
                                                <span
                                                    key={i}
                                                    className="badge rounded-pill fw-bold bg-primary me-2"
                                                >
                                                    {tech}
                                                </span>
                                            ))
                                        ) : (
                                            <p>No tech details available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            <div className="card border-0 shadow mb-4">
                                <div className="card-body">
                                    <h1 className="card-title">{discussion?.title}</h1>
                                    <p className="text-muted m-0">
                                        By <strong>{discussion?.createdBy?.firstName} {" "}
                                            {discussion?.createdBy?.lastName}</strong> |{" "}
                                        {new Date(discussion?.createdAt).toLocaleDateString()}
                                    </p>
                                    <div className="py-2">
                                        {discussion?.tags && discussion?.tags.length > 0 && (
                                            <div className="">
                                                {discussion?.tags.map((tag, index) => (
                                                    <span key={index} className="badge bg-primary rounded-pill me-2">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="card-text"
                                        dangerouslySetInnerHTML={{ __html: discussion?.description }}
                                    ></div>
                                </div>
                            </div>
                            {errorMessage && <p className="text-danger">{errorMessage}</p>}

                            {data ? (
                                null
                            ) : (
                                <div className="text-center"><p className="text-danger fw-bold">Log in to add a thread or comment.</p></div>
                            )}
                            <div className="border-0">
                                <div className="">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div>
                                            <h3>Threads</h3>
                                        </div>
                                        <div>
                                            {data ? (
                                                <button
                                                    className={showThreadForm ? "btn btn-sm btn-danger fw-bold" : "btn btn-sm btn-primary fw-bold"}
                                                    onClick={() => setShowThreadForm((prev) => !prev)}
                                                >
                                                    {showThreadForm ? "Cancel" : "Add a Thread"}
                                                </button>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </div>
                                    <Collapse in={showThreadForm}>
                                        <div className="card border-0 shadow my-4">
                                            <div className="card-body">
                                                <ReactQuill
                                                    value={newThreadContent}
                                                    onChange={setNewThreadContent}
                                                    modules={modules}
                                                    className="mb-3"
                                                    placeholder="Write your thread (you can add code blocks)..."
                                                    theme="snow"
                                                />
                                                <div className="text-end">
                                                    <button
                                                        className="btn btn-primary btn-sm fw-bold"
                                                        onClick={handleAddThread}
                                                    >
                                                        Add Thread
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    {discussion?.threads?.length > 0 ? (
                                        discussion?.threads?.map((thread) => (
                                            <div key={thread?._id} className="mb-4">
                                                <div className="card shadow border-0">
                                                    <div className="card-body">
                                                        <p className="mb-2">
                                                            <strong className="text-muted">
                                                                {thread?.createdBy?.firstName} {" "}
                                                                {thread?.createdBy?.lastName}
                                                            </strong>
                                                        </p>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: thread?.content }}
                                                        ></div>
                                                        <hr className="my-2" />
                                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                className={`text-decoration-none ${showComments[thread?._id] ? 'text-danger' : 'text-primary'}`}
                                                                onClick={() => {
                                                                    setShowComments((prev) => ({
                                                                        ...prev,
                                                                        [thread?._id]: !prev[thread?._id],
                                                                    }))
                                                                    setShowCommentForm(false)
                                                                }
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faComments} />{" "}
                                                                {showComments[thread?._id] ? "Hide Comments" : "Show Comments"}
                                                            </span>
                                                            {data ? <>
                                                                {thread?.voted ? <div>
                                                                    <span onClick={() => {
                                                                        handleToggleUpvoteThread(thread._id)
                                                                    }} style={{ cursor: 'pointer' }} className="badge bg-secondary rounded-pill border-secondary">{thread?.upvotes} Votes <FontAwesomeIcon size="1x" icon={faArrowDown} /></span>
                                                                </div> : <div>
                                                                    <span onClick={() => {
                                                                        handleToggleUpvoteThread(thread._id)
                                                                    }} style={{ cursor: 'pointer' }} className="badge bg-secondary rounded-pill">{thread?.upvotes} Votes <FontAwesomeIcon size="1x" icon={faArrowUp} /> </span>
                                                                </div>}
                                                            </> : null}
                                                            {data ? (
                                                                <span
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`text-decoration-none ${showCommentForm[thread?._id] ? 'text-danger' : 'text-success'} ms-3`}
                                                                    onClick={() => {
                                                                        setShowCommentForm((prev) => ({
                                                                            ...prev,
                                                                            [thread?._id]: !prev[thread?._id],
                                                                        }))
                                                                        setShowComments(false)
                                                                    }
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} /> {showCommentForm[thread?._id] ? "Close" : "Add Comment"}
                                                                </span>
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                        <Collapse in={showCommentForm[thread?._id]}>
                                                            <hr />
                                                            <TextField
                                                                variant="outlined"
                                                                value={
                                                                    newCommentContent[thread?._id] || ""
                                                                }
                                                                onChange={(e) =>
                                                                    setNewCommentContent((prev) => ({
                                                                        ...prev,
                                                                        [thread?._id]: e.target.value,
                                                                    }))
                                                                }
                                                                className="form-control mb-3"
                                                                label="Write a comment..."
                                                            />
                                                            <div className="text-end">
                                                                <button
                                                                    className="btn btn-sm fw-bold btn-primary"
                                                                    onClick={() =>
                                                                        handleAddComment(thread?._id)
                                                                    }
                                                                >
                                                                    Add Comment
                                                                </button>
                                                            </div>
                                                        </Collapse>
                                                        <Collapse in={showComments[thread?._id]}>
                                                            <hr />
                                                            <h6>Comments:</h6>
                                                            <ul className="list-unstyled">
                                                                {thread?.comments?.length > 0 ? (
                                                                    thread?.comments.map((comment, idx) => (
                                                                        <li key={idx} className="mb-2">
                                                                            <strong>
                                                                                {comment.createdBy?.firstName}:
                                                                            </strong>{" "}
                                                                            {comment.content}
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <p className="text-muted">
                                                                        No comments yet.
                                                                    </p>
                                                                )}
                                                            </ul>
                                                        </Collapse>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-muted">
                                            No threads yet. Be the first to add a thread!
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SingleDiscussion;
