import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Safely retrieve user
    return user?.token || ""; // Return token or fallback to empty string
};

export const createProject = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/create-project`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUserProject = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-user-project/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const deleteProject = (projectId) => {
    const token = getToken();
    return fetch(`${apiUrl}/delete-project/${projectId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const updateProject = (projectId, updatedData) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-project/${projectId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchUsers = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/fetch-users/${data}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUsersByTechnologies = (techArray) => {
    const token = getToken();
    return fetch(`${apiUrl}/fetch-users-by-tech-for-project?techArray=${techArray}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(techArray)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchRankedUsers = () => {
    const token = getToken();
    return fetch(`${apiUrl}/fetch-ranked-users/`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchSingleRankedUser = (rankId) => {
    const token = getToken();
    return fetch(`${apiUrl}/fetch-single-ranked-user/${rankId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchUsersByRankRange = (rankRange) => {
    const token = getToken();
    return fetch(`${apiUrl}/fetch-ranked-users/by-rank-range?rankRange=${encodeURIComponent(rankRange)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json()) // Handle JSON response
        .catch(err => {
            console.error("Error fetching users:", err); // Log the error
            throw err; // Re-throw for further handling
        });
};

export const joinTeamRequest = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/send-requests-team`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const applyTeam = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/apply-team`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const withdrawApplication = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/withdraw-application`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json();
    }).catch(err => {
        return err.json();
    });
};

export const rejectApplication = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/reject-application`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json();
    }).catch(err => {
        return err.json();
    });
};

export const deSelectRequestedMember = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/de-select-requested-member`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const addTaskToRequirements = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-tasks-to-requirements`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const removeTeamMember = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/remove-team-member`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitProposedAmount = (data) => {
    console.log(data)
    const token = getToken();
    return fetch(`${apiUrl}/negotiate-from-user`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitProposedAmountHost = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/negotiate-from-host`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitNegotiationSettle = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/negotiation-settle`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getSingleProject = ({ projectId, gitId }) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-single-project/${projectId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err.json())
        return err.json()
    })
}

export const getProjectsByTech = (tech) => {
    const token = getToken();
    const techQuery = Array.isArray(tech) ? tech.join(',') : tech;

    return fetch(`${apiUrl}/projects/by-tech?tech=${encodeURIComponent(techQuery)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.json())
        .catch(err => err.json());
};


export const searchProjects = (searchTerm) => {
    const token = getToken();
    return fetch(`${apiUrl}/projects/search?searchTerm=${encodeURIComponent(searchTerm)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        })
        .catch(err => {
            // console.error('Error in searchProjects:', err);
            return { error: true, message: err.message };
        });
};

export const getSingleProjectForTeam = (projectId) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-single-project-for-team/${projectId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err.json())
        return err.json()
    })
}

export const acceptTeamRequest = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/accept-team-request`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const rejectTeamRequest = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/reject-team-request`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitCoHost = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/submit-co-host`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getPublicProject = () => {
    const token = getToken();
    return fetch(`${apiUrl}/get-public-projects`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const fetchRecentChats = (projectId) => {
    const token = getToken();
    return fetch(`${apiUrl}/chatrooms/${projectId}/messages?limit=30`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
};

export const updateGitAccess = (data) => {
    console.log(data)
    const token = getToken();
    return fetch(`${apiUrl}/update-git-user-control/${data.projectId}/${data.gitAccess}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return (err.json())
    })
}

export const updateRepoIntegration = (data) => {
    // console.log(data)
    const token = getToken();
    return fetch(`${apiUrl}/update-repo-integrate-git/${data}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return (err.json())
    })
}

export const assignGitRepoToProject = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-git-repo/${data.projectId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return (err.json())
    })
}

export const getProjectCommits = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-project-repo-commits/${data.projectId}/${data.gitId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getProjectTransactions = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/project/${data}/transactions`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const initiatePayout = ({ projectId, memberId, amount, projectHost }) => {
    const token = getToken();
    return fetch(`${apiUrl}/project/${projectId}/team/${memberId}/initiate-first-payout`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount, projectHost })  // Include amount and projectHost in the body
    })
        .then(response => response.json())
        .catch(err => {
            console.error("Error initiating payout:", err);
            throw err;
        });
};
