import React, { useState } from 'react';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TextField,
    Button,
    Box,
} from '@mui/material';
import { ExpandMore, HelpOutline } from '@mui/icons-material';
import { useAuthStore } from '../store/store';
import { askQuestion } from '../actions/askActions';
import Navbar from './Navbar';
import Footer from './Footer';

const AskDevSemble = () => {

    const user = useAuthStore(state => state.user)

    const [expanded, setExpanded] = useState(null); // For FAQ Accordion state
    const [formData, setFormData] = useState({
        question: '',
        guestName: '',
        guestEmail: '',
    });

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (name) => (e) => {
        setFormData({ ...formData, [name]: e.target.value });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        const { question, guestName, guestEmail } = formData;

        if (!user && (!guestName || !guestEmail)) {
            setLoading(false);
            setError('Please provide your name and email as a guest.');
            return;
        }

        const data = {
            question,
            ...(user ? { userId: user?.user?._id } : { guestName, guestEmail }),
        };

        try {
            const response = await askQuestion(data);
            setLoading(false);

            if (response.error) {
                setError(response.error);
            } else {
                setSuccessMessage('Your question has been submitted successfully!');
                setFormData({ question: '', guestName: '', guestEmail: '' });
            }
        } catch (err) {
            setLoading(false);
            setError('Something went wrong. Please try again later.');
        }
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null); // Open one FAQ at a time
    };

    const faqData = [
        {
            id: 1,
            question: 'What is DevSemble?',
            answer: 'DevSemble is a platform for collaboration, project management, and professional growth that connects students, freelancers, and project listers.'
        },
        {
            id: 2,
            question: 'How does Git integration work in DevSemble?',
            answer: 'Git integration is linked to projects to enable task tracking and scoring based on commit activity. For ranking purposes, profiles must have GitHub integrated, which helps evaluate repository contributions like commits, code quality, and project involvement. Only Git-integrated profiles are considered for ranking.'
        },
        {
            id: 3,
            question: 'How can I create a profile on DevSemble?',
            answer: 'Sign up using your email, fill out your profile with skills and experience, and integrate your GitHub repositories for evaluation and scoring.'
        },
        {
            id: 4,
            question: 'What is essential for scoring high ranks in DevSemble?',
            answer: 'To rank higher, actively contribute to projects, maintain high-quality code, complete tasks on time, and integrate your GitHub account to showcase your repository activity.'
        },
        {
            id: 5,
            question: 'How do I ask a question on DevSemble?',
            answer: 'Use the "Ask DevSemble" feature to submit your questions, either as a registered user or as a guest by providing your name and email.'
        },
        {
            id: 6,
            question: 'How can I join a project on DevSemble?',
            answer: 'Explore listed projects, check their requirements, and send a request to join the team. The project host will review and approve your application.'
        },
        {
            id: 7,
            question: 'Can I host my own project on DevSemble?',
            answer: 'Yes, registered users can host projects by creating a detailed listing, setting budgets, and inviting or approving team members.'
        },
        {
            id: 8,
            question: 'What are the benefits of Git integration in projects?',
            answer: 'Git integration helps in project traction with integrated task management and performance score calculation for team members.'
        },
        {
            id: 9,
            question: 'Are private repositories supported in DevSemble?',
            answer: 'Yes, private repositories are supported through authorized Git access, ensuring secure task tracking and scoring.'
        },
        {
            id: 10,
            question: 'What is the "Ask DevSemble" feature?',
            answer: '"Ask DevSemble" is a platform feature where users can submit questions related to the platform or projects and receive guidance from the team.'
        },
        {
            id: 11,
            question: 'How do I integrate my GitHub account with DevSemble?',
            answer: 'You can integrate it while filling out your profile, or go to your profile page and link your GitHub account. This allows DevSemble to access your repositories for scoring and evaluation.'
        },
        {
            id: 12,
            question: 'How does DevSemble ensure fairness in ranking?',
            answer: 'DevSemble’s ranking algorithm evaluates profiles based on skills, repository contributions, and completed tasks, ensuring a transparent and fair process.'
        },
    ];


    return (
        <>
            <div>
                <Navbar />
            </div>
            <Grid container spacing={4} alignItems="flex-center" justifyContent='center' sx={{ mt: 4 }}>
                <Grid item xs={11} md={4}>
                    <div style={{ position: "sticky", top: "5vh" }}>
                        <Typography variant="h4" mb={2} align="center" sx={{ color: '#0275d8', fontWeight: 'bold' }}>
                            Ask DevSemble
                        </Typography>

                        {/* Form */}
                        <Box component="form" onSubmit={handleSubmit} sx={{ marginBottom: '2rem' }}>
                            {user && (
                                <>
                                    <Typography variant="body1" mb={1}>
                                        Name: {user?.user?.firstName} {user?.user?.lastName}
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Email: {user?.user?.email}
                                    </Typography>
                                </>
                            )}
                            <TextField
                                label="Your Question"
                                variant="outlined"
                                fullWidth
                                required
                                multiline
                                rows={3}
                                value={formData.question}
                                onChange={handleChange('question')}
                                sx={{ mb: 2 }}
                            />
                            {!user && (
                                <>
                                    <TextField
                                        label="Your Name"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.guestName}
                                        onChange={handleChange('guestName')}
                                        sx={{ mb: 2 }}
                                    />
                                    <TextField
                                        label="Your Email"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.guestEmail}
                                        onChange={handleChange('guestEmail')}
                                        sx={{ mb: 2 }}
                                    />
                                </>
                            )}
                            {error && (
                                <Typography variant="body2" color="error" mb={2}>
                                    {error}
                                </Typography>
                            )}
                            {successMessage && (
                                <Typography variant="body2" color="success.main" mb={2}>
                                    {successMessage}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                sx={{
                                    backgroundColor: '#0275d8',
                                    '&:hover': { backgroundColor: '#025aa5' },
                                }}
                            >
                                {loading ? 'Submitting...' : 'Submit Question'}
                            </Button>
                        </Box>
                    </div>
                </Grid>
                {/* FAQs Section */}
                <Grid item xs={11} md={4}>
                    <Typography variant="h4" sx={{ mb: 2, color: '#444', fontWeight: 'bold' }}>
                        Frequently Asked Questions
                    </Typography>
                    {faqData.map((faq) => (
                        <Accordion
                            className='rounded-3'
                            key={faq.id}
                            expanded={expanded === faq.id}
                            onChange={handleAccordionChange(faq.id)}
                            sx={{ mb: 1, overflow: 'hidden' }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{ backgroundColor: '#f1f1f1', padding: '0.5rem 1rem' }}
                            >
                                <Typography sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <HelpOutline fontSize="small" />
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '1rem' }}>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
            <div>
                <Footer />
            </div>
        </>
    );
};

export default AskDevSemble;
