import { useEffect, useState } from 'react';
import Navbar from './Navbar'
import devSembleLogo from '../authFlow/assets/Frame_6devSemble_logo.png'
import Footer from './Footer';
import { Helmet } from "react-helmet-async";

const AboutUs = () => {

    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight / 2) {
                setShowNavbar(true);
            } else {
                setShowNavbar(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>About DevSemble - Empowering Collaboration</title>
                <meta
                    name="description"
                    content="Discover DevSemble's mission to empower collaboration in the tech community. Learn how we connect students, freelancers, and project listers to create impactful projects."
                />
            </Helmet>
            <div>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-lg-6 col-sm-8 col-11">
                    <div className="card bg-transparent border-0 py-md-5 py-3" >
                        <div className="row d-flex flex-wrap justify-content-between align-items-center">
                            <div className="col-md-4 col-4">
                                <img src={devSembleLogo} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            </div>
                            <div className="col-md-8 col-12 d-flex flex-wrap justify-content-between align-items-center">
                                <div className="card-body px-0">
                                    <h1 className="display-1 fw-bold" data-aos="fade-right" data-aos-delay="600">About Us</h1>
                                    <h3 className="" data-aos="fade-right" data-aos-delay="700">A place where developers assemble.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-justify"><article className="text-justify py-md-5 py-3">
                        <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                            <h2 className='m-0'>
                                Empowering Innovation Through Collaboration
                            </h2>
                            <p className='fs-5 m-0 py-2'>
                                At <span className="text-primary fw-bold">devSemble</span>, we believe that talent shines brightest when given the right opportunities and tools.
                                We are reimagining how individuals and teams collaborate,
                                leveraging advanced systems to make every contribution count.
                            </p>
                        </div>
                        <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                            <h2 className='m-0'>
                                Your Work, Elevated
                            </h2>
                            <p className='fs-5 m-0 py-2'>
                                <span className="text-primary fw-bold">devSemble</span> isn’t just a platform; it’s a catalyst for growth.
                                By merging skill-driven matchmaking with dynamic project tools, we empower users to transform ideas into impactful solutions.
                                Our community thrives on collaboration, where every task, contribution, and effort shapes a shared success story.
                            </p>
                        </div>
                        <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                            <h2 className='m-0'>
                                Recognition That Matters
                            </h2>
                            <p className='fs-5 m-0 py-2'>
                                The heart of <span className="text-primary fw-bold">devSemble</span> lies in its intelligent ranking algorithm—a system designed to fairly evaluate contributions, skills, and impact.
                                This ensures that your work not only gets recognized but also opens doors to opportunities tailored to your strengths.
                            </p>
                        </div>
                    </article>
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default AboutUs