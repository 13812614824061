import { useState, useEffect } from "react"
import { useAuthStore } from '../../store/store';
import { getUserProfile } from "../../actions/userProfileActions";
import { Link } from "react-router-dom";
import { CircularProgress, Collapse } from "@mui/material";
import { withdrawApplication } from "../../actions/projectActions";

const TeamApplied = () => {

    const data = useAuthStore(state => state.user)
    const setStateUser = useAuthStore(state => state.setUser)

    const [userData, setUserData] = useState({})
    const [refresh, setRefresh] = useState(false)

    const [isExpanded, setIsExpanded] = useState(-1);

    const [showReqs, setShowReqs] = useState(-1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUserProfile(data.user._id).then(res => {
            setUserData(res)
            setLoading(false)
        })
    }, [refresh])

    const handleWithdrawApplication = async (projectId) => {
        const confirmed = window.confirm('Are you sure you want to withdraw your application?');
        if (!confirmed) return;

        const data = {
            projectId: projectId,
            profileId: userData?._id
        };

        try {
            const response = await withdrawApplication(data);
            if (response && response.updatedProfile) {
                const updatedProfile = response.updatedProfile;
                const currentUser = JSON.parse(localStorage.getItem('user'));

                const updatedUser = {
                    ...currentUser,
                    profile: {
                        ...currentUser.profile,
                        teamApplied: updatedProfile.teamApplied,
                        teamRejected: updatedProfile.teamRejected
                    },
                };

                localStorage.setItem('user', JSON.stringify(updatedUser));
                setStateUser(updatedUser);
                setRefresh(true)
            } else {
                alert('Failed to withdraw application');
            }
        } catch (error) {
            console.error('Error withdrawing application:', error);
        }
    };

    return (
        <div className="col-md-10 col-12">
            {loading ?
                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                        <div className="text-center">
                            <CircularProgress />
                            <div>
                                Loading Tasks please wait
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="d-flex flex-wrap justify-content-center">
                    {userData?.teamApplied?.length !== 0 ? (
                        <div style={{ width: '100%' }}>
                            {userData?.teamApplied?.map((a, i) => (
                                <div key={i} className="py-2 col-12">
                                    <div className="shadow bg-white my-3 p-2 rounded-3">
                                        {/* Project Title */}
                                        <div className="d-flex col-12 justify-content-between align-items-center">
                                            <div className="text-decoration-none">
                                                <h3 className="fw-bold text-dark">
                                                    {a?.projectTitle}
                                                    {a?.isDeleted && <span> (Deleted)</span>}
                                                </h3>
                                            </div>
                                            <div>
                                                <button onClick={() => handleWithdrawApplication(a._id)} className="btn btn-sm btn-warning rounded-pill">
                                                    Withdraw Application
                                                </button>
                                            </div>
                                        </div>

                                        {/* Project Tech Stack */}
                                        <div className="col-12 d-flex pb-4 pt-2 flex-wrap">
                                            {a?.projectTech.map((p, index) => (
                                                <div key={index} className="bg-primary rounded-pill px-2 me-2">
                                                    <span className="fs-6 fw-bold m-0 text-white">{p}</span>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Project Description with Dynamic Height */}
                                        <p className="fs-5">
                                            {/* Render the description based on the expanded state */}
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: isExpanded === i
                                                        ? a?.projectDescription // Full description when expanded
                                                        : `${a?.projectDescription?.substring(0, 100)}...`, // Truncated description
                                                }}
                                            />
                                            {/* Conditional rendering of the button */}
                                            {a?.projectDescription?.length > 100 && (
                                                <small className="text-primary fw-bold"
                                                    onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {isExpanded === i ? 'Show Less' : 'Show More'}
                                                </small>
                                            )}
                                        </p>
                                        <hr />

                                        {/* Project Requirements Section */}
                                        <div className="py-3">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div>
                                                    <h4>Project Current Requirements</h4>
                                                </div>
                                                <div>
                                                    {showReqs === i ? (
                                                        <button className="btn btn-danger rounded-pill btn-sm" onClick={() => setShowReqs(-1)}>
                                                            Close
                                                        </button>
                                                    ) : (
                                                        <>
                                                            {a?.projectCurrentReq.length === 0 ? (
                                                                <div className="text-center">
                                                                    <span className="fs-5 text-danger">No requirements Added</span>
                                                                </div>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-info rounded-pill btn-sm"
                                                                    onClick={() => setShowReqs(i)}
                                                                >
                                                                    Show
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Requirements Collapse */}
                                            <Collapse in={showReqs === i}>
                                                {a?.projectCurrentReq.length !== 0 ? (
                                                    <div>
                                                        {a?.projectCurrentReq.map((p, index) => (
                                                            <div key={index}>
                                                                <div className="d-flex align-items-start justify-content-start">
                                                                    <span className="fs-5 col-1 text-center">{index + 1}.</span>
                                                                    <div className="col-9">
                                                                        <p className="m-0 fs-5">{p?.title}</p>
                                                                        <p className="m-0 fs-5" dangerouslySetInnerHTML={{ __html: p.desc }}></p>
                                                                    </div>
                                                                </div>
                                                                {index !== a?.projectCurrentReq.length - 1 && <hr className="mx-2 mx-md-5" />}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="text-center">
                                                        <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                                    </div>
                                                )}
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="shadow bg-white col-12 text-center p-2 my-2">
                            <h4>No Teams Applied</h4>
                            <div>
                                <Link to={'/dashboard/display-public-projects'}>
                                    <button className="btn btn-primary rounded-pill">
                                        Look for projects
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}

                </div>
            }
        </div>
    )
}

export default TeamApplied