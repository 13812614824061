import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    return user?.token || "";
};

export const createDiscussion = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getPublicDiscussions = () => {
    return fetch(`${apiUrl}/discussions/public`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getDiscussionBySlug = (slug, userId = null) => {
    const queryParam = userId ? `?userId=${userId}` : '';

    return fetch(`${apiUrl}/discussion/${slug}${queryParam}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Error fetching discussion: ${response.statusText}`);
            }
            return response.json();
        })
        .catch((err) => {
            console.error('Error in getDiscussionBySlug:', err.message);
            return { error: err.message };
        });
};


export const getDiscussionsByProject = (projectId) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${projectId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const updateDiscussion = (topicId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const toggleUpvoteThread = ({discussionId, threadId}) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${discussionId}/threads/${threadId}/toggle-upvote`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const deleteDiscussion = (topicId) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Thread Actions
export const addThreadToDiscussion = (topicId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const updateThread = (topicId, threadId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads/${threadId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const deleteThread = (topicId, threadId) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads/${threadId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Comment Actions
export const addCommentToThread = (topicId, threadId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads/${threadId}/comments`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const updateComment = (topicId, threadId, commentId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads/${threadId}/comments/${commentId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const deleteComment = (topicId, threadId, commentId) => {
    const token = getToken();
    return fetch(`${apiUrl}/discussions/${topicId}/threads/${threadId}/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};
