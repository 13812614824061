import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes";
import AuthNavbar from "../AuthNavbar";
import { useAuthStore } from "../../store/store";
import { updateAdvAccount } from "../../actions/advAccActions";
import { Collapse, IconButton, TextField } from "@mui/material";
import { deleteProject, getUserProject } from "../../actions/projectActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

const AdvAccountDashboard = () => {

    const user = useAuthStore.getState().user
    const setStateUser = useAuthStore(state => state.setUser)

    const [formData, setFormData] = useState({
        userId: user.user._id,
        representingCompany: '',
        positionInCompany: '',
        companyDescription: '',
        companyEmail: '',
        companyContactNo: '',
        errorType: '',
        errorMessage: ''
    })

    const [addedProjects, setAddedProjects] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const isMdScreen = useMediaQuery('(min-width: 786px)');
    const [showProfile, setShowProfile] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [profileTab, setProfileTab] = useState(false)
    const [showProjects, setShowProjects] = useState(!isMdScreen)

    const [isExpanded, setIsExpanded] = useState(-1);
    const maxChars = 100; // Maximum characters to show before truncating

    const { userId, companyContactNo, companyDescription, companyEmail, representingCompany, positionInCompany, errorMessage, errorType } = formData

    const handleChange = name => event => {
        setFormData({ ...formData, [name]: event.target.value, errorMessage: '', errorType: '' })
    }


    useEffect(() => {
        getProject()
    }, [refresh])

    const getProject = () => {
        getUserProject(user?.user?._id).then(res => {
            setAddedProjects(res?.reverse())
        }).catch(err => {
            console.log(err)
        })
    }

    const [showReqs, setShowReqs] = useState(-1)


    const handleUpdateProfile = () => {
        if (representingCompany === '') {
            return setFormData({ ...formData, errorType: 'representingCompany', errorMessage: 'Required' })
        }
        if (companyDescription === '') {
            return setFormData({ ...formData, errorType: 'companyDescription', errorMessage: 'Required' })
        }
        if (companyEmail === '') {
            return setFormData({ ...formData, errorType: 'companyEmail', errorMessage: 'Required' })
        }
        if (positionInCompany === '') {
            return setFormData({ ...formData, errorType: 'positionInCompany', errorMessage: 'Required' })
        }
        if (companyContactNo === '') {
            return setFormData({ ...formData, errorType: 'companyContactNo', errorMessage: 'Required' })
        }
        if (companyEmail === '') {
            return setFormData({ ...formData, errorType: 'companyEmail', errorMessage: 'Required' })
        } else {
            updateAdvAccount(formData).then(res => {
                if (res.success) {
                    let userData = JSON.parse(localStorage.getItem('user'));
                    userData.profile = res.response
                    userData.profileStatus = true
                    localStorage.setItem('user', JSON.stringify(userData));
                    setStateUser(userData)
                    setShowForm(false)
                    setRefresh(true)
                }
            }).catch(err => {
                console.log(err)
            })
        }

        setRefresh(false)
    }

    const handelDeleteProject = (projectId) => {
        deleteProject(projectId).then(res => {
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const allTips = [
        "Commit regularly to improve contribution activity.",
        "Collaborate with others on open-source projects.",
        "Write clear and actionable issues in repositories.",
        "Submit detailed pull requests to repositories.",
        "Enhance README files with project descriptions.",
        "Document code to make it easier for others to understand.",
        "Engage with repositories by starring and forking.",
        "Resolve issues in your repositories or those of others.",
        "Tag releases to showcase project milestones.",
        "Keep repositories active with frequent updates."
    ];

    const [currentTips, setCurrentTips] = useState([]);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const shuffleTips = () => {
            const shuffled = allTips.sort(() => 0.5 - Math.random()).slice(0, 3);
            setCurrentTips(shuffled);
        };

        shuffleTips(); // Initial tips
        const interval = setInterval(() => {
            setFade(true); // Start fade-out
            setTimeout(() => {
                shuffleTips(); // Update tips
                setFade(false); // Start fade-in
            }, 500); // Duration of fade-out
        }, 5000); // Change tips every 5 seconds

        return () => clearInterval(interval); // Cleanup
    }, []);

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className={`d-lg-none px-3 d-flex flex-wrap justify-content-end align-items-start ${showMenu ? 'bg-light shadow py-2' : ''}`} style={{
                    position: "sticky", zIndex: 10, top: 10
                }}>
                    <div className={`${showMenu ? "" : 'rounded-pill bg-white shadow'}`}>
                        <IconButton onClick={() => setShowMenu(!showMenu)} >
                            <MenuIcon sx={{ strokeWidth: 3 }} />
                        </IconButton>
                    </div>
                    <Collapse in={showMenu} className="bg-light col-12 text-end">
                        <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                            <span className="text-primary dash-link fw-bold py-1" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                setShowMenu(false)
                                setShowProfile(true)
                                setShowProjects(false)
                            }}>
                                My Profile
                                <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                            </span>
                            <span className="text-primary dash-link fw-bold py-1" style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                setShowMenu(false)
                                setShowProfile(false)
                                setShowProjects(true)
                            }}>
                                My Project
                                <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                            </span>
                        </div>
                    </Collapse>
                </div>
                <div className="container-fluid col-md-10 col-12 py-md-5">
                    <div className="d-flex flex-wrap justify-content-around align-items-start">
                        {isMdScreen ?
                            <div className="col-lg-3 d-none col-12">
                                <div className="card border-0 shadow p-3" style={{
                                    backgroundColor: '#daebf9'
                                }}>
                                    <div className="" style={{
                                        position: `${isMdScreen ? "sticky" : ''}`, top: 0,
                                    }}>
                                        <Collapse in={!showForm}>
                                            <div className="card p-2 bg-transparent my-2 border-0">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <span className="fw-bold fs-4">{user.user.firstName} {user.user.middleName} {user.user.lastName}</span>

                                                    {showForm ? null :
                                                        <div>
                                                            <button onClick={() => {
                                                                setShowForm(true)
                                                                setFormData({
                                                                    ...formData,
                                                                    representingCompany: user.profile.representingCompany,
                                                                    companyDescription: user.profile.companyDescription,
                                                                    positionInCompany: user.profile.positionInCompany,
                                                                    companyEmail: user.profile.companyEmail,
                                                                    companyContactNo: user.profile.companyContactNo
                                                                })
                                                            }} className="btn btn-sm bg-transparent col-12"><FontAwesomeIcon icon={faEdit} /></button>
                                                        </div>}
                                                </div>
                                                <span className="  fs-5">{user.user.email}</span>
                                                <hr />
                                                <div>
                                                    <div className="">
                                                        <span className="  fs-5">Company Representing: </span>
                                                        <span className="fs-5">{user?.profile?.representingCompany}</span>
                                                    </div>
                                                    <div className="">
                                                        <span className="  fs-5">Company Desciption: </span>
                                                        <span className="fs-5">{user?.profile?.companyDescription}</span>
                                                    </div>
                                                    <div className="">
                                                        <span className="  fs-5">Position in Company: </span>
                                                        <span className="fs-5">{user?.profile?.positionInCompany}</span>
                                                    </div>
                                                    <div className="">
                                                        <span className="  fs-5">Company Email: </span>
                                                        <span className="fs-5">{user?.profile?.companyEmail}</span>
                                                    </div>
                                                    <div className="">
                                                        <span className="  fs-5">Company Contact: </span>
                                                        <span className="fs-5">{user?.profile?.companyContactNo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                        <Collapse in={showForm}>
                                            <div className="py-2">
                                                <div className="py-2">
                                                    <button onClick={() => setShowForm(false)} className="btn btn-sm btn-danger">
                                                        Back
                                                    </button>
                                                </div>
                                                <div>
                                                    <span className="  fs-5">Required Details</span>
                                                </div>
                                                <div className="col-12">
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "representingCompany" ? true : false}
                                                            id="outlined-basic"
                                                            value={representingCompany}
                                                            label={errorType === 'representingCompany' ? `Company Representing ${errorMessage}` : "Company Representing"}
                                                            onChange={handleChange('representingCompany')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyDescription" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyDescription}
                                                            label={errorType === 'companyDescription' ? `Company Description ${errorMessage}` : "Company Description"}
                                                            onChange={handleChange('companyDescription')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "positionInCompany" ? true : false}
                                                            id="outlined-basic"
                                                            value={positionInCompany}
                                                            label={errorType === 'positionInCompany' ? `Position In Company ${errorMessage}` : "Your Position in Company"}
                                                            onChange={handleChange('positionInCompany')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyEmail" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyEmail}
                                                            label={errorType === 'companyEmail' ? `Company Email ${errorMessage}` : "Official Email Company"}
                                                            onChange={handleChange('companyEmail')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyContactNo" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyContactNo}
                                                            label={errorType === 'companyContactNo' ? `Company Contact No ${errorMessage}` : "Official Company Contact No"}
                                                            onChange={handleChange('companyContactNo')}
                                                            variant="outlined" />
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <button onClick={() => handleUpdateProfile()} className="btn   btn-primary col-12">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                <div
                                    className={`rounded-3 my-4 p-3 d-lg-block d-none shadow`}
                                    style={{ backgroundColor: "#daebf9" }}
                                >
                                    <div>
                                        <span className="fs-3 fw-bold">
                                            Tips
                                        </span>
                                    </div>
                                    <div className={` ${fade ? "tips-fade-out" : "tips-fade-in"}`}>
                                        {/* <ul className="mt-3">
                                            {currentTips.map((tip, index) => (
                                                <li key={index} className="fs-6 pb-2">
                                                    {tip}
                                                </li>
                                            ))}
                                        </ul> */}
                                    </div>
                                </div>
                            </div> :
                            <Collapse in={!isMdScreen && showProfile} className="col-12">
                                <div className="card border-0 shadow p-2 col-lg-3 p-3 col-12" style={{
                                    position: `${isMdScreen ? "sticky" : ''}`, top: 0,
                                    backgroundColor: '#daebf9'
                                }}>
                                    <Collapse in={!showForm}>
                                        <div className="card p-2 bg-transparent my-2 border-0">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <span className="fw-bold fs-4">{user.user.firstName} {user.user.middleName} {user.user.lastName}</span>

                                                {showForm ? null :
                                                    <div>
                                                        <button onClick={() => {
                                                            setShowForm(true)
                                                            setFormData({
                                                                ...formData,
                                                                representingCompany: user?.profile?.representingCompany,
                                                                companyDescription: user?.profile?.companyDescription,
                                                                positionInCompany: user?.profile?.positionInCompany,
                                                                companyEmail: user?.profile?.companyEmail,
                                                                companyContactNo: user?.profile?.companyContactNo
                                                            })
                                                        }} className="btn btn-sm bg-transparent col-12"><FontAwesomeIcon icon={faEdit} /></button>
                                                    </div>}
                                            </div>
                                            <span className="  fs-5">{user.user.email}</span>
                                            <hr />
                                            <div>
                                                <div className="py-1">
                                                    <div className="fw-bold fs-6">Company Representing: </div>
                                                    <div className="fs-6">{user?.profile?.representingCompany}</div>
                                                </div>
                                                <div className="py-1">
                                                    <div className="fw-bold fs-6">Company Desciption: </div>
                                                    <div className="fs-6">{user?.profile?.companyDescription}</div>
                                                </div>
                                                <div className="py-1">
                                                    <div className="fw-bold fs-6">Position in Company: </div>
                                                    <div className="fs-6">{user?.profile?.positionInCompany}</div>
                                                </div>
                                                <div className="py-1">
                                                    <div className="fw-bold fs-6">Company Email: </div>
                                                    <div className="fs-6">{user?.profile?.companyEmail}</div>
                                                </div>
                                                <div className="py-1">
                                                    <div className="fw-bold fs-6">Company Contact: </div>
                                                    <div className="fs-6">{user?.profile?.companyContactNo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <Collapse in={showForm}>
                                        <div className="py-2">
                                            <div className="py-2">
                                                <button onClick={() => setShowForm(false)} className="btn btn-sm btn-danger">
                                                    Back
                                                </button>
                                            </div>
                                            <div>
                                                <span className="  fs-5">Required Details</span>
                                            </div>
                                            <div className="col-12">
                                                <div className=''>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        className='my-2 bg-white'
                                                        error={errorType === "representingCompany" ? true : false}
                                                        id="outlined-basic"
                                                        value={representingCompany}
                                                        label={errorType === 'representingCompany' ? `Company Representing ${errorMessage}` : "Company Representing"}
                                                        onChange={handleChange('representingCompany')}
                                                        variant="outlined" />
                                                </div>
                                                <div className=''>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        className='my-2 bg-white'
                                                        error={errorType === "companyDescription" ? true : false}
                                                        id="outlined-basic"
                                                        value={companyDescription}
                                                        label={errorType === 'companyDescription' ? `Company Description ${errorMessage}` : "Company Description"}
                                                        onChange={handleChange('companyDescription')}
                                                        variant="outlined" />
                                                </div>
                                                <div className=''>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        className='my-2 bg-white'
                                                        error={errorType === "positionInCompany" ? true : false}
                                                        id="outlined-basic"
                                                        value={positionInCompany}
                                                        label={errorType === 'positionInCompany' ? `Position In Company ${errorMessage}` : "Your Position in Company"}
                                                        onChange={handleChange('positionInCompany')}
                                                        variant="outlined" />
                                                </div>
                                                <div className=''>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        className='my-2 bg-white'
                                                        error={errorType === "companyEmail" ? true : false}
                                                        id="outlined-basic"
                                                        value={companyEmail}
                                                        label={errorType === 'companyEmail' ? `Company Email ${errorMessage}` : "Official Email Company"}
                                                        onChange={handleChange('companyEmail')}
                                                        variant="outlined" />
                                                </div>
                                                <div className=''>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        className='my-2 bg-white'
                                                        error={errorType === "companyContactNo" ? true : false}
                                                        id="outlined-basic"
                                                        value={companyContactNo}
                                                        label={errorType === 'companyContactNo' ? `Company Contact No ${errorMessage}` : "Official Company Contact No"}
                                                        onChange={handleChange('companyContactNo')}
                                                        variant="outlined" />
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                <button onClick={() => handleUpdateProfile()} className="btn   btn-primary col-12">
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Collapse>
                        }
                        {isMdScreen ?
                            <div className="col-lg-8 p-2 col-12 rounded-3" style={{
                                minHeight: '90vh',
                                overflowY: 'auto',
                                backgroundColor: '#daebf9'
                            }}>
                                <div className="">
                                    <div className="d-flex py-3 flex-wrap justify-content-between align-items-center">
                                        <span className="fw-bold fs-4">{user?.user?.firstName} {user?.user?.middleName} {user?.user?.lastName}</span>

                                        {showForm ? null :
                                            <div>
                                                <button onClick={() => {
                                                    setShowForm(true)
                                                    setProfileTab(true)
                                                    setFormData({
                                                        ...formData,
                                                        representingCompany: user?.profile?.representingCompany,
                                                        companyDescription: user?.profile?.companyDescription,
                                                        positionInCompany: user?.profile?.positionInCompany,
                                                        companyEmail: user?.profile?.companyEmail,
                                                        companyContactNo: user?.profile?.companyContactNo
                                                    })
                                                }} className="btn btn-sm bg-transparent col-12"><FontAwesomeIcon icon={faEdit} /></button>
                                            </div>}
                                    </div>
                                    <div className="d-flex flex-wrap gap-3 justify-content-start align-items-center">
                                        <span className="text-primary fw-bold" style={{ cursor: 'pointer' }} onClick={() => setProfileTab(true)}>
                                            My Profile
                                        </span>
                                        <span className="text-primary fw-bold" style={{ cursor: 'pointer' }} onClick={() => {
                                            setProfileTab(false)
                                            setShowForm(false)
                                        }}>
                                            My Projects
                                        </span>
                                    </div>
                                    <hr className="my-3" />
                                    <Collapse in={profileTab}>
                                        <Collapse in={!showForm}>
                                            <div className="card p-2 bg-transparent my-2 border-0">
                                                <span className="  fs-5">{user.user.email}</span>
                                                <hr />
                                                <div>
                                                    <div className="py-1">
                                                        <span className="fw-bold fs-6">Company Representing: </span>
                                                        <span className="fs-6">{user?.profile?.representingCompany}</span>
                                                    </div>
                                                    <div className="py-1">
                                                        <span className="fw-bold fs-6">Company Desciption: </span>
                                                        <span className="fs-6">{user?.profile?.companyDescription}</span>
                                                    </div>
                                                    <div className="py-1">
                                                        <span className="fw-bold fs-6">Position in Company: </span>
                                                        <span className="fs-6">{user?.profile?.positionInCompany}</span>
                                                    </div>
                                                    <div className="py-1">
                                                        <span className="fw-bold fs-6">Company Email: </span>
                                                        <span className="fs-6">{user?.profile?.companyEmail}</span>
                                                    </div>
                                                    <div className="py-1">
                                                        <span className="fw-bold fs-6">Company Contact: </span>
                                                        <span className="fs-6">{user?.profile?.companyContactNo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                        <Collapse in={showForm}>
                                            <div className="py-2">
                                                <div className="py-2">
                                                    <button onClick={() => setShowForm(false)} className="btn btn-sm btn-danger">
                                                        Back
                                                    </button>
                                                </div>
                                                <div>
                                                    <span className="  fs-5">Required Details</span>
                                                </div>
                                                <div className="col-12">
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "representingCompany" ? true : false}
                                                            id="outlined-basic"
                                                            value={representingCompany}
                                                            label={errorType === 'representingCompany' ? `Company Representing ${errorMessage}` : "Company Representing"}
                                                            onChange={handleChange('representingCompany')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyDescription" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyDescription}
                                                            label={errorType === 'companyDescription' ? `Company Description ${errorMessage}` : "Company Description"}
                                                            onChange={handleChange('companyDescription')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "positionInCompany" ? true : false}
                                                            id="outlined-basic"
                                                            value={positionInCompany}
                                                            label={errorType === 'positionInCompany' ? `Position In Company ${errorMessage}` : "Your Position in Company"}
                                                            onChange={handleChange('positionInCompany')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyEmail" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyEmail}
                                                            label={errorType === 'companyEmail' ? `Company Email ${errorMessage}` : "Official Email Company"}
                                                            onChange={handleChange('companyEmail')}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorType === "companyContactNo" ? true : false}
                                                            id="outlined-basic"
                                                            value={companyContactNo}
                                                            label={errorType === 'companyContactNo' ? `Company Contact No ${errorMessage}` : "Official Company Contact No"}
                                                            onChange={handleChange('companyContactNo')}
                                                            variant="outlined" />
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <button onClick={() => handleUpdateProfile()} className="btn   btn-primary col-12">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </Collapse>
                                </div>
                                <Collapse in={!profileTab}>
                                    <div className="d-flex justify-content-center">
                                        <div className="col-md-10 pt-md-4 col-12">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div>
                                                    <h2 className="m-0">My Projects</h2>
                                                </div>
                                                <div>
                                                    <Link to={`/advanced-dashboard/manage-projects`}>
                                                        <button className="btn btn-success rounded-pill">
                                                            Add New Project
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                            {addedProjects.map((a, i) => (
                                                <div key={i} className="card border-0 shadow bg-white rounded-3 my-5 p-2 px-md-3">
                                                    <div className="d-flex col-12 justify-content-between align-items-center">
                                                        <div>
                                                            <Link to={`manage-projects/single-project/${a._id}`} className=" text-decoration-none" state={a._id}>
                                                                <h3 className="fw-bold text-decoration-none text-dark">{a.projectTitle}
                                                                    {a.isDeleted ? <span> (Deleted)</span> : null}
                                                                </h3>
                                                            </Link>
                                                            <p className={a?.isPrivate ? `text-success  ` : `text-danger  `}>Visibility: {a?.isPrivate ? 'Private' : 'Public'}</p>
                                                        </div>
                                                        <div className="col-5 border-start border-3 d-flex justify-content-end gap-4 align-items-center">
                                                            <Link to={`/advanced-dashboard/manage-projects`} state={{
                                                                update: true,
                                                                projectIdFromDash: a._id,
                                                                projectTitleFromDash: a.projectTitle,
                                                                projectDescriptionFromDash: a.projectDescription,
                                                                projectCurrentReqFromDash: a.projectCurrentReq,
                                                                projectTechFromDash: a.projectTech,
                                                                isPrivateFromDash: a.isPrivate,
                                                                budgetFromDash: a.budget
                                                            }}>
                                                                <button className={a.isDeleted ? "btn disabled btn-sm btn-warning rounded-pill" : "btn btn-sm btn-warning rounded-pill"}>
                                                                    <span className="d-md-inline fw-bold d-none">Update</span> <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </Link>
                                                            <button onClick={() => handelDeleteProject(a._id)}
                                                                className={a.isDeleted ? "btn disabled btn-sm btn-danger rounded-pill" : "btn btn-sm btn-danger rounded-pill"}>
                                                                <span className="d-md-inline fw-bold d-none">Delete</span> <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 pb-2 d-flex flex-wrap">
                                                        {a?.projectTech.map((p, i) => (
                                                            <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                <span className="fs-6 text-white fw-bold">{p}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <p className="fs-5">
                                                        {/* Render the description based on the expanded state */}
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: isExpanded === i
                                                                    ? a?.projectDescription // Full description when expanded
                                                                    : `${a?.projectDescription?.substring(0, 100)}...`, // Truncated description
                                                            }}
                                                        />
                                                        {/* Conditional rendering of the button */}
                                                        {a?.projectDescription?.length > 100 && (
                                                            <small className="text-primary fw-bold"
                                                                onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {isExpanded === i ? 'Show Less' : 'Show More'}
                                                            </small>
                                                        )}
                                                    </p>
                                                    <hr />
                                                    <div className="">
                                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                            <div>
                                                                <h4>Project Current Requirements</h4>
                                                            </div>
                                                            <div>
                                                                {showReqs === i ? (
                                                                    <button className="btn btn-sm bw-bold btn-danger rounded-pill" onClick={() => setShowReqs(-1)}>
                                                                        Close
                                                                    </button>
                                                                ) : (
                                                                    <>
                                                                        {a?.projectCurrentReq.length === 0 ? (
                                                                            <div className="text-center">
                                                                                <span className="fs-5 text-danger">No requirements Added</span>
                                                                            </div>
                                                                        ) : (
                                                                            <button
                                                                                className="btn btn-sm fw-bold rounded-pill"
                                                                                style={{
                                                                                    backgroundColor: '#daebf9',
                                                                                }}
                                                                                onClick={() => setShowReqs(i)}
                                                                            >
                                                                                Show
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <Collapse in={showReqs === i}>
                                                            {a?.projectCurrentReq.length !== 0 ? (
                                                                <div>
                                                                    {a?.projectCurrentReq.map((p, i) => (
                                                                        <div key={i}>
                                                                            <div className="d-flex align-items-start justify-content-start">
                                                                                <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                                                <div className="col-9">
                                                                                    <p className="m-0 fs-5">{p?.title}</p>
                                                                                    <p className="m-0 fs-5" dangerouslySetInnerHTML={{ __html: p.description }}></p>
                                                                                </div>
                                                                            </div>
                                                                            <hr className="mx-2 mx-md-5" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <div className="text-center">
                                                                    <span className="fs-5 text-danger">No requirements Added</span>
                                                                </div>
                                                            )}
                                                        </Collapse>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            : <div>
                                <Collapse in={showProjects}>
                                    <div className="col-lg-8 p-2 col-12" style={{
                                        height: '90vh',
                                        overflowY: 'auto'
                                    }}>
                                        <div>
                                            <div className="d-flex justify-content-center">
                                                <div className="col-md-9 col-12">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                        <div>
                                                            <h2 className="m-0">My Projects</h2>
                                                        </div>
                                                        <div>
                                                            <Link to={`/advanced-dashboard/manage-projects`}>
                                                                <button className="btn btn-success btn-sm rounded-pill">
                                                                    Add New Project
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {addedProjects.map((a, i) => (
                                                        <div key={i} className="card border-0 shadow bg-transparent my-5 p-2">
                                                            <div className="d-flex col-12 justify-content-between align-items-center">
                                                                <div>
                                                                    <Link to={`manage-projects/single-project/${a._id}`} className="" state={a._id}><h3 className="fw-bold">{a.projectTitle}
                                                                        {a.isDeleted ? <span> (Deleted)</span> : null}</h3></Link>
                                                                    <p className={a?.isPrivate ? `text-danger  ` : `text-success  `}>Visibility: {a?.isPrivate ? 'Public' : 'Private'}</p>
                                                                </div>
                                                                <div className="col-4 border-start border-3 d-flex justify-content-around align-items-center">
                                                                    <Link to={`/advanced-dashboard/manage-projects`} state={{
                                                                        update: true,
                                                                        projectIdFromDash: a._id,
                                                                        projectTitleFromDash: a.projectTitle,
                                                                        projectDescriptionFromDash: a.projectDescription,
                                                                        projectCurrentReqFromDash: a.projectCurrentReq,
                                                                        projectTechFromDash: a.projectTech,
                                                                        isPrivateFromDash: a.isPrivate,
                                                                        budgetFromDash: a.budget
                                                                    }}>
                                                                        <button className={a.isDeleted ? "btn disabled btn-sm btn-warning rounded-pill" : "btn btn-sm btn-warning rounded-pill"}>
                                                                            <span className="d-md-inline fw-bold d-none">Update</span> <FontAwesomeIcon icon={faEdit} />
                                                                        </button>
                                                                    </Link>
                                                                    <button onClick={() => handelDeleteProject(a._id)}
                                                                        className={a.isDeleted ? "btn disabled btn-sm btn-danger rounded-pill" : "btn btn-sm btn-danger rounded-pill"}>
                                                                        <span className="d-md-inline fw-bold d-none">Delete</span> <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 d-flex flex-wrap ">
                                                                {a?.projectTech.map((p, i) => (
                                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                        <span className="fs-6 text-white  ">{p}</span>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <p className="fs-5">
                                                                {/* Render the description based on the expanded state */}
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: isExpanded === i
                                                                            ? a?.projectDescription // Full description when expanded
                                                                            : `${a?.projectDescription?.substring(0, 100)}...`, // Truncated description
                                                                    }}
                                                                />
                                                                {/* Conditional rendering of the button */}
                                                                {a?.projectDescription?.length > 100 && (
                                                                    <small className="text-primary fw-bold"
                                                                        onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {isExpanded === i ? 'Show Less' : 'Show More'}
                                                                    </small>
                                                                )}
                                                            </p>
                                                            <hr />
                                                            <div className="">
                                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                                    <div>
                                                                        <h4>Project Current Requirements</h4>
                                                                    </div>
                                                                    <div>
                                                                        {showReqs === i ?
                                                                            <button className="btn btn-sm bw-bold btn-danger rounded-pill  " onClick={() => setShowReqs(-1)}>
                                                                                Close
                                                                            </button> : <>
                                                                                {a?.projectCurrentReq.length === 0 ? <div className="text-center">
                                                                                    <span className="  fs-5 text-danger">No requirements Added</span>
                                                                                </div> :
                                                                                    <button className="btn btn-sm fw-bold btn-info rounded-pill  " onClick={() => setShowReqs(i)}>
                                                                                        Show
                                                                                    </button>}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Collapse in={showReqs === i}>
                                                                    {a?.projectCurrentReq.length !== 0 ? <div>{a?.projectCurrentReq.map((p, i) => (
                                                                        <div key={i}>
                                                                            <div className="d-flex align-items-start justify-content-start">
                                                                                <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                                                <div className="col-9">
                                                                                    <p className="m-0 fs-5">{p?.title}</p>
                                                                                    <p className="m-0 fs-5"  dangerouslySetInnerHTML={{ __html: p.description }}></p>
                                                                                </div>
                                                                            </div>
                                                                            <hr className="mx-2 mx-md-5" />
                                                                        </div>
                                                                    ))}</div> : <div className="text-center">
                                                                        <span className="  fs-5 text-danger">No requirements Added</span>
                                                                    </div>}
                                                                </Collapse>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>}
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default AdvAccountDashboard