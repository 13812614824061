import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Safely retrieve user
    return user?.token || ""; // Return token or fallback to empty string
};

export const fetchNotesByProject = (projectId) => {
    const token = getToken();
    return fetch(`${apiUrl}/projects/${projectId}/notes`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }).then(response => {
        return response.json();
    }).catch(err =>
        console.log("Fetch Notes Err: ", err)
    );
}

export const createNote = (noteData) => {
    const token = getToken();
    return fetch(`${apiUrl}/notes`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(noteData),
    }).then(response => {
        return response.json();
    }).catch(err =>
        console.log("Create Note Err: ", err)
    );
}

export const updateNote = (noteId, noteData) => {
    const token = getToken();
    return fetch(`${apiUrl}/notes/${noteId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(noteData),
    }).then(response => {
        return response.json();
    }).catch(err =>
        console.log("Update Note Err: ", err)
    );
}

export const deleteNote = (noteId) => {
    const token = getToken();
    return fetch(`${apiUrl}/notes/${noteId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }).then(response => {
        return response.json();
    }).catch(err =>
        console.log("Delete Note Err: ", err)
    );
}
