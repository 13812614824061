import { useState, useEffect } from "react"
import { useAuthStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { getUserProfile } from "../../actions/userProfileActions";
import { acceptTeamRequest, rejectTeamRequest, submitProposedAmount } from "../../actions/projectActions"
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Collapse, TextField } from "@mui/material";

const TeamRequested = () => {

    const data = useAuthStore(state => state.user)
    const [userData, setUserData] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [isExpanded, setIsExpanded] = useState(-1);

    const [negotiationForm, setNegotiationForm] = useState(-1)

    const [proposedAmountUser, setProposedAmountUser] = useState(null)

    useEffect(() => {
        getUserProfile(data.user._id).then(res => {
            setUserData(res)
            setLoading(false)
        })
    }, [refresh])

    const [showReqs, setShowReqs] = useState(-1)

    const handleAcceptRequest = (data) => {
        acceptTeamRequest(data).then(res => {
            if (res) {
                setRefresh(true)
                navigate('/dashboard/teams-joined')
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleRejectRequest = (data) => {
        rejectTeamRequest(data).then(res => {
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleProposedAmountChange = (e) => {
        setProposedAmountUser(e.target.value);
    };


    const handleSubmitProposedAmountUser = ({ projectId, userId, proposedAmountUser }) => {
        submitProposedAmount({ projectId, userId, proposedAmountUser }).then(res => {
            if (res) {
                setRefresh(true)
                setNegotiationForm(-1)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }


    return (
        <div className="col-md-10 col-12">

            {loading ?
                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                        <div className="text-center">
                            <CircularProgress />
                            <div>
                                Loading Tasks please wait
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="d-flex flex-wrap justify-content-between">
                    {userData?.pendingRequests?.length !== 0 ? <div style={{ width: '100%' }}>{userData?.pendingRequests?.map((a, i) => (
                        <div key={i} className="py-2 col-12">
                            <div className="shadow bg-white my-3 p-2">
                                <div className="d-flex col-12 justify-content-between align-items-start">
                                    <div className="col-7">
                                        <h3 className="fw-bold d-flex flex-wrap justify-content-between align-items-center">
                                            {a?.projectId?.projectTitle}
                                            <span className='fs-4 px-2'>INR.{a?.amount}</span></h3>
                                        <div className="col-12 py-2 d-flex flex-wrap ">
                                            {a?.projectId?.projectTech?.map((p, i) => (
                                                <div className="badge bg-primary rounded-pill mx-1 px-2 p-1 fw-bold text-white">{p}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-5 border-start border-3 d-flex flex-wrap justify-content-center align-items-center">
                                        <div className="col-12 d-flex flex-wrap gap-4 justify-content-center align-items-end">
                                            {a?.projectId?.negotiations[0]?.isSettled ? <>
                                                <div>
                                                    Proposal is Settled to {a?.projectId?.negotiations[0]?.settledAmount}
                                                </div>
                                            </> :
                                                <>
                                                    {a?.projectId?.negotiations.length === 0 ? null :
                                                        <div className="text-center">
                                                            <div className="fs-3">{a?.projectId?.negotiations[0]?.proposedAmountUser}</div>
                                                            <div>Your proposal</div>
                                                        </div>
                                                    }
                                                    {
                                                        !a?.projectId?.negotiations[0]?.proposedAmountHost ? null : <div>
                                                            <div className="text-center">
                                                                <div className="fs-3">{a?.projectId?.negotiations[0].proposedAmountHost}</div>
                                                                <div>Host proposal</div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>}
                                        </div>
                                        {a?.projectId?.negotiations?.length === 0 || a?.projectId?.negotiations[0]?.isSettled ? null : <hr className="col-10 p-0 m-2" />}
                                        {!a?.projectId?.negotiations[0]?.isSettled ?
                                            <div className="col-12 text-center py-2">
                                                <a
                                                    className="bg-transparent"
                                                    onClick={() => { negotiationForm === i ? setNegotiationForm(-1) : setNegotiationForm(i) }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecorationLine: 'none',
                                                        color: negotiationForm === i ? "red" : ""
                                                    }}
                                                >
                                                    {negotiationForm === i ? "Close" : " Want to negotiate?"}
                                                </a>
                                            </div> : null}
                                        <Collapse in={negotiationForm === i} className="col-11">
                                            <div>
                                                <div className="col-12">
                                                    <TextField
                                                        className="col-12 my-2"
                                                        variant="outlined"
                                                        value={proposedAmountUser}
                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                        type="number"
                                                        onChange={handleProposedAmountChange}
                                                        label="Propose Amount"
                                                    />
                                                </div>
                                                <div>
                                                    <button onClick={() => {
                                                        handleSubmitProposedAmountUser({
                                                            projectId: a?.projectId?._id,
                                                            userId: data?.user?._id,
                                                            proposedAmountUser: proposedAmountUser
                                                        })
                                                    }} className="col-12 btn btn-sm btn-success rounded-pill fw-bold">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                <p className="fs-5">
                                    {/* Render the description based on the expanded state */}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: isExpanded === i
                                                ? a?.projectId?.projectDescription // Full description when expanded
                                                : `${a?.projectId?.projectDescription?.substring(0, 100)}...`, // Truncated description
                                        }}
                                    />
                                    {/* Conditional rendering of the button */}
                                    {a?.projectId?.projectDescription?.length > 100 && (
                                        <small className="text-primary fw-bold"
                                            onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {isExpanded === i ? 'Show Less' : 'Show More'}
                                        </small>
                                    )}
                                </p>
                                <div className="col-12 d-flex flex-wrap justify-content-end align-items-center gap-3">
                                    <button onClick={() => handleAcceptRequest({ projectId: a?.projectId?._id, userId: data?.user._id })} className="btn btn-success rounded-pill">
                                        <span className="d-md-inline d-none fw-bold">Accept</span> <FontAwesomeIcon icon={faSquareCheck} />
                                    </button>
                                    <button onClick={() => handleRejectRequest({ projectId: a?.projectId?._id, userId: data?.user._id })} className="btn btn-danger rounded-pill">
                                        <span className="d-md-inline d-none fw-bold">Reject</span> <FontAwesomeIcon icon={faSquareXmark} />
                                    </button>
                                </div>
                                <hr />
                                <div className="py-3">
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div>
                                            <h4>Project Current Requirements</h4>
                                        </div>
                                        <div>
                                            {showReqs === i ?
                                                <button className="btn btn-danger rounded-pill fw-bold" onClick={() => setShowReqs(-1)}>
                                                    Close
                                                </button> : <>
                                                    {a?.projectId?.projectCurrentReq?.length === 0 ? <div className="text-center">
                                                        <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                                    </div> :
                                                        <button className="btn btn-info rounded-pill fw-bold" onClick={() => setShowReqs(i)}>
                                                            Show
                                                        </button>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <Collapse in={showReqs === i}>
                                        {a?.requirements?.length !== 0 ? <div>{a?.requirements?.map((p, i) => (
                                            <div key={i}>
                                                <div className="d-flex align-items-start justify-content-start">
                                                    <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                    <div className="col-9">
                                                        <p className="m-0 fs-5">{p?.title}</p>
                                                        <p className="m-0 fs-5" dangerouslySetInnerHTML={{ __html: p.desc }}></p>
                                                    </div>
                                                </div>
                                                {a?.requirements.length - 1 === i ? null : <hr className="mx-2 mx-md-5" />}
                                            </div>
                                        ))}</div> : <div className="text-center">
                                            <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                        </div>}
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    ))}</div> : <div className="shadow bg-white col-12 text-center p-2 my-2">
                        <h4>No Teams Requested</h4>
                        <div>
                            <Link to={'/dashboard/display-public-projects'}>
                                <button className="btn btn-primary rounded-pill">
                                    Look for projects
                                </button>
                            </Link>
                        </div>
                    </div>}
                </div>
            }
        </div>
    )
}

export default TeamRequested