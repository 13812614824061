import { useEffect, useState } from "react";
import { getProjectTransactions, initiatePayout } from "../../actions/projectActions";
import { useAuthStore } from '../../store/store';
import { Collapse, TextField } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

const ProjectTransactions = ({ projectId, projectHost }) => {

    const data = useAuthStore(state => state.user);

    const [transactionData, setTransactionData] = useState([]);
    const [teamMemberData, setTeamMemberData] = useState([]);
    const [showingTransactions, setShowingTransactions] = useState(true); // Toggle state
    const [visibleTransactions, setVisibleTransactions] = useState({}); // Manage visibility for each team member
    const [paymentForms, setPaymentForms] = useState({}); // Manage the state for forms visibility
    const [paymentAmounts, setPaymentAmounts] = useState({}); // Store the entered payment amounts
    const [showPayoutForm, setShowPayoutForm] = useState(-1)

    const isMdScreen = useMediaQuery('(min-width: 786px)');

    useEffect(() => {
        getTransactions();
    }, []);

    const getTransactions = () => {
        getProjectTransactions(projectId)
            .then(res => {
                setTransactionData(res.projectTransactions);
                setTeamMemberData(res.teamMemberTransactions);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleToggleTransactions = (memberId) => {
        setVisibleTransactions((prevState) => ({
            ...prevState,
            [memberId]: !prevState[memberId], // Toggle visibility for the specific team member
        }));
    };

    const handleTogglePaymentForm = (memberId) => {
        setPaymentForms((prevState) => ({
            ...prevState,
            [memberId]: !prevState[memberId], // Toggle visibility for the payment form
        }));
    };

    const handlePaymentAmountChange = (memberId, amount) => {
        setPaymentAmounts((prevState) => ({
            ...prevState,
            [memberId]: amount, // Update the amount for the specific member
        }));
    };


    const handleInitiatePayout = (memberId) => {
        const amount = paymentAmounts[memberId];
        initiatePayout({
            memberId,
            amount,
            projectId,
            projectHost: data.user._id
        })
            .then(res => {
                getTransactions(); // Refresh transactions after payout
                setPaymentForms((prevState) => ({ ...prevState, [memberId]: false }));
                setShowPayoutForm(-1)
            })
            .catch(err => {
                console.log("Error initiating payout:", err);
            });
    };

    return (
        <div className="py-2">
            <div className={`d-flex flex-wrap ${isMdScreen ? `justify-content-around` : 'jusitify-content-start'} align-items-center`}>
                <span style={{cursor: 'pointer'}}
                    onClick={() => setShowingTransactions(true)}
                    className={`fw-bold ${showingTransactions ? 'text-primary text-decoration-underline' : 'text-secondary'}`}
                >
                    View Project Transactions
                </span>
                <span style={{cursor: 'pointer'}}
                    onClick={() => setShowingTransactions(false)}
                    className={`fw-bold ${!showingTransactions ? 'text-primary text-decoration-underline' : 'text-secondary'}`}
                >
                    View Team Member Payouts
                </span>
            </div>
            <div className="py-2">
                {showingTransactions ? (
                    <div style={{overflow: 'scroll'}}>
                        <h3 className="text-center py-2">Project Transactions</h3>
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionData.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{transaction._id}</td>
                                        <td>Rs.{transaction.amount}</td>
                                        <td>{transaction.paymentStatus}</td>
                                        <td>{new Date(transaction.timestamp).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="card border-0 p-2">
                        <h3 className="text-center py-2">Team Members' Payouts</h3>
                        {teamMemberData.map((member, index) => (
                            <div key={index} className="team-member pb-3 my-2 shadow">
                                <div className="p-3 rounded mb-3">
                                    <h5 className="m-0"><strong>{member.memberName}</strong></h5>
                                    <hr />
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="m-0"><strong>Settled Amount:</strong> Rs.{member.settledAmount}</p>
                                        <p className="m-0"><strong>Total Paid:</strong> Rs.{member.totalPaid}</p>
                                        <p className="m-0"><strong>Remaining Amount:</strong> Rs.{member.remainingAmount}</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap justify-content-around align-items-center pt-2">
                                    <button
                                        onClick={() => handleToggleTransactions(member.memberId)}
                                        className={`btn btn-sm rounded-pill ${visibleTransactions[member.memberId] ? 'btn-danger' : 'btn-secondary'}`}
                                    >
                                        {visibleTransactions[member.memberId] ? "Hide Transactions" : "Show Transactions"}
                                    </button>

                                    {showPayoutForm === index ?
                                        <button
                                            onClick={() => setShowPayoutForm(-1)}
                                            className={`btn btn-sm rounded-pill btn-danger`}
                                        >
                                            Cancel Payout
                                        </button>
                                        :
                                        <button
                                            onClick={() => {
                                                setShowPayoutForm(index)
                                            }}
                                            className={`btn btn-sm rounded-pill btn-primary`}
                                        >
                                            Initiate Payout
                                        </button>
                                    }
                                </div>

                                {/* Payment Form inside Collapse */}
                                <Collapse in={showPayoutForm === index} className="px-2">
                                    <div className="mt-3">
                                        <label className="form-label" htmlFor={`paymentAmount-${member.memberId}`}>
                                            Enter Payment Amount (Max: Rs.{member.remainingAmount}):
                                        </label>
                                        <TextField
                                            type="number"
                                            label="Enter Amount"
                                            id={`paymentAmount-${member.memberId}`}
                                            className="col-12"
                                            value={paymentAmounts[member.memberId] || ''}
                                            onChange={(e) =>
                                                handlePaymentAmountChange(member.memberId, Math.min(e.target.value, member.remainingAmount))
                                            }
                                            max={member.remainingAmount}
                                            min="1"
                                        />
                                        <button
                                            onClick={() => handleInitiatePayout(member.memberId)}
                                            className="btn btn-sm btn-success mt-2 rounded-pill"
                                            disabled={!paymentAmounts[member.memberId] || paymentAmounts[member.memberId] <= 0}
                                        >
                                            Submit Payout
                                        </button>
                                    </div>
                                </Collapse>

                                {/* Transactions Table inside Collapse */}
                                <Collapse in={visibleTransactions[member.memberId]}  style={{overflow: 'scroll'}} className="px-2">
                                    <table className="table mt-3">
                                        <thead className="">
                                            <tr>
                                                <th scope="col">Transaction ID</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.transactions.map((transaction, transIndex) => (
                                                <tr key={transIndex}>
                                                    <td>{transaction._id}</td>
                                                    <td>Rs.{transaction.amount}</td>
                                                    <td>{transaction.status}</td>
                                                    <td>{new Date(transaction.date).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectTransactions;
