import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { fetchEmail, loginUser, registerUser } from '../actions/authActions';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthStore } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import video_home from "./assets/video_home1.mp4"
import { Helmet } from 'react-helmet-async';
const AuthComponent = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [values, setValues] = useState({
        sessionId: '',
        email: '',
        password: '',
        userName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        termsAccepted: false,
        field: '',
        error: '',
        errorMessage: '',
        errors: {}
    })

    const testData = [
        'Student / Freelancer',
        // 'Project host'
    ]

    const navigate = useNavigate()

    // var passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    const setStateUser = useAuthStore(state => state.setUser)

    const { firstName, middleName, lastName, field, sessionId, email, password, termsAccepted, userName, error, errorMessage } = values
    const [showPassword, setShowPassword] = useState(false);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);

    const emailRegex = /\S+@\S+\.\S+/;

    const capitalizeFirstLetter = (value) => {
        if (!value) return '';
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const handleChange = name => event => {
        setValues({
            ...values,
            error: '',
            errorMessage: '',
            errors: {},
            [name]: (name === 'password' || name === 'email')
                ? event.target.value
                : capitalizeFirstLetter(event.target.value)
        });
    };

    const handleSubmit = (email) => {
        setIsLoading(true)
        if (emailRegex.test(email)) {
            fetchEmail({ email: email }).then(res => {
                if (res.userExist) {
                    setValues({ ...values, sessionId: res.sessionId, userName: res.userName, error: '', errorMessage: '' });
                    setShowRegistrationForm(false)
                    setIsFirstVisible(!isFirstVisible);
                } else {
                    setValues({ ...values, sessionId: res.sessionId, error: '', errorMessage: '' });
                    setShowRegistrationForm(true)
                    setIsFirstVisible(!isFirstVisible);
                }
            })
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" })
        }
        setIsLoading(false)
    }

    const handlePassword = (password) => {
        if (password !== '') {
            let data = {
                sessionId: sessionId,
                password: password
            }
            loginUser(data).then(res => {
                if (res.status === false) {
                    return setValues({ ...values, error: true, errorMessage: res.error })
                }
                if (res.status === true) {
                    setStateUser(res)
                    localStorage.setItem('user', JSON.stringify(res))
                    if (res.user.isAdvancedAccount) {
                        return navigate('/advanced-dashboard')
                    } else {
                        return navigate('/dashboard')
                    }
                }
            }).catch(err => {
                setValues({ ...values, error: true, errorMessage: "Something went wrong" })
                console.log(err)
            })
        } else {
            setValues({ ...values, errorMessage: "Please provide valid data" })
        }
        setIsLoading(false)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [isFirstVisible, setIsFirstVisible] = useState(true);

    const handleSubmitRegister = (firstName, field, middleName, lastName, password) => {
        let fieldErrors = {};

        if (!firstName.trim()) fieldErrors.firstName = "First Name is required";
        if (!middleName.trim()) fieldErrors.middleName = "Middle Name is required";
        if (!lastName.trim()) fieldErrors.lastName = "Last Name is required";
        if (!field) fieldErrors.field = "User type is required";
        if (termsAccepted === false) fieldErrors.termsAccepted = "Accept before moving further"
        if (!password) {
            fieldErrors.password = "Password is required";
        } else if (password.length <= 7) {
            fieldErrors.password = "Password must be at least 8 characters";
        }

        if (Object.keys(fieldErrors).length > 0) {
            return setValues({ ...values, errors: fieldErrors });
        }

        let data = {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            field: field,
            sessionId: sessionId,
            password: password,
        };

        registerUser(data)
            .then((res) => {
                setStateUser(res);
                localStorage.setItem("user", JSON.stringify(res));
                if (res.user.isAdvancedAccount) {
                    return navigate("/advanced-dashboard");
                } else {
                    return navigate("/dashboard");
                }
            })
            .catch((err) => {
                console.error("Registration Error:", err);
            });
    };


    return (
        <div style={{ height: '100vh' }}>
            <Helmet>
                <title>Get Started with DevSemble - Begin Your Journey</title>
                <meta
                    name="description"
                    content="Ready to start your journey with DevSemble? Sign up, create a profile, and dive into a world of opportunities to collaborate and grow in the tech ecosystem."
                />
            </Helmet>
            <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="col-lg-6 col-12 d-flex flex-wrap justify-content-center align-items-center bg-light position-relative" style={{ height: '100vh', zIndex: '11', overflowX: "hidden" }}>
                    {/* Floating Shapes in the background */}
                    <div className="floating-shape shape1"></div>
                    <div className="floating-shape shape2"></div>
                    <div className="floating-shape shape3"></div>

                    {/* Centered Video */}
                    <div className={`d-flex flex-wrap justify-content-center ${window.innerWidth >= 992 ? 'align-items-center' : 'align-content-around'} col-12`}
                        style={{ height: '100vh', zIndex: '12' }}>
                        <video autoPlay loop muted className="centered-video">
                            <source src={video_home} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className='d-lg-none d-flex justify-content-center align-items-center col-12' style={{ minHeight: '20vh', height: 'auto', overflow: 'visible' }}>
                            <div className={`box col-10 d-flex flex-wrap justify-content-center align-items-center ${isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                                <div className='col-12' >
                                    <div>
                                        <div className="fw-bold display-4">
                                            Get Started
                                        </div>
                                        <div className="fw-bold fs-2">
                                            with your email
                                        </div>
                                        <div className='py-3'>
                                            <div className='col-12'>
                                                <TextField
                                                    required={true}
                                                    className='m-0 col-12 bg-light'
                                                    error={errorMessage === "" ? false : true}
                                                    type='email'
                                                    value={email}
                                                    label={errorMessage === '' ? "Email" : errorMessage}
                                                    onChange={handleChange('email')}
                                                    variant="outlined" />
                                            </div>
                                            <div className='py-3 d-flex justify-content-between align-items-center'>
                                                <div className='text-danger fw-bold'>
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button className='fw-bold btn btn-primary rounded-pill'
                                                    onClick={() => {
                                                        setIsLoading(true)
                                                        handleSubmit(email)
                                                    }}>
                                                    {isLoading ? <CircularProgress /> : <>
                                                        Lets Go <FontAwesomeIcon icon={faArrowRight} />
                                                    </>}
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`box col-11 d-flex flex-wrap justify-content-center align-items-center ${!isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`} style={{ height: 'auto' }}>
                                {showRegistrationForm ?
                                    <div className='col-12 bg-light p-3 shadow-lg' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <div className='py-2'>
                                            <button className='btn btn-sm btn-warning fw-bold rounded-pill' onClick={() => {
                                                setValues({ ...values, sessionId: '' })
                                                setIsFirstVisible(!isFirstVisible)
                                            }}>
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        </div>
                                        <div className=''>
                                            <div className="fw-bold fs-2">
                                                Get Yourself Register
                                            </div>
                                            <div className="py-3">
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmitRegister(firstName, field, middleName, lastName, password, termsAccepted);
                                                }}>
                                                    <div className="col-12">
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.firstName)}
                                                            helperText={values.errors?.firstName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="First Name"
                                                            onChange={handleChange("firstName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.middleName)}
                                                            helperText={values.errors?.middleName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="Middle Name"
                                                            onChange={handleChange("middleName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.lastName)}
                                                            helperText={values.errors?.lastName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="Last Name"
                                                            onChange={handleChange("lastName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.password)}
                                                            helperText={values.errors?.password}
                                                            type={showPassword ? "text" : "password"}
                                                            value={password}
                                                            label="Password"
                                                            onChange={handleChange("password")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <div className="py-2">
                                                            <InputLabel
                                                                id="demo-multiple-chip-label"
                                                                style={{
                                                                    color: errorMessage === '' || error !== "fieldErr" ? "#0275d8" : "red",
                                                                }}
                                                            >
                                                                {errorMessage === '' || error !== "fieldErr" ? "Select type of user" : errorMessage}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                style={{ width: "100%", color: "black" }}
                                                                error={Boolean(values.errors?.field)}
                                                                value={field}
                                                                defaultValue="Student / Freelancer"
                                                                onChange={handleChange("field")}
                                                                input={<OutlinedInput id="select-multiple-chip" label="" />}
                                                            >
                                                                {testData.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem value={''} disabled>
                                                                    Project Host (coming soon)
                                                                </MenuItem>
                                                            </Select>
                                                            {values.errors?.field && (
                                                                <span style={{ color: "red" }}>{values.errors.field}</span>
                                                            )}
                                                        </div>
                                                        <div className="my-1">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={termsAccepted}
                                                                        onChange={handleChange('termsAccepted')}
                                                                        color="primary"
                                                                        required
                                                                    />
                                                                }
                                                                label={
                                                                    <span>
                                                                        I agree to the{" "}
                                                                        <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                                            Terms of Service
                                                                        </Link>{" "}
                                                                        and{" "}
                                                                        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                                            Privacy Policy
                                                                        </Link>.
                                                                    </span>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-3 d-flex justify-content-between">
                                                        <div className="text-danger fw-bold">
                                                            {errorMessage === '' ? "" : errorMessage}
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="fw-bold btn btn-primary rounded-pill"
                                                            disabled={!termsAccepted} // Disable button if terms are not accepted
                                                        >
                                                            Register
                                                        </button>
                                                    </div>
                                                    <div className='text-center'>
                                                        <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-12 bg-light p-3 shadow-lg' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <div className='py-2'>
                                            <button className='btn btn-sm btn-warning fw-bold rounded-pill' onClick={() => {
                                                setValues({ ...values, sessionId: '' })
                                                setIsFirstVisible(!isFirstVisible)
                                            }}>
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        </div>
                                        <div>
                                            <div className="fw-bold fs-2">
                                                Welcome Back
                                            </div>
                                            <div className="fw-bold display-4">
                                                {userName}
                                            </div>
                                            <div className='py-3'>
                                                <div className='col-12'>
                                                    <TextField
                                                        className='m-0 col-12 bg-light'
                                                        error={errorMessage === "" ? false : true}
                                                        type={showPassword ? "text" : "password"}
                                                        value={password}
                                                        label={errorMessage === '' ? "Password" : errorMessage}
                                                        onChange={handleChange('password')}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="outlined" />
                                                </div>
                                                <div className='py-3 d-flex justify-content-between align-items-center'>
                                                    <div className='text-danger fw-bold'>
                                                        {errorMessage === '' ? "" : errorMessage}
                                                    </div>
                                                    <button className='fw-bold btn btn-primary rounded-pill'
                                                        onClick={() => {
                                                            setIsLoading(true)
                                                            handlePassword(password)
                                                        }}>
                                                        Login
                                                    </button>
                                                </div>
                                                <div className='text-center'>
                                                    <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 d-none d-lg-flex flex-wrap justify-content-center align-items-center">
                    <div className={`col-6 box d-flex flex-wrap justify-content-center align-items-center ${isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                        <div className="col-8">
                            <div>
                                <div className="fw-bold display-4">Get Started</div>
                                <div className="fw-bold fs-2">with your email</div>
                                <div className="py-3">
                                    <form onSubmit={(e) => {
                                        e.preventDefault(); // Prevent page reload
                                        handleSubmit(email); // Your submit logic
                                    }}>
                                        <div className="col-12">
                                            <TextField
                                                required={true}
                                                className="m-0 col-12"
                                                error={errorMessage === "" ? false : true}
                                                type="email"
                                                value={email}
                                                label={errorMessage === '' ? "Email" : errorMessage}
                                                onChange={handleChange('email')}
                                                variant="outlined" />
                                        </div>
                                        <div className="py-3 d-flex justify-content-between align-items-center">
                                            <div className="text-danger fw-bold">
                                                {errorMessage === '' ? "" : errorMessage}
                                            </div>
                                            <button type="submit" className="fw-bold btn btn-primary rounded-pill">
                                                Lets Go <FontAwesomeIcon icon={faArrowRight} />
                                            </button>
                                        </div>
                                        <div className='text-center'>
                                            <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`col-6 box d-flex flex-wrap justify-content-center align-items-center ${!isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                        {showRegistrationForm ?
                            <div className="col-8">
                                <div className="py-2">
                                    <button
                                        className="btn btn-sm btn-warning fw-bold rounded-pill"
                                        onClick={() => {
                                            setValues({ ...values, sessionId: '' });
                                            setIsFirstVisible(!isFirstVisible);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                                    </button>
                                </div>
                                <div>
                                    <div className="fw-bold fs-2">Get Yourself Register</div>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmitRegister(firstName, field, middleName, lastName, password, termsAccepted);
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.firstName)}
                                                    helperText={values.errors?.firstName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="First Name"
                                                    onChange={handleChange("firstName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.middleName)}
                                                    helperText={values.errors?.middleName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="Middle Name"
                                                    onChange={handleChange("middleName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.lastName)}
                                                    helperText={values.errors?.lastName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="Last Name"
                                                    onChange={handleChange("lastName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.password)}
                                                    helperText={values.errors?.password}
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    label="Password"
                                                    onChange={handleChange("password")}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <div className="py-2">
                                                    <InputLabel
                                                        id="demo-multiple-chip-label"
                                                        style={{
                                                            color: errorMessage === '' || error !== "fieldErr" ? "#0275d8" : "red",
                                                        }}
                                                    >
                                                        {errorMessage === '' || error !== "fieldErr" ? "Select type of user" : errorMessage}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        style={{ width: "100%", color: "black" }}
                                                        error={Boolean(values.errors?.field)}
                                                        value={field}
                                                        defaultValue="Student / Freelancer"
                                                        onChange={handleChange("field")}
                                                        input={<OutlinedInput id="select-multiple-chip" label="" />}
                                                    >
                                                        {testData.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                        <MenuItem value={''} disabled>
                                                            Project Host (coming soon)
                                                        </MenuItem>
                                                    </Select>
                                                    {values.errors?.field && (
                                                        <span style={{ color: "red" }}>{values.errors.field}</span>
                                                    )}
                                                </div>
                                                <div className="my-1">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={termsAccepted}
                                                                onChange={handleChange('termsAccepted')}
                                                                color="primary"
                                                                required
                                                            />
                                                        }
                                                        label={
                                                            <span>
                                                                I agree to the{" "}
                                                                <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                                    Terms of Service
                                                                </Link>{" "}
                                                                and{" "}
                                                                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                                    Privacy Policy
                                                                </Link>.
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="py-3 d-flex justify-content-between align-items-center">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="fw-bold btn btn-primary rounded-pill"
                                                    disabled={!termsAccepted} // Disable button if terms are not accepted
                                                >
                                                    Register
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-8">
                                <div className="py-2">
                                    <button className="btn btn-sm btn-warning fw-bold rounded-pill" onClick={() => {
                                        setValues({ ...values, sessionId: '' });
                                        setIsFirstVisible(!isFirstVisible);
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                                    </button>
                                </div>
                                <div>
                                    <div className="fw-bold fs-2">Welcome Back</div>
                                    <div className="fw-bold display-4">{userName}</div>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault(); // Prevents page reload
                                            handlePassword(password); // Your submit logic
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    className="m-0 col-12"
                                                    error={errorMessage === "" ? false : true}
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    label={errorMessage === '' ? "Password" : errorMessage}
                                                    onChange={handleChange('password')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="outlined" />
                                            </div>
                                            <div className="py-3 d-flex justify-content-between">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button type="submit" className="fw-bold btn btn-primary rounded-pill">
                                                    Login
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthComponent