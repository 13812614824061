import { Collapse, TextField, Chip, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faComments, faEdit } from "@fortawesome/free-solid-svg-icons";
import { createDiscussion, getDiscussionsByProject, updateDiscussion } from "../../actions/discussionActions";
import { Link, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { useAuthStore } from "../../store/store";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "code-block"],
        ["clean"],
    ],
    syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
    },
};

const Discussions = ({ projectCoHost, projectHost, projectTech }) => {
    const [viewForm, setViewForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingDiscussionId, setEditingDiscussionId] = useState(null);
    const [discussions, setDiscussions] = useState([]);
    const { pId: projectId } = useParams();
    const data = useAuthStore(state => state.user)

    const [formData, setFormData] = useState({
        title: '',
        description: '',
    });

    const [tags, setTags] = useState([]); // State for tags

    useEffect(() => {
        getDiscussionsByProject(projectId)
            .then((res) => {
                setDiscussions(res || []);
            })
            .catch((err) => {
                console.error("Error fetching discussions:", err);
            });
    }, [projectId]);

    const canEditDiscussion = (discussion) => {
        return (
            data.user._id === discussion.createdBy._id ||
            data.user._id === projectHost ||
            data.user._id === projectCoHost
        );
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleQuillChange = value => {
        setFormData(prevState => ({
            ...prevState,
            description: value
        }));
    };

    const handleTagsChange = (event, value) => {
        setTags(value); // Update tags state
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const discussionData = {
            title: formData.title,
            description: formData.description,
            tags, // Include tags in the data
            projectId,
        };

        if (isEditing) {
            updateDiscussion(editingDiscussionId, discussionData)
                .then(() => getDiscussionsByProject(projectId))
                .then((updatedDiscussions) => {
                    setDiscussions(updatedDiscussions || []);
                    resetForm();
                })
                .catch((err) => console.error("Error updating discussion:", err));
        } else {
            createDiscussion(discussionData)
                .then(() => getDiscussionsByProject(projectId))
                .then((updatedDiscussions) => {
                    setDiscussions(updatedDiscussions || []);
                    resetForm();
                })
                .catch((err) => console.error("Error creating discussion:", err));
        }
    };

    const handleEdit = (discussion) => {
        setIsEditing(true);
        setEditingDiscussionId(discussion._id);
        setFormData({ title: discussion.title, description: discussion.description });
        setViewForm(true);
    };

    const resetForm = () => {
        setFormData({ title: '', description: '' });
        setViewForm(false);
        setIsEditing(false);
        setEditingDiscussionId(null);
    };

    return (
        <div>
            <div className="p-2">
                <Collapse in={!viewForm}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <h3>Discussions</h3>
                        <button
                            onClick={() => setViewForm(true)}
                            className="btn btn-sm btn-primary rounded-pill fw-bold"
                        >
                            <span className="px-2 fw-bold">Start New Discussion</span>
                            <FontAwesomeIcon size="1x" icon={faArrowRight} />
                        </button>
                    </div>
                    <div className="mt-4">
                        {discussions.length > 0 ? (
                            discussions.map((d) => (
                                <div key={d._id} className="card border-0 mb-3 shadow">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div className="">
                                                <Link to={`/discussion/${d.slug}`} className="text-decoration-none">
                                                    <h5 className="card-title">{d.title}</h5>
                                                </Link>
                                            </div>
                                            <div>
                                                {canEditDiscussion(d) && (
                                                    <button
                                                        onClick={() => handleEdit(d)}
                                                        className="btn btn-sm btn-warning mt-2"
                                                    >
                                                        <FontAwesomeIcon size="1x" icon={faEdit} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <span
                                            className="fs-6 m-0"
                                            dangerouslySetInnerHTML={{ __html: d.description }}
                                        ></span>
                                        <div className="py-1">
                                            {d.tags && d.tags.length > 0 && (
                                                <div className="">
                                                    {d.tags.map((tag, index) => (
                                                        <span key={index} className="badge bg-primary rounded-pill me-2">
                                                            {tag}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-muted">
                                                By {d.createdBy.firstName} {d.createdBy.lastName}
                                            </span>
                                            <span className="text-muted">
                                                {new Date(d.createdAt).toLocaleDateString()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted">No discussions available.</p>
                        )}
                    </div>
                </Collapse>
                <Collapse in={viewForm}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            onClick={resetForm}
                            className="btn btn-sm btn-warning rounded-pill fw-bold"
                        >
                            <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                            <span className="px-2 fw-bold">Back</span>
                        </button>
                        <h3>{isEditing ? "Edit Discussion" : "Initiate Public Discussions"}</h3>
                    </div>
                    <div className="py-3">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <TextField
                                    className="form-control"
                                    fullWidth
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="tags" className="form-label">Tags</label>
                                <Autocomplete
                                    multiple
                                    id="tags"
                                    options={projectTech} // Use projectTech as predefined options
                                    freeSolo
                                    value={tags}
                                    onChange={handleTagsChange}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={index} label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Add tags" />
                                    )}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="description" className="form-label">Description</label>
                                <ReactQuill
                                    id="description"
                                    theme="snow"
                                    value={formData.description}
                                    onChange={handleQuillChange}
                                    placeholder="Write your discussion description here..."
                                    modules={modules}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary col-12 fw-bold"
                            >
                                {isEditing ? "Update Discussion" : "Create Discussion"}
                            </button>
                        </form>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default Discussions;
