import { useLocation } from "react-router-dom"
import { gitUrl } from "../../constants";

const MyProfile = () => {
    const data = useLocation().state
    const handleLoginGit = () => {
        window.location.href = gitUrl;
    };
    return (
        <div className="col-md-9 col-12 bg-white">
            <div className="">
                <span className="fs-2 m-0"><b className="m-0">{data?.user?.firstName} {data?.user?.lastName}</b></span>
            </div>
            <div>
                <span className="fs-4 fw-bold">Rank Range: <span className="text-primary">{data?.profile?.rankId?.rankRange}</span></span>
            </div>
            <div className="">
                <button onClick={() => handleLoginGit()} className="btn btn-sm btn-danger fw-bold">
                    Integrate GitHub
                </button>
            </div>
            <div>
                <div>
                    <div className="py-3">
                        <div className='shadow py-3'>
                            <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Work exprience you added</h3></div>
                            {data?.profile?.workExprience?.length !== 0 ? <div className='py-2'>{data?.profile?.workExprience?.map((w, i) =>
                                <div className='container-fluid border-primary my-0 p-2' key={i}>
                                    <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                    <div><b className=""></b>{w.description}</div><br />
                                    <div className="fw-bold" style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <div key={i} className='badge bg-primary rounded-pill mx-1 px-2 p-1 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</div>)}</div>
                                    {data?.profile?.workExprience?.length - 1 === i ? null : <hr />}
                                </div>)}</div> : null}
                        </div>
                    </div>
                    <div className="py-3">
                        <div className='shadow py-3'>
                            <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Education exprience you added</h3></div>
                            {data?.profile?.educationExprience?.length !== 0 ? <div className='py-0'>{data?.profile?.educationExprience?.map((w, i) =>
                                <div className='container-fluid border-primary my-0 p-2' key={i}>
                                    <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                    <div><b className=""></b>{w.eduDescription}</div><br />
                                    {data?.profile?.educationExprience?.length - 1 === i ? null : <hr />}
                                </div>)}</div> : null}
                        </div>
                    </div>
                    <div className="py-3">
                        <div className='shadow py-3'>
                            <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Other links you added</h3></div>
                            {data?.profile?.otherLinks?.length !== 0 ? <div className='py-0'>{data?.profile?.otherLinks?.map((w, i) =>
                                <div className='container-fluid border-primary my-0 p-2' key={i}>
                                    <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                    <div><b className="">Link:</b> {w.linkHref}</div>
                                    {data?.profile?.otherLinks?.length - 1 === i ? null : <hr />}
                                </div>)}</div> : <div className="py-3 text-center">
                                <h4>No Links Added</h4>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile