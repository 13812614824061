import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { useAuthStore } from '../../store/store';
import TextField from '@mui/material/TextField';
import { Autocomplete, FormControl, IconButton, setRef, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { submitProfile } from '../../actions/userProfileActions';
import AuthNavbar from "../AuthNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { getRepos, postGitCreds } from "../../actions/gitOAuthActions";
import { updateAboutYou } from '../../actions/userProfileActions'
import { gitUrl } from "../../constants";
import MenuIcon from '@mui/icons-material/Menu';

const DashBoard = () => {

    const data = useAuthStore(state => state.user)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()
    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const [refresh, setRefresh] = useState(false)

    const [isUpdate, setIsUpdate] = useState(false)
    const [values, setValues] = useState({
        text: '',
        errorMessage: '',
        error: ''
    })

    useEffect(() => {
        redirect()
        setOpenModelGit(true)
    }, [refresh])

    const redirect = () => {
        if (data.user.isReviewer === true) {
            return navigate('/review-lists')
        }
        if (data.user.isAdvancedAccount === true) {
            return navigate('/advanced-dashboard')
        }
    }

    const [workDisplay, setWorkDisplay] = useState(true)
    const [workFormDisplay, setWorkFormDisplay] = useState(true)
    const [eduDisplay, setEduDisplay] = useState(false)
    const [eduFormDisplay, setEduFormDisplay] = useState(false)
    const [otherLinksForm, setOtherLinkForm] = useState(false)
    const [displayOtherLink, setDisplayOtherLink] = useState(false)
    const [displayProfile, setDisplayProfile] = useState(true)
    const [displayViewData, setDisplayViewData] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const [index, setIndex] = useState(null)

    const [work, setWork] = useState({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        workLink: '',
        repoName: '',
        repoLink: '',
        currentlyPursuing: '',
        techValue: '',
        errorMessageWork: '',
        errorWork: ''
    })
    const [techUsed, setTechUsed] = useState([])

    const [education, setEducation] = useState({
        courseName: '',
        institutionName: '',
        currentlyPursuingEdu: '',
        eduErrorMessageWork: '',
        eduErrorWork: ''
    })

    const [linkData, setLinkData] = useState({
        linkTitle: '',
        linkHref: '',
        linkError: '',
        linkErrorMessage: '',
    })

    const [workExprience, setWorkExprience] = useState([])
    const [eduExprience, setEduExprience] = useState([])
    const [otherLinks, setOtherLink] = useState([])

    const { title, description, currentlyPursuing, workLink, techValue } = work

    const { courseName, institutionName, currentlyPursuingEdu } = education

    const { githubProfile, stackOverFlowProfile, linkTitle, linkHref } = linkData

    const { error, errorMessage } = values

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));

    const [openModelGit, setOpenModelGit] = useState(false)

    const location = useLocation();

    const [loading, setLoading] = useState(true)

    const [responseLoading, setResponseLoading] = useState(false)

    const [aboutYou, setAboutYou] = useState('')
    const [aboutYouUpdate, setAboutYouUpdate] = useState(false)

    const handleSubmitAboutYou = () => {
        updateAboutYou({ userId: data.user._id, aboutYou: aboutYou }).then(res => {
            if (res.success) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const profile = res.profile;
                const updatedUser = {
                    ...currentUser,
                    message: "Your Profile is ready",
                    profileStatus: true,
                    profile: {
                        ...currentUser.profile,
                        aboutYou: profile.aboutYou
                    }
                };

                localStorage.setItem('user', JSON.stringify(updatedUser));
                setStateUser(updatedUser);
                setResponseLoading(false)
                setAboutYouUpdate(false)
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    useEffect(() => {
        const getQueryParams = () => {
            const searchParams = new URLSearchParams(location.search);
            const githubId = searchParams.get('githubId');
            const accessToken = searchParams.get('accessToken');
            return { userId: data.user._id, githubId, accessToken };
        };

        const userData = getQueryParams();
        if (userData.accessToken === null || userData.githubId === null) {
        } else {
            postGitCreds(userData).then(res => { })
        }
        navigate('/dashboard', { replace: true });
    }, [location.search]);

    const [gitTokenAvailable, setGitTokenAvailable] = useState(false)

    useEffect(() => {
        getRepos(data.user._id).then(response => {
            if (response.success) {
                setGitTokenAvailable(true)
                setGitRepos([...response.privateRepos, ...response.publicRepos, ...response.collaboratorRepos])
                setLoading(false)
            } else {
                setGitTokenAvailable(false)
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const [selectedRepository, setSelectedRepository] = useState(null);
    const [gitRepos, setGitRepos] = useState([])
    const uniqueGitRepos = gitRepos.filter(
        (repo, index, self) =>
            index === self.findIndex((r) => r.name === repo.name && r.url === repo.url)
    );

    const handleChangeWork = name => event => {
        setWork({ ...work, errorWork: '', errorMessageWork: '', [name]: event.target.value });
    };

    const handleChangeEdu = name => event => {
        setEducation({ ...education, eduError: '', eduErrorMessage: '', [name]: event.target.value });
    };

    const handleChangeLink = name => event => {
        setLinkData({ ...linkData, linkError: '', linkErrorMessage: '', [name]: event.target.value })
    }

    const handleCurrentlyPursuingEdu = (event) => {
        setEducation({ ...education, currentlyPursuingEdu: event.target.value, endDate: "Currently Working" })
    }

    const onAppendEduArray = (newInput) => {
        setEduExprience(eduExprience => [...eduExprience, newInput])
        setEduDisplay(true)
        setEducation({
            courseName: '',
            institutionName: '',
            eduErrorMessageWork: '',
            eduErrorWork: '',
            currentlyPursuingEdu: 'false'
        })
    }

    const updateWorkTech = (data) => {
        // Split input by commas, trim whitespace, and filter out empty values
        const techArray = data.split(',').map(item => item.trim()).filter(item => item);

        // Avoid adding duplicate values
        const newTechUsed = [...new Set([...techUsed, ...techArray])];

        // Update state
        setTechUsed(newTechUsed);
        setWork({ ...work, errorWork: '', errorMessageWork: '', techValue: '' });
    };

    const handleDeleteTech = (ele) => {
        setTechUsed(techUsed.filter(e => e !== ele))
    }

    const onAppendWorkArray = (newInput) => {
        setWorkExprience(workExprience => [...workExprience, newInput])
        setWorkDisplay(true)
        setWork({
            ...work,
            title: '',
            description: '',
            workLink: '',
            repoName: '',
            repoLink: '',
            currentlyPursuing: 'false',
            techValue: '',
            errorMessageWork: '',
            errorWork: ''
        })
    }

    const onAppendLinkArray = (newInput) => {
        setOtherLink(otherLinks => [...otherLinks, newInput])
        setDisplayOtherLink(true)
    }

    const deleteWork = (i) => {
        setWorkExprience(workExprience => {
            return workExprience.filter((_, index) => index !== i)
        })
    }
    const deleteEdu = (i) => {
        setEduExprience(eduExprience => {
            return eduExprience.filter((_, index) => index !== i)
        })
    }
    const deleteLink = (i) => {
        setOtherLink(otherLinks => {
            return otherLinks.filter((_, index) => index !== i)
        })
    }
    const deleteTech = (i) => {
        setWork({ ...work, techUsed: techUsed.filter(e => e !== i) })
    }

    const updateWork = (w) => {
        setWork(w)
        setSelectedRepository({ name: w.repoName, link: w.repoLink })
        setTechUsed(w.techUsed)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateEdu = (w) => {
        setEducation(w)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateLink = (w) => {
        setLinkData(w)
    }

    const onUpdateWorkArray = (index, newInput) => {
        let dummyArray = [...workExprience]
        dummyArray[index] = newInput
        setWorkExprience(dummyArray)
        setTechUsed([])
        setIsUpdate(false)
    }

    const onUpdateEduArray = (index, newInput) => {
        let dummyArray = [...eduExprience]
        dummyArray[index] = newInput
        setEduExprience(dummyArray)
        setIsUpdate(false)
    }


    const onUpdateLinkArray = (index, newInput) => {
        let dummyArray = [...otherLinks]
        dummyArray[index] = newInput
        setOtherLink(dummyArray)
        setIsUpdate(false)
    }

    const conformationCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any work exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setEduDisplay(true)
                setWorkDisplay(false)
            } else {
                setWorkFormDisplay(true)
                setOtherLinkForm(false)
                setEduFormDisplay(false)
            }
        } else {
            setWorkFormDisplay(false)
            setEduFormDisplay(true)
            setEduDisplay(true)
            setWorkDisplay(false)
        }
    }
    const conformationEduCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any education exprience`) === true) {
                setWorkFormDisplay(false)
                setEduFormDisplay(false)
                setOtherLinkForm(true)
                setDisplayOtherLink(true)
                setEduDisplay(false)
            } else {
                setWorkFormDisplay(false)
                setEduFormDisplay(true)
                setOtherLinkForm(false)
                setEduDisplay(false)
            }
        } else {
            setDisplayOtherLink(true)
            setWorkFormDisplay(false)
            setEduFormDisplay(false)
            setOtherLinkForm(true)
            setEduDisplay(false)
        }
    }
    const conformationLinkCheck = (arr) => {
        if (arr.length === 0) {
            if (window.confirm(`Are you sure you don't want to add any other links`) === true) {
                setDisplayViewData(true)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setDisplayOtherLink(false)
                setOtherLinkForm(false)
            } else {
                setDisplayViewData(false)
                setEduFormDisplay(false)
                setWorkFormDisplay(false)
                setOtherLinkForm(true)
            }
        } else {
            setDisplayViewData(true)
            setEduFormDisplay(false)
            setDisplayOtherLink(false)
            setWorkFormDisplay(false)
            setOtherLinkForm(false)
        }
    }
    const handleSubmitProfile = (profileData) => {
        submitProfile(profileData).then(res => {
            if (res.success) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const profile = res.profile;
                const updatedUser = {
                    ...currentUser,
                    message: "Your Profile is ready",
                    profileStatus: true,
                    profile: profile
                };

                localStorage.setItem('user', JSON.stringify(updatedUser));
                setStateUser(updatedUser);
                setResponseLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleLogin = () => {
        window.location.href = gitUrl;
    };

    const handleLoginGit = () => {
        window.location.href = gitUrl;
    };

    const allTips = [
        "Commit regularly to improve contribution activity.",
        "Collaborate with others on open-source projects.",
        "Write clear and actionable issues in repositories.",
        "Submit detailed pull requests to repositories.",
        "Enhance README files with project descriptions.",
        "Document code to make it easier for others to understand.",
        "Engage with repositories by starring and forking.",
        "Resolve issues in your repositories or those of others.",
        "Tag releases to showcase project milestones.",
        "Keep repositories active with frequent updates."
    ];

    const [currentTips, setCurrentTips] = useState([]);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const shuffleTips = () => {
            const shuffled = allTips.sort(() => 0.5 - Math.random()).slice(0, 3);
            setCurrentTips(shuffled);
        };

        shuffleTips(); // Initial tips
        const interval = setInterval(() => {
            setFade(true); // Start fade-out
            setTimeout(() => {
                shuffleTips(); // Update tips
                setFade(false); // Start fade-in
            }, 500); // Duration of fade-out
        }, 5000); // Change tips every 5 seconds

        return () => clearInterval(interval); // Cleanup
    }, []);

    return (
        <AuthNavbar>
            {responseLoading && (
                <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        zIndex: 1050, // Ensure it's above all other elements
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="text-center text-white">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Uploading your profile, please wait...</p>
                    </div>
                </div>
            )}
            <div className=''>
                {data?.profileStatus === false ? <div style={{ backgroundColor: "wihtesmoke" }} className="card border-0 my-md-5 my-3 mx-md-5 mx-3">
                    <h3 align="center" className='text-danger pb-md-3'>{data.message}!!</h3>
                    {displayViewData === false ? <div className='row'>
                        {workFormDisplay === true ?
                            <div className='col-11 col-md-4 p-2 mx-auto'>
                                <h3 className="text-dark">Showcase Your Projects and Experiences</h3>
                                <small className="fw-bold text-danger">*You can also add your personal projects with git repos. This will help you rank better</small>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={title}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeWork('title')}
                                        variant="outlined" />
                                </div>
                                <div className='py-1'>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={description}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeWork('description')}
                                        variant="outlined" />
                                </div>

                                {loading ? <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <div>
                                        <CircularProgress size={30} />
                                    </div>
                                    <div>
                                        <span className="px-2">Loading Git Tokens</span>
                                    </div>
                                </div> : <div>
                                    {!gitTokenAvailable ? <div align="center" className="pt-2">
                                        <button
                                            className="btn btn-danger btn-sm fw-bold"
                                            onClick={handleLogin}>Integrate Your Github
                                        </button>
                                        <button
                                            className="btn btn-sm btn-outline-primary fw-bold mx-2"
                                            onClick={() => setOpenModelGit(true)}>
                                            ?
                                        </button>
                                        <Modal
                                            open={openModelGit}
                                            onClose={() => setOpenModelGit(false)}
                                        >
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="p-md-3 p-2 card col-md-6 col-11">
                                                    <div className="modal-content border-0">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="githubModalLabel">Integrate Your GitHub</h5>
                                                            <button type="button" className="btn-close" onClick={() => setOpenModelGit(false)} aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                Integrating your GitHub repository allows us to analyze your contributions and highlight your skills effectively.
                                                                While linking your entire GitHub account is optional, we strongly recommend adding repositories individually for a better experience.
                                                            </p>
                                                            <p>
                                                                <strong>Note:</strong> Only the repositories you connect will be considered for our ranking algorithm—not your entire account.
                                                            </p>
                                                            <p>
                                                                <strong>Confused?</strong> Take a <Link to="/user-tour">tour</Link> or feel free to <Link to="/ask-devsemble">Ask devSemble</Link>
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">

                                                            <button
                                                                className="btn btn-danger btn-sm fw-bold"
                                                                onClick={handleLogin}>Integrate Your Github
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div> : <div><div className="d-flex align-items-center">
                                        <div className="my-2 w-100">
                                            <FormControl fullWidth>
                                                <div className="">
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={uniqueGitRepos.map(repo => ({ name: repo.name, link: repo.url }))}
                                                        getOptionLabel={(option) => {
                                                            const urlParts = option.link.split('/');
                                                            const owner = urlParts[urlParts.length - 2]; // Extract repo owner
                                                            return `${option.name} (${owner})`;
                                                        }}
                                                        value={selectedRepository}
                                                        onChange={(event, newValue) => {
                                                            setSelectedRepository(newValue === '' ? null : newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Select Repository" />}
                                                    />
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>
                                        <small className="fw-bold text-success">*You have successfully integrated GitHub</small>
                                    </div>}
                                </div>
                                }
                                <div className='py-2'>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={workLink}
                                        label={errorMessage === '' ? "Mention other link available" : errorMessage}
                                        onChange={handleChangeWork('workLink')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <div className="d-flex justify-content-center align-item-center">
                                        <div className="d-flex justify-content-between align-items-center col-12 px-0" style={{ gap: "10px" }}>
                                            <div style={{ flex: 1 }}>
                                                <TextField
                                                    className="my-2 w-100"
                                                    error={errorMessage === "" ? false : true}
                                                    id="custom-css-outlined-input"
                                                    color={error ? "error" : ""}
                                                    value={techValue}
                                                    label={errorMessage === '' ? "Tech Used" : errorMessage}
                                                    onChange={handleChangeWork('techValue')}
                                                    variant="outlined"
                                                />
                                            </div>
                                            <button
                                                onClick={() => updateWorkTech(techValue)}
                                                className="btn fw-bold btn-primary rounded-pill"
                                            >
                                                {isMdScreen ? "Add tech" : <FontAwesomeIcon size="1x" icon={faPlus} />}
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <small className="text-muted small">You can use commas to separate multiple techs (e.g., React, Node.js, MongoDB)</small>
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <div className="col-12 card bg-transparent border-primary">
                                            <div className="col-12 d-flex flex-wrap ">
                                                {techUsed.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill p-1 px-2 m-2">
                                                        <span className="px-2 fw-bold text-white m-0">{p}</span>
                                                        <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                            <FontAwesomeIcon size="1x" icon={faTrash} />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {isUpdate ?
                                        <button className='col-12 rounded-pill my-3 fw-bold btn btn-warning'
                                            onClick={() => onUpdateWorkArray(index, {
                                                title,
                                                description,
                                                workLink,
                                                repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuing,
                                                techUsed
                                            })}>
                                            Update
                                        </button> :
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <button style={{ flex: 2 }} className='m-1 fw-bold rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendWorkArray({
                                                    title,
                                                    description,
                                                    workLink,
                                                    repoName: selectedRepository === null ? "" : selectedRepository.name,
                                                    repoLink: selectedRepository === null ? "" : selectedRepository.link,
                                                    startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuing,
                                                    techUsed
                                                })}>
                                                Add Work Exprience
                                            </button>
                                            <button style={{ flex: 1 }} className='m-1 fw-bold rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationCheck(workExprience)
                                                }}>{workExprience.length === 0 ? <span className="fw-bold">{isMdScreen ? <>Skip <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}</span> : <span className="fw-bold">{isMdScreen ? <>NEXT <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}</span>}
                                            </button>
                                        </div>}
                                </div>
                            </div> : null}

                        {/* Education form */}

                        {eduFormDisplay === true ?
                            <div className='col-11 col-lg-4 p-3 mx-auto'>
                                <h3 className="text-dark">Education Form</h3>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={courseName}
                                        label={errorMessage === '' ? "Course Name" : errorMessage}
                                        onChange={handleChangeEdu('courseName')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={institutionName}
                                        label={errorMessage === '' ? "Institution Name" : errorMessage}
                                        onChange={handleChangeEdu('institutionName')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <RadioGroup
                                        value={currentlyPursuingEdu}
                                        onChange={handleCurrentlyPursuingEdu}
                                    >
                                        <div className={`d-flex flex-wrap justify-content-center ${isMdScreen ? "align-items-center" : "align-items-start"} flex-column flex-md-row`}>
                                            <div className="mb-2 mb-md-0" style={{ flex: 1 }}>
                                                <FormControlLabel
                                                    className="text-dark"
                                                    value={true}
                                                    control={<Radio />}
                                                    label="Currently Pursuing"
                                                />
                                            </div>
                                            <div className="" style={{ flex: 1 }}>
                                                <FormControlLabel
                                                    className="text-dark"
                                                    value={false}
                                                    control={<Radio />}
                                                    label="Completed"
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>

                                <div align="center" className='py-3'>
                                    {isUpdate ?
                                        <button className='col-12 rounded-pill fw-bold my-3 btn btn-warning'
                                            onClick={() => onUpdateEduArray(index, {
                                                courseName,
                                                institutionName,
                                                eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                currentlyPursuingEdu
                                            })}>
                                            Update
                                        </button> :
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    setWorkFormDisplay(true)
                                                    setOtherLinkForm(false)
                                                    setWorkDisplay(true)
                                                    setEduDisplay(false)
                                                    setEduFormDisplay(false)
                                                }}>{isMdScreen ? <><FontAwesomeIcon size="1x" icon={faArrowLeft} /> BACK</> : <FontAwesomeIcon size="1x" icon={faArrowLeft} />}
                                            </button>
                                            <button style={{ flex: 3 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                onClick={() => onAppendEduArray({
                                                    courseName,
                                                    institutionName,
                                                    eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`,
                                                    currentlyPursuingEdu
                                                })}>
                                                Add Education
                                            </button>
                                            <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                onClick={() => {
                                                    conformationEduCheck(eduExprience)
                                                }}>{isMdScreen ? <>NEXT <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <FontAwesomeIcon size="1x" icon={faArrowRight} />}
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div> : null}
                        {otherLinksForm === true ?
                            <div className='col-11 col-lg-4 p-3 mx-auto'>
                                <h3 className="text-dark">Other Links</h3>
                                <Collapse in={true}>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%', textTransform: 'capitalize !important' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkTitle}
                                            label={errorMessage === '' ? "Title" : errorMessage}
                                            onChange={handleChangeLink('linkTitle')}
                                            variant="outlined" />
                                    </div>
                                    <div className=''>
                                        <TextField
                                            style={{ width: '100%' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={linkHref}
                                            label={errorMessage === '' ? "Link" : errorMessage}
                                            onChange={handleChangeLink('linkHref')}
                                            variant="outlined" />
                                    </div>
                                    <div align="center" className='py-3'>
                                        {isUpdate ?
                                            <button className='col-12 my-3 btn btn-warning'
                                                onClick={() => onUpdateLinkArray(index, {
                                                    linkTitle,
                                                    linkHref
                                                })}>
                                                Update
                                            </button> :
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                <button style={{ flex: 1 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setOtherLinkForm(false)
                                                        setEduFormDisplay(true)
                                                    }}>{isMdScreen ? <><FontAwesomeIcon size="1x" icon={faArrowLeft} /> BACK</> : <FontAwesomeIcon size="1x" icon={faArrowLeft} />}
                                                </button>
                                                <button style={{ flex: 3 }} className='fw-bold m-1 rounded-pill fs-6 btn btn-primary btn-sm'
                                                    onClick={() => onAppendLinkArray({
                                                        linkTitle,
                                                        linkHref
                                                    })}>
                                                    Add Link
                                                </button>
                                                <button
                                                    style={{ flex: 2 }}
                                                    className="fw-bold m-1 rounded-pill fs-6 btn btn-outline-secondary btn-sm"
                                                    onClick={() => {
                                                        conformationLinkCheck(eduExprience);
                                                    }}
                                                >
                                                    {isMdScreen ? <>Profile <FontAwesomeIcon size="1x" icon={faArrowRight} /></> : <><FontAwesomeIcon size="1x" icon={faSave} /> <FontAwesomeIcon size="1x" icon={faArrowRight} /></>}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Collapse>
                            </div> : null}
                        <div className='text-dark col-11 col-lg-6 px-0 mx-auto'>
                            <Collapse className='' in={workDisplay}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Work exprience you added</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark fw-bold btn-sm rounded-pill'
                                            onClick={() => setWorkDisplay(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                        <div className='container-fluid my-0 p-2 my-md-3 shadow' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateWork(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-danger'
                                                        onClick={() => deleteWork(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.description}</div><br />
                                            <div>Repo: {w.repoName}</div>
                                            <div>Tech Used: {w.techUsed.map((t, i) => <small key={i} className='bg-primary text-white fw-bold rounded-pill mx-2 px-2 p-1'>{t}</small>)}</div>
                                        </div>)}</div> :
                                        <div className='container-fluid my-0 p-2 my-md-3 shadow'>
                                            <small className="small fw-bold text-danger">*this is dummy representation of how projects will be added</small>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>Backend developer</h4></span>
                                                </div>
                                            </div>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> Mention detailed description for the same</div><br />
                                            <div>Mention you github repo for better scoring</div>
                                            <div>Mention tech, languages, etc.</div>
                                        </div>}
                                </div>
                            </Collapse>
                            <Collapse className='' in={eduDisplay}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Education you added</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark fw-bold btn-sm rounded-pill'
                                            onClick={() => setEduDisplay(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                        <div className='container-fluid shadow my-md-3 p-2' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>{w.courseName}</h4>
                                                    </span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateEdu(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteEdu(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Institution Name:</b> {w.institutionName}</div><br />
                                        </div>)}</div> :
                                        <div className='container-fluid my-0 p-2 my-md-3 shadow'>
                                            <small className="small fw-bold text-danger">*this is dummy representation of how your education will be added</small>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>Course Name</h4></span>
                                                </div>
                                            </div>
                                            <div style={{ height: '5vh', overflow: 'auto' }}>Institution Name</div><br />
                                        </div>}
                                </div>
                            </Collapse>
                            <Collapse className='' in={displayOtherLink}>
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                        <h3>Other Links</h3>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-dark rounded-pill'
                                            onClick={() => setDisplayOtherLink(false)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                                <div className=''>
                                    {otherLinks.length !== 0 ? <div className='py-0'>{otherLinks.map((w, i) =>
                                        <div className='container-fluid shadow my-md-3 p-2' key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'>
                                                        <h4>{w.linkTitle}</h4>
                                                    </span>
                                                </div>
                                                <div className='col-6 d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateLink(w)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteLink(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                </div>
                                            </div>
                                            <div><b className="">Link:</b> {w.linkHref}</div><br />
                                        </div>)}</div> :
                                        <div className='container-fluid my-0 p-2 my-md-3 shadow'>
                                            <small className="small fw-bold text-danger">*this is dummy representation of how your links will be added</small>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="col-6">
                                                    <span className='d-flex align-items-center'><h4>My Portfolio</h4></span>
                                                </div>
                                            </div>
                                            <div><b className="">Link:</b> Here goes link</div><br />
                                        </div>}
                                </div>
                            </Collapse>
                            <Collapse className='d-lg-block d-none' in={!workDisplay && !eduDisplay && !displayOtherLink}>
                                <div align='center' className='py-2'>
                                    <h3>View Your</h3>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setWorkDisplay(true)
                                                setWorkFormDisplay(true)
                                                setOtherLinkForm(false)
                                                setEduFormDisplay(false)
                                            }}>
                                            {`Work - ${workExprience.length}`}
                                        </span>
                                    </div>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setEduDisplay(true)
                                                setWorkFormDisplay(false)
                                                setOtherLinkForm(false)
                                                setEduFormDisplay(true)
                                            }}>
                                            {`Education - ${eduExprience.length}`}
                                        </span>
                                    </div>
                                    <div className='px-2'>
                                        <span className='m-auto text-primary fw-bold' style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setDisplayOtherLink(true)
                                                setWorkFormDisplay(false)
                                                setOtherLinkForm(true)
                                                setEduFormDisplay(false)
                                            }}>
                                            {`Links - ${otherLinks.length}`}
                                        </span>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div> : <div className=''>
                        <div className=" d-flex flex-wrap justify-content-center py-3">
                            <div className='col-6 d-flex flex-wrap justify-content-between'>
                                <button className='btn btn-warning btn-sm rounded-pill'
                                    onClick={() => {
                                        setWorkFormDisplay(true)
                                        setWorkDisplay(true)
                                        setDisplayViewData(false)
                                    }}>
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                                    <span className="px-2 fw-bold">Back</span>
                                </button>
                                <button className='btn btn-dark rounded-pill'
                                    onClick={() => {
                                        setResponseLoading(true)
                                        handleSubmitProfile({
                                            userId: data.user._id,
                                            workExprience: workExprience,
                                            educationExprience: eduExprience,
                                            otherLinks: otherLinks,
                                            githubProfile: githubProfile,
                                            stackOverFlowProfile: stackOverFlowProfile
                                        })
                                    }}>
                                    Upload Data
                                </button>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div className="py-3">
                                    <div className='shadow py-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                            <h3 className="fw-normal">Work exprience you added</h3>
                                        </div>
                                        <hr className="mx-3" />
                                        {workExprience?.length !== 0 ? <div className='py-2'>{workExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div style={{ flex: 2 }}>
                                                        <h4>{w.title}</h4>
                                                    </div>
                                                    <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>                                                        <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setWorkFormDisplay(true)
                                                            setWorkDisplay(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateWork(w)
                                                            setDisplayViewData(false)
                                                        }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                        <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                            onClick={() => deleteWork(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                    </div>
                                                </div>
                                                <div><b className=""></b>{w.description}</div><br />
                                                <div>REPO: {w.repoName} {w.repoLink}</div>
                                                <div className="fw-bold" style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <small key={i} className='bg-primary rounded-pill mx-2 px-2 p-1 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</small>)}</div>
                                                {workExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : <div className="py-3 text-center">
                                            <h4>No work exprience Added</h4>
                                        </div>}
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className='shadow py-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                            <h3 className="fw-normal">Education exprience you added</h3>
                                        </div>
                                        <hr className="mx-3" />
                                        {eduExprience?.length !== 0 ? <div className='py-0'>{eduExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div style={{ flex: 2 }} className="">
                                                        <h4 className="mb-0">{w.courseName}</h4>
                                                        <small>({w.eduStartDate} - {w.eduEndDate})</small>
                                                    </div>
                                                    <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>
                                                        <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                            onClick={() => {
                                                                setEduFormDisplay(true)
                                                                setEduDisplay(true)
                                                                setIsUpdate(true)
                                                                setIndex(i)
                                                                updateEdu(w)
                                                                setDisplayViewData(false)
                                                            }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                        <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                            onClick={() => deleteEdu(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                    </div>
                                                </div>
                                                <div><b className=""></b>{w.institutionName}</div><br />
                                                {eduExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : <div className="py-3 text-center">
                                            <h4>No education added</h4>
                                        </div>}
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className='shadow py-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center px-3'>
                                            <h3 className="fw-normal">Other links you added</h3>
                                        </div>
                                        <hr className="mx-3" />
                                        {otherLinks?.length !== 0 ? <div className='py-0'>{otherLinks?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div style={{ flex: 2 }}>
                                                        <h4>{w.linkTitle}</h4>
                                                    </div>
                                                    <div style={{ flex: 1 }} className='d-flex justify-content-around py-2'>
                                                        <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                            onClick={() => {
                                                                setOtherLinkForm(true)
                                                                setDisplayOtherLink(true)
                                                                setIsUpdate(true)
                                                                setIndex(i)
                                                                updateLink(w)
                                                                setDisplayViewData(false)
                                                            }}><FontAwesomeIcon size="1x" icon={faEdit} />{isMdScreen ? ' Update' : ''}</button>
                                                        <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                            onClick={() => deleteLink(i)}><FontAwesomeIcon size="1x" icon={faTrash} />{isMdScreen ? ' Delete' : ''}</button>
                                                    </div>
                                                </div>
                                                <span className='d-flex align-items-center'></span>
                                                <div><b className="">Link:</b> {w.linkHref}</div>
                                                {otherLinks?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : <div className="py-3 text-center">
                                            <h4>No Links Added</h4>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}</div> :
                    // this is the logged in section
                    <div className="container-fluid d-flex justify-content-center">
                        <div className={`${isMdScreen ? 'col-10' : 'col-12'} d-flex flex-wrap justify-content-start`}>
                            <div className="d-flex flex-wrap justify-content-end col-12 col-md-3 py-md-5" style={{
                                position: "sticky", top: 10, zIndex: 10
                            }}>
                                <div className={`d-lg-none d-flex justify-content-end dash-div ${showMenu ? 'bg-white rounded shadow' : ''}`} style={{
                                    position: "sticky", zIndex: 10, top: 10
                                }}>
                                    <Collapse in={showMenu}>
                                        <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to="" state={data}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(true)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        My Profile
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='teams-joined' state={data.profile.joined}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold d-flex align-items-center"
                                                    >
                                                        Team Joined
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='team-applied' state={data.profile.teamApplied}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        Team Applied
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='team-requested' state={data.profile.pendingRequests}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        Team Requested
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='teams-removed' state={data.profile.teamRemoved}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        Team Removed
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='display-public-projects'>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        Display Projects
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link" to='wallet-and-transactions' state={data.profile.teamRemoved}>
                                                    <span
                                                        onClick={() => {
                                                            setDisplayProfile(false)
                                                            setShowMenu(false)
                                                        }}
                                                        className="fw-bold">
                                                        My wallet
                                                        <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <div className={`${showMenu ? "" : 'rounded-pill bg-white shadow'}`}>
                                        <IconButton onClick={() => setShowMenu(!showMenu)} >
                                            <MenuIcon sx={{ strokeWidth: 3 }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="rounded-3 col-10">
                                    <div className="rounded-3 p-3 shadow d-lg-block d-none " style={{
                                        backgroundColor: '#daebf9'
                                    }}>
                                        <div className="d-lg-block d-none">
                                            <div className="col-12 d-flex flex-wrap justify-content-start align-items-center">
                                                <span className="fs-2 m-0"><b className="m-0">{data?.user?.firstName} {data?.user?.lastName}</b></span>
                                            </div>
                                            <div className="text-start">
                                                <span className="fs-4"><span className="text-primary fw-bold">{data?.profile?.rankId?.rankRange}</span></span>
                                            </div>
                                        </div>
                                        <div className="d-lg-block d-none card bg-transparent py-2 rounded-lg border-0">
                                            <div className="d-flex flex-column rounded-3 align-items-start flex-wrap justify-content-end">
                                                <div className="py-md-4">
                                                    <div className="py-1">
                                                        <Link
                                                            className="text-decoration-none fw-bold dash-link"
                                                            to=""
                                                            state={data}
                                                        >
                                                            <span
                                                                onClick={() => setDisplayProfile(true)}
                                                                className="fw-bold text-dark"
                                                            >
                                                                My Profile
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard' ? 'active-arrow' : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link
                                                            className="text-decoration-none fw-bold dash-link"
                                                            to="teams-joined"
                                                            state={data.profile.joined}
                                                        >
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark"
                                                            >
                                                                Team Joined
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/teams-joined'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link className="text-decoration-none fw-bold dash-link" to='team-applied' state={data.profile.teamApplied}>
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark">
                                                                Team Applied
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/team-applied'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link className="text-decoration-none fw-bold dash-link" to='team-requested' state={data.profile.pendingRequests}>
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark">
                                                                Team Requested
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/team-requested'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link className="text-decoration-none fw-bold dash-link" to='teams-removed' state={data.profile.teamRemoved}>
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark">
                                                                Team Removed
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/teams-removed'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link className="text-decoration-none fw-bold dash-link" to='display-public-projects'>
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark">
                                                                Display Projects
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/display-public-projects'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="py-1">
                                                        <Link className="text-decoration-none fw-bold dash-link" to='wallet-and-transactions' state={data.profile.teamRemoved}>
                                                            <span
                                                                onClick={() => setDisplayProfile(false)}
                                                                className="fw-bold text-dark">
                                                                My wallet
                                                                <i
                                                                    className={`fa fa-arrow-right ms-2 arrow-icon ${location.pathname === '/dashboard/wallet-and-transactions'
                                                                        ? 'active-arrow'
                                                                        : ''
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`rounded-3 my-4 p-3 d-lg-block d-none shadow`}
                                        style={{ backgroundColor: "#daebf9" }}
                                    >
                                        <div>
                                            <span className="fs-3 fw-bold">
                                                Tips
                                            </span>
                                        </div>
                                        <div className={` ${fade ? "tips-fade-out" : "tips-fade-in"}`}>
                                            <ul className="mt-3">
                                                {currentTips.map((tip, index) => (
                                                    <li key={index} className="fs-6 pb-2">
                                                        {tip}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-9 py-md-5">
                                <div className="card mx-md-5 rounded-3 d-flex justify-content-start align-items-center border-0 p-2" style={{
                                    backgroundColor: '#daebf9'
                                }}>
                                    <div className={`col-md-9 col-12 ${displayProfile ? 'd-block' : 'd-none'}`}>
                                        <div className="d-lg-none">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <span className="fs-2 m-0"><b className="m-0">{data?.user?.firstName} {data?.user?.lastName}</b></span>
                                            </div>
                                            <div>
                                                <span className="fs-4"><span className="text-primary fw-bold">{data?.profile?.rankId?.rankRange}</span></span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="py-3">
                                                <div className="d-flex flex-wrap justify-content-start align-item-start">
                                                    <h4 className="m-0">
                                                        About Me
                                                    </h4>
                                                    {aboutYouUpdate ? null : <FontAwesomeIcon onClick={() => setAboutYouUpdate(true)}
                                                        style={{ cursor: 'pointer' }}
                                                        className="px-2 text-warning" size="1x" icon={faEdit} />
                                                    }
                                                </div>
                                                <Collapse in={aboutYouUpdate}>
                                                    <div className='py-1'>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2 bg-white'
                                                            error={errorMessage === "" ? false : true}
                                                            id="outlined-basic"
                                                            multiline
                                                            maxRows={4}
                                                            value={aboutYou}
                                                            label={errorMessage === '' ? "Description" : errorMessage}
                                                            onChange={(e) => setAboutYou(e.target.value)}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className="d-flex flex-wrap justify-content-end gap-3">
                                                        <button onClick={() => handleSubmitAboutYou()} className="btn btn-sm btn-primary fw-bold">
                                                            Update
                                                        </button>
                                                        <button onClick={() => setAboutYouUpdate(false)} className="btn btn-sm btn-danger fw-bold">
                                                            cancel
                                                        </button>
                                                    </div>
                                                </Collapse>
                                                <Collapse in={!aboutYouUpdate}>
                                                    <div className="py-2">
                                                        {!data?.profile?.aboutYou ? <>
                                                            <button onClick={() => setAboutYouUpdate(true)} className="btn btn-sm btn-warning fw-bold">
                                                                Add about you
                                                            </button>
                                                        </> : data?.profile?.aboutYou}
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <div className="">
                                                <div className="py-3">
                                                    <div className="d-flex flex-wrap justify-content-end gap-3 py-3">
                                                        <div className="">
                                                            <button onClick={() => handleLoginGit()} className="btn btn-sm btn-danger fw-bold">
                                                                Refresh GitHub Tokens
                                                            </button>
                                                        </div>
                                                        <Link to={'/dashboard/update-profile'} state={{
                                                            workExprience: data?.profile?.workExprience, eduExprience: data?.profile?.educationExprience, otherLinks: data?.profile?.otherLinks
                                                        }}>
                                                            <button className="btn btn-sm btn-warning fw-bold">
                                                                Profile <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className='shadow py-3 bg-white rounded-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Work exprience you added</h3></div>
                                                        {data?.profile?.workExprience?.length !== 0 ? <div className='py-2'>{data?.profile?.workExprience?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                                                <div><b className=""></b>{w.description}</div><br />
                                                                <div className="fw-bold" style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <div key={i} className='badge bg-primary rounded-pill mx-1 px-2 p-1 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</div>)}</div>
                                                                {data?.profile?.workExprience?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className='shadow py-3 bg-white rounded-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Education exprience you added</h3></div>
                                                        {data?.profile?.educationExprience?.length !== 0 ? <div className='py-0'>{data?.profile?.educationExprience?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.courseName}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                                <div><b className=""></b>{w.institutionName}</div><br />
                                                                {data?.profile?.educationExprience?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : null}
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className='shadow py-3 bg-white rounded-3'>
                                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Other links you added</h3></div>
                                                        {data?.profile?.otherLinks?.length !== 0 ? <div className='py-0'>{data?.profile?.otherLinks?.map((w, i) =>
                                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                                                <div><b className="">Link:</b> {w.linkHref}</div>
                                                                {data?.profile?.otherLinks?.length - 1 === i ? null : <hr />}
                                                            </div>)}</div> : <div className="py-3 text-center">
                                                            <h4>No Links Added</h4>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </AuthNavbar>
    )
}

export default DashBoard

