import { useNavigate, Link, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faClose, faComment, faFileEdit, faNoteSticky, faPaperPlane, faReceipt, faTasks } from '@fortawesome/free-solid-svg-icons'
import { useAuthStore } from '../../store/store';
import { useEffect, useRef, useState } from "react"
import { CircularProgress, Collapse, IconButton } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled, width } from '@mui/system';
import { fetchRecentChats, getSingleProject, updateGitAccess } from "../../actions/projectActions"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { createTask, getProjectTasks, updateTask, pushUpgrade, deleteTask } from "../../actions/taskActions";
import { faArrowLeft, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@mui/material/useMediaQuery';
import socket from "../../freeAccess/socket";
import CollaborativeEditor from "../editors/CollaborativeEditor";
import Notes from "../noteUtils/Note";
import VoiceCall from "../voiceChat/VoiceCall";
import Requirements from "./requirements/Requirements";
import ProjectTransactions from "../projectTransactions/ProjectTransaction";
import MenuIcon from '@mui/icons-material/Menu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill theme CSS
import Discussions from "../discussions/Discussions";

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'black'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'black',
    label: 'black',
    icon: 'black',
    '& label': {
        color: '#0275d8',
    },
    '& .MuiSvgIcon-root': {
        color: 'black',
    },
}));

const SingleProject = () => {

    const navigate = useNavigate()

    const project_id = useParams().pId

    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };

    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const data = useAuthStore(state => state.user)
    const [refresh, setRefresh] = useState(false)

    const [showTaskForm, setShowTaskForm] = useState(false)

    const [showMobileTask, setShowMobileTasks] = useState(false)
    const [showMobileTeam, setShowMobileTeams] = useState(false)
    const [showMobileDiscussions, setShowMobileDiscussions] = useState(false)

    const [isUpdate, setIsUpdate] = useState(false)

    const [showMenu, setShowMenu] = useState(false)
    const [showChat, setShowChat] = useState(false)

    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [chatLoading, setChatLoading] = useState(true)
    const [tasksLoading, setTasksLoading] = useState(true)
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    const [formData, setFormData] = useState({
        tId: '',
        taskTitle: "Some Task",
        taskDescription: "Desciption for the task",
        taskStartDate: '',
        taskEndDate: '',
        projectId: project_id,
        assignedBy: data.user._id,
        taskStatus: false,
        assignedTo: '',
        error: '',
        errorMessage: '',
    })

    const {
        tId,
        taskTitle,
        taskDescription,
        projectId,
        error,
        errorMessage,
        assignedBy,
        assignedTo
    } = formData

    const [modifiedData, setModifiedData] = useState({
        requirement: '',
        updatedBy: data.user._id,
        errorModified: '',
        errorMessageModified: ''
    })

    const { requirement, updatedBy, errorModified, errorMessageModified } = modifiedData

    const [openModifiedForm, setOpenModifiedForm] = useState(false)
    const [displayUpdatedData, setDisplayUpdatedData] = useState(-1)
    const [displayReqsAssigned, setDisplayReqsAssigned] = useState(-1)

    const [authorizedGitAccess, setAuthorizedGitAccess] = useState('')
    const [gitAccessCollapse, setGitAccessCollapse] = useState(false)

    const [isTeamsVisible, setIsTeamsVisible] = useState(true);

    const handleModifiedChange = name => event => {
        setModifiedData({ ...modifiedData, errorModified: '', errorMessageModified: '', [name]: event.target.value })
    }

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));

    const handleChangeStartDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', startDate: dayjs(newValue).format('MM/DD/YY') });
        setStartDateValue(dayjs(newValue).format('MM/DD/YY'))
    };

    const handleChangeEndDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', endDate: dayjs(newValue).format('MM/DD/YY') });
        setEndDateValue(dayjs(newValue).format('MM/DD/YY'))
    };

    const handleDeleteTask = (taskId) => {
        deleteTask(taskId).then(res => {
            setRefresh(true)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleChange = name => event => {
        const value = event.target.value;
        const isDuplicate = name === 'taskTitle' && projectTasks.some(task => task.taskTitle === value);

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            error: isDuplicate ? 'taskTitleError' : '',
            errorMessage: isDuplicate ? 'Task title already exists!' : ''
        }));
    };

    const handleQuillChange = value => {
        setFormData(prevState => ({
            ...prevState,
            taskDescription: value,
            error: '',
            errorMessage: '',
        }));
    };

    const [projectData, setProjectData] = useState({})
    const [projectTasks, setProjectTasks] = useState([])

    useEffect(() => {
        getSingleProject({ projectId: project_id }).then(res => {
            if (res.response.projectHost._id === data.user._id) {
                setProjectData(res.response)
            } else {
                navigate('/advanced-dashboard')
            }
        }).catch(err => {
            console.log(err)
        })
        getProjectTasks(project_id).then(res => {
            setProjectTasks(res?.tasks?.reverse())
            setTasksLoading(false)
        }).catch(err => {
            console.log(err)
        })

        if (!isMdScreen) {
            setShowChat(true)
        }

    }, [refresh])


    const handleSubmit = (taskObj) => {
        if (taskObj.taskEndDate === dayjs(new Date()).format("MM/DD/YY")) {
            return setFormData({ ...formData, error: "dateError", errorMessage: 'Todays date cannot be Used' })
        }
        if (taskObj.taskTitle === '' || taskObj.taskDescription === '') {
            return setFormData({ ...formData, error: "formError", errorMessage: 'Fields required' })
        }

        if (taskObj.assignedTo === "") {
            return setFormData({ ...formData, error: "memberError", errorMessage: "Select a member to assign task" })
        }
        else {
            createTask(taskObj).then(res => {
                if (res.success === true) {
                    setRefresh(true)
                    setShowTaskForm(false)
                }
            })
        }
        setRefresh(false)
    }

    const handleUpdate = (taskObj, tId) => {
        if (taskObj.taskEndDate === dayjs(new Date()).format("MM/DD/YY")) {
            return setFormData({ ...formData, error: "dateError", errorMessage: 'Todays date cannot be Uses' })
        }
        if (taskObj.taskTitle === '' || taskObj.taskDescription === '') {
            return setFormData({ ...formData, error: "formError", errorMessage: 'Fields required' })
        }

        if (taskObj.assignedTo === "") {
            return setFormData({ ...formData, error: "memberError", errorMessage: "Select a member to assign task" })
        }
        else {
            updateTask(taskObj, tId).then(res => {
                if (res.success === true) {
                    setRefresh(true)
                    setShowTaskForm(false)
                }
            })
        }
        setRefresh(false)
    }

    const handleUpgrade = (taskUpdates, tId) => {
        if (taskUpdates.requirement === '') {
            setModifiedData({ ...modifiedData, errorModified: 'formError', errorMessageModified: 'Field is required' })
        } else {
            pushUpgrade(taskUpdates, tId).then(res => {
                if (res.status === true) {
                    setRefresh(true)
                    setOpenModifiedForm(false)
                    setShowTaskForm(false)
                }
            })
        }
    }

    useEffect(() => {
        // Ensure socket is connected
        if (!socket || !socket.connected) {
            console.error("Socket not connected");
            return;
        }
    
        fetchRecentChats(projectId).then((response) => {
            setMessages(response.reverse());
            setChatLoading(false);
        });
    
        // Emit handshake and project join events
        socket.emit("handshake", { data: "Hello from client" });
    
        socket.on("handshakeSuccess", () => {});
    
        socket.emit("joinProject", projectId);
    
        socket.on("gitProcessUpdate", (gitData) => {
            if (gitData.status) {
                setRefresh(true)
            }
        });
    
        // Cleanup listeners
        return () => {
            socket.off("handshakeSuccess");
            socket.off("gitProcessUpdate");
        };
    }, [projectId]);
    

    useEffect(() => {
        socket.on('privateMessage', (newMessage) => {
            setMessages(messages => [...messages, newMessage]);
        });


        return () => {
            socket.off('privateMessage');
        };
    }, [messages]);

    const sendMessage = (event) => {
        event.preventDefault()
        if (message.trim()) {
            socket.emit('privateMessage', {
                sender: data.user.firstName,
                projectId: project_id,
                projectName: projectData.projectTitle,
                content: message
            });
            setMessage('');
        }
    };

    const handleAuthorizedGitAccess = (event) => {
        setAuthorizedGitAccess(event.target.value)
    }

    const handleSubmitGitAccess = () => {
        updateGitAccess({ gitAccess: authorizedGitAccess._id, projectId: projectId }).then(res => {
            if (res.success) {
                setRefresh(true)
                setGitAccessCollapse(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = { weekday: 'short', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const [activeCollapse, setActiveCollapse] = useState('tasks');

    const toggleCollapse = (section) => {
        if (activeCollapse === section) {
            setActiveCollapse('tasks');
        } else {
            setActiveCollapse(section);
        }
    };

    return (
        <div className="py-md-3">
            <AllowAdvancedAccount>
                <div className="container-fluid" style={{
                    // overflow: 'scroll'
                }}>
                    <div className={`d-lg-none d-flex flex-wrap justify-content-start align-items-start shadow rounded-3 ${showMenu ? 'bg-white shadow py-1' : ''}`} style={{
                        position: "sticky", zIndex: 10, top: 10
                    }}>
                        <div className="col-12 d-flex flex-wrap justify-content-between align-items-center bg-white">
                            <div>
                                <Link to={`/advanced-dashboard/`}>
                                    <button className="rounded-pill btn btn-sm btn-warning">
                                        <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                                    </button>
                                </Link>
                            </div>
                            <div className="fs-5 text-center col-9 px-3 py-1">
                                {projectData.projectTitle}
                            </div>
                            <div className={`${showMenu ? "" : 'bg-white shadow'}`} style={{
                                borderRadius: '50vh'
                            }}>
                                <IconButton onClick={() => setShowMenu(!showMenu)} >
                                    <MenuIcon sx={{ strokeWidth: 3 }} />
                                </IconButton>
                            </div>
                        </div>
                        <Collapse in={showMenu} className="bg-light col-12">
                            <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('notes')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Notes
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('editor')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Editor
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('requirements')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Requirements
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('discussions')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(true)
                                    setShowChat(false)
                                }}>
                                    Discussions
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('transactions')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Transactions
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('tasks')
                                    setShowMenu(false)
                                    setShowMobileTasks(true)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Tasks
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('tasks')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(true)
                                    setShowMobileDiscussions(false)
                                    setShowChat(false)
                                }}>
                                    Team
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                                <span className="text-primary dash-link fw-bold" style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    toggleCollapse('tasks')
                                    setShowMenu(false)
                                    setShowMobileTasks(false)
                                    setShowMobileTeams(false)
                                    setShowMobileDiscussions(false)
                                    setShowChat(true)
                                }}>
                                    Chat
                                    <i className="fa fa-arrow-right ms-2 arrow-icon"></i>
                                </span>
                            </div>
                        </Collapse>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="d-lg-flex d-none col-11 row align-items-center py-2">
                            <div className="col-auto">
                                <Link to={`/advanced-dashboard/`}>
                                    <button onClick={() => handleBackClick()} className="btn btn-warning fw-bold rounded-pill">
                                        <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">Go Back</span>
                                    </button>
                                </Link>
                            </div>
                            <div className="col text-center">
                                <span className="fs-2 fw-bold" >{projectData.projectTitle}</span>
                            </div>
                            {activeCollapse === 'tasks' && (
                                <>
                                    <div className="col-auto">
                                        <button
                                            onClick={() => toggleCollapse('requirements')}
                                            className={`${activeCollapse === 'requirements' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                        >
                                            {activeCollapse === 'requirements' ?
                                                <>
                                                    <span className="pe-lg-3">Close Requirements</span>
                                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                                </> :
                                                <>
                                                    <span className="pe-lg-3">Requirements</span>
                                                    <FontAwesomeIcon size="1x" icon={faTasks} />
                                                </>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            onClick={() => toggleCollapse('discussions')}
                                            className={`${activeCollapse === 'discussions' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                        >
                                            {activeCollapse === 'discussions' ?
                                                <>
                                                    <span className="pe-lg-3">Close Discussions</span>
                                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                                </> :
                                                <>
                                                    <span className="pe-lg-3">Discussions</span>
                                                    <FontAwesomeIcon size="1x" icon={faComment} />
                                                </>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            onClick={() => toggleCollapse('notes')}
                                            className={`${activeCollapse === 'notes' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                        >
                                            {activeCollapse === 'notes' ?
                                                <>
                                                    <span className="pe-lg-3">Close Notes</span>
                                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                                </> :
                                                <>
                                                    <span className="pe-lg-3">Notes</span>
                                                    <FontAwesomeIcon size="1x" icon={faNoteSticky} />
                                                </>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            onClick={() => toggleCollapse('transactions')}
                                            className={`${activeCollapse === 'transactions' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                        >
                                            {activeCollapse === 'transactions' ?
                                                <>
                                                    <span className="pe-lg-3">Close Transactions</span>
                                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                                </> :
                                                <>
                                                    <span className="pe-lg-3">Transactions</span>
                                                    <FontAwesomeIcon size="1x" icon={faReceipt} />
                                                </>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            onClick={() => {
                                                toggleCollapse('editor')
                                                setIsTeamsVisible(!isTeamsVisible)
                                            }}
                                            className={`${activeCollapse === 'editor' ? 'btn btn-info fw-bold rounded-pill' : 'btn btn-info fw-bold rounded-pill'}`}
                                        >
                                            {activeCollapse === 'editor' ?
                                                <>
                                                    <span className="pe-lg-3">Close Editor</span>
                                                    <FontAwesomeIcon size="1x" icon={faClose} />
                                                </> :
                                                <>
                                                    <span className="pe-lg-3">Editor</span>
                                                    <FontAwesomeIcon size="1x" icon={faFileEdit} />
                                                </>
                                            }
                                        </button>
                                    </div>
                                </>
                            )}
                            {activeCollapse !== 'tasks' && (
                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            toggleCollapse(activeCollapse)
                                            setIsTeamsVisible(true)
                                        }}
                                        className="btn btn-danger fw-bold rounded-pill"
                                    >
                                        <span className="pe-lg-3">Close {activeCollapse}</span>
                                        <FontAwesomeIcon size="1x" icon={faClose} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`d-flex flex-wrap ${!isMdScreen ? 'justify-content-center' : 'justify-content-evenly'} align-items-start py-2`} style={{
                        height: `${!isMdScreen ? '90vh' : '80vh'}`
                    }}>
                        {/* TEAMS */}
                        <div className={`teams-div ${isMdScreen || showMobileTeam ? 'd-block' : 'd-none'} ${isMdScreen ? 'h-100 col-md-2 col-12 card bg-transparent border-0 shadow' : 'col-12 p-2 my-1 card border-0 bg-transparent rounded-lg'} ${isTeamsVisible ? 'visible' : 'collapsed'}`} style={{ overflowY: "scroll" }}>
                            <Collapse in={isMdScreen || showMobileTeam} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                <div className="p-2">
                                    <Link to={`/advanced-dashboard/manage-projects/manage-single-project/${projectData._id}`}>
                                        <button className="btn col-12 fw-bold btn-primary rounded">
                                            Manage Team
                                        </button>
                                    </Link>
                                    <div className="fs-4 fw-bold">
                                        <div className="p-2 my-3 card bg-transparent border-primary">
                                            <span>Project Host: </span>
                                            <span>{projectData?.projectHost?.firstName}</span>
                                        </div>
                                    </div>
                                    <div className="fs-4 fw-bold">
                                        <div className="p-2 my-3 card bg-transparent border-primary">
                                            <span>Project CoHost: </span>
                                            <span>{projectData?.projectCoHost?.firstName}</span>
                                        </div>
                                    </div>
                                    {projectData?.projectTeam?.length === 0 ?
                                        null
                                        :
                                        <div className="card border-secondary p-2">
                                            {!projectData?.authorizedGitAccess ? <div>
                                                <button onClick={() => setGitAccessCollapse(!gitAccessCollapse)} className="col-12 btn btn-sm fw-bold btn-primary">
                                                    {!gitAccessCollapse ? 'Assign Git Master Control To' : 'Close'}
                                                </button>
                                            </div> : <><div className="d-flex justify-content-between align-items-start">
                                                <div className="">
                                                    <div className=" fw-bold fs-6">Git Access assigned To:</div>
                                                    <div className=" fw-bold fs-5">
                                                        {projectData?.authorizedGitAccess?.firstName} {projectData?.authorizedGitAccess?.lastName}
                                                    </div>
                                                </div>
                                                <button onClick={() => setGitAccessCollapse(!gitAccessCollapse)} className="btn btn-sm btn-danger fw-bold" style={{ fontSize: 12 }}>
                                                    {gitAccessCollapse ? 'Close' : 'Change'}
                                                </button>
                                            </div>
                                                <hr />
                                                <div className="fw-bold">
                                                    <div>Repo Name: <span><a target="_blank" style={{ textDecoration: 'none' }} href={`${projectData?.projectGitRepo?.link}`}>{projectData?.projectGitRepo?.name}</a></span></div>
                                                </div></>}
                                            <Collapse in={gitAccessCollapse}>
                                                <span className="text-danger fw-bold" style={{ fontSize: 15 }}>*For git access only the users integrated their account can be selected</span>
                                                <div className="py-2">
                                                    <StyledSelect
                                                        className="col-12"
                                                        labelId="my-select-label"
                                                        id="demo-simple-select"
                                                        value={authorizedGitAccess}
                                                        label="Select User to authorize with Git Access"
                                                        variant="outlined"
                                                        onChange={handleAuthorizedGitAccess}
                                                    >
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <MenuItem key={i} disabled={!p.gitTokenId} value={p}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </div>
                                                <button onClick={() => handleSubmitGitAccess()} className=" col-12 btn btn-sm btn-success fw-bold">
                                                    Update
                                                </button>
                                            </Collapse>
                                        </div>
                                    }
                                    <div className="card p-2 my-3 fs-4 fw-bold border-primary">
                                        <span>Team Members: </span>
                                        {projectData?.projectTeam?.map((t, i) => (
                                            <div key={i} className="card bg-transparent border-0 py-1">
                                                <span className="fs-5 fw-bold">{t.firstName} {t.lastName}</span>
                                                <span className={!t.gitTokenId ? "fs-6 fw-bold text-danger" : "fs-6 fw-bold text-success"}>
                                                    {!t.gitTokenId ? 'Git access not available' : 'Git access available'}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className="">
                                        <button className="btn col-12" style={{
                                            backgroundColor: '#daebf9'
                                        }}>
                                            Public Discussion
                                        </button>
                                    </div> */}
                                </div>
                            </Collapse>
                        </div>
                        {/* CHAT SECTION */}
                        <Collapse in={showChat || isMdScreen} className={`${showChat && isMdScreen ? 'h-100' : ''} d-flex flex-wrap justify-content-end overflow-auto p-2 m-1 card border-primary bg-transparent rounded-lg ${isMdScreen ? 'col-md-4 col-12' : 'col-12'} ${!isTeamsVisible ? 'col-md-4' : ''}`}>
                            <div>
                                <div className="col-12">

                                    {chatLoading ?
                                        <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1, height: `${!isMdScreen ? '90vh' : '80vh'}` }}>
                                            <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                                                <div className="text-center">
                                                    <CircularProgress />
                                                    <div>
                                                        Loading chats please wait
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <form className="container d-flex flex-column bg-transparent" onSubmit={sendMessage}>
                                            <div id="divA" className="flex-grow-1 bg-transparent" style={{ overflowY: 'scroll', height: `${isMdScreen ? `calc(70vh - 10px)` : `calc(80vh - 10px)`}`, scrollbarWidth: 'none' }} >
                                                <div className="py-2">
                                                    {messages.map((msg, index) => (
                                                        <div key={index} className="py-2">
                                                            <div className={`text-justify`}>
                                                                <div className={`d-flex ${msg.sender === data.user.firstName ? `justify-content-end` : `justify-content-start`}`}>
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: msg.sender === data.user.firstName ? '#198CEF' : 'white',
                                                                            borderColor: msg.sender === data.user.firstName ? '#198CEF' : 'white',
                                                                        }} className={`card shadow col-md-9 col-10 break-word border-2 rounded-3 ${msg.sender === data.user.firstName ? `text-white fw-bolder border-primary` : `fw-bolder bg-white border-light`}`}>
                                                                        <div className={`px-2 pt-2 d-flex ${msg.sender === data.user.firstName ? `justify-content-end` : `justify-content-start`}`}>
                                                                            <strong className="fw-bold">{msg.sender}</strong>
                                                                        </div>
                                                                        <hr className={`my-1 mx-2 ${msg.sender === data.user.firstName ? 'bg-white' : 'bg-dark'}`} />
                                                                        <div className="p-2 fs-6 fw-normal">
                                                                            {msg.content}
                                                                        </div>
                                                                        <div className="px-2">
                                                                            <small className={`fw-normal d-flex ${msg.sender === data.user.firstName ? `justify-content-end text-light` : `justify-content-start text-muted `}`}>{formatTimestamp(msg.timestamp)}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div ref={messagesEndRef} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div id="divB" className="sticky-bottom">
                                                <div className="d-flex flex-wrap col-12 justify-content-between align-items-center p-2">
                                                    <div className="col-10">
                                                        <CssTextField
                                                            className="col-12 rounded-pill"
                                                            id="custom-css-outlined-input"
                                                            label="Send a message"
                                                            autoComplete="off"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            InputProps={{
                                                                style: {
                                                                    borderRadius: "50vh",
                                                                }
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <FontAwesomeIcon size="lg" icon={faPaperPlane} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </Collapse>
                        {/* TASKS SECTION */}
                        <div className={`card ${isMdScreen ? 'border-primary' : 'border-0'} bg-transparent rounded-lg ${isMdScreen ? 'col-md-5 col-12 m-1 h-100' : 'col-12'} ${!isTeamsVisible ? 'col-md-6' : 'p-2 my-1 '}`} style={{ overflowY: 'scroll' }}>
                            <Collapse in={activeCollapse === 'tasks'}>
                                <Collapse in={isMdScreen || showMobileTask} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                    <Collapse in={!showTaskForm} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                        <div>
                                            <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                                <h2>Task Room</h2>
                                                {projectData?.projectTeam?.length === 0 ? null :
                                                    <button onClick={() => {
                                                        setShowTaskForm(true)
                                                        setIsUpdate(false)
                                                    }} className="btn btn-primary rounded-pill">
                                                        <span className="px-2 fw-bold">Assign New Task</span>
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </button>}
                                            </div>
                                            {tasksLoading ?
                                                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1, height: `${!isMdScreen ? '90vh' : '80vh'}` }}>
                                                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                                                        <div className="text-center">
                                                            <CircularProgress />
                                                            <div>
                                                                Loading Tasks please wait
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div>
                                                    {!projectTasks || projectTasks?.length === 0 ? <div>
                                                        {projectData?.projectTeam?.length === 0 ? <div style={{ height: '50vh' }} className="d-flex justify-content-center align-items-center">
                                                            <div>
                                                                <h2 className="fw-bold">Add New Members to Team</h2>
                                                                <div className="text-center">
                                                                    <Link to={`/advanced-dashboard/manage-projects/manage-single-project/${projectData._id}`}>
                                                                        <button className="btn btn-primary rounded-pill">
                                                                            <h3 className="fw-bold m-0">Let's devSemble</h3>
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div> : <div style={{ height: '50vh' }} className="d-flex justify-content-center align-items-center">

                                                        </div>}
                                                    </div> : projectTasks?.map((pt, i) => (
                                                        <div key={i}>
                                                            {pt.taskDeleteStatus ? null :
                                                                <div className={`my-3 rounded d-flex justify-content-evenly align-items-stretch flex-wrap 
                                                ${pt.taskAccepted && !pt.taskStatus ? 'border-warning' :
                                                                        pt.taskAccepted && pt.taskStatus ? 'border-success' : 'border-danger'
                                                                    }`}>
                                                                    <div className="col-md-10 col-12 d-flex">
                                                                        <div className={`col-12 card rounded-0 py-2 ${isMdScreen ? 'shadow' : ''} border-0 border-start border-3 
                                                                ${pt.taskAccepted && !pt.taskStatus ? 'border-warning' :
                                                                                pt.taskAccepted && pt.taskStatus ? 'border-success' : 'border-danger'
                                                                            }`}>
                                                                            <div className="fw-bold d-flex justify-content-between align-items-center p-2">
                                                                                <h4 className="fw-bold m-0 text-truncate" style={{ maxWidth: '40%' }} title={pt.taskTitle}>
                                                                                    {pt.taskTitle}
                                                                                </h4>
                                                                                <h5 className="fw-bold mx-2 text-nowrap">To</h5>
                                                                                {pt?.assignedTo === null ? <h4 className="fw-bold m-0 text-truncate" style={{ maxWidth: '40%' }} title='ACTION REQUIRED'>
                                                                                    ACTION REQUIRED
                                                                                </h4> :
                                                                                    <h4 className="fw-bold m-0 text-truncate" style={{ maxWidth: '40%' }} title={pt.assignedTo.firstName}>
                                                                                        {pt.assignedTo.firstName}
                                                                                    </h4>}
                                                                            </div>
                                                                            <div className="px-2 fw-normal">{pt.taskStartDate} - {pt.taskEndDate}</div>
                                                                            <hr className="mx-2 my-1" />
                                                                            <div className="px-2">
                                                                                <span
                                                                                    className="fs-6 m-0"
                                                                                    dangerouslySetInnerHTML={{ __html: pt.taskDescription }}
                                                                                ></span>
                                                                                <h5 className="m-0">{pt.taskAccepted && !pt.taskStatus ? <span className="text-warning">Task Accepted</span> :
                                                                                    pt.taskAccepted && pt.taskStatus ? <span className="text-success">Completed{pt?.modifyState === true ? <span className="px-2 text-danger">Changed Pending</span> : null}</span> : <span className="text-danger">Not Accepted</span>
                                                                                }</h5>
                                                                            </div>
                                                                            <div className="py-2">
                                                                                <Collapse in={displayReqsAssigned === -1 && displayUpdatedData === -1} className="">
                                                                                    <div className="px-2 d-flex flex-wrap gap-3 justify-content-end align-items-center">
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => setDisplayUpdatedData(i)}>View Updates</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                            <div className={displayUpdatedData === i ? "p-2" : ''}>
                                                                                <Collapse in={displayUpdatedData === i} className="">
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <button className="btn btn-sm fw-bold btn-danger" onClick={() => setDisplayUpdatedData(-1)}>Close Updates</button>
                                                                                    </div>
                                                                                    <div className="py-3 p-2 card border-primary my-2">
                                                                                        {pt?.taskUpdates.length === 0 ? <div className="text-center text-danger fw-bold">No Tasks</div> : pt?.taskUpdates.map((u, i) => (
                                                                                            <div className="" key={i}>
                                                                                                <div className="d-flex fs-5 flex-wrap justify-content-between align-items-center">
                                                                                                    <div className="text-wrap col-12">
                                                                                                        <div className="d-flex flex-wrap justify-content-between">
                                                                                                            <div className="fw-bold">
                                                                                                                Type: <span className="fw-normal">{u?.eventType} {u.isCompleted ? <span className="fw-normal">(completed)</span> : null}</span>
                                                                                                            </div>
                                                                                                            <div className="fw-bold">
                                                                                                                Author: <span className="fw-normal">{u?.author}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="fw-bold text-wrap">
                                                                                                            Message: <span className="fw-normal">{u?.messages}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {pt?.taskUpdates.length === i + 1 ? null : <hr className="my-3" />}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                            <div className={displayReqsAssigned === i ? "p-2" : ''}>
                                                                                <Collapse in={displayReqsAssigned === i} className="">
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <button className="btn btn-sm fw-bold btn-danger" onClick={() => setDisplayReqsAssigned(-1)}>Close</button>
                                                                                    </div>
                                                                                    {/* {JSON.stringify(pt.taskUpdates)} */}
                                                                                    <div className="py-3 p-2 card border-primary my-2">
                                                                                        {pt?.requirements.length === 0 ? <div className="text-center text-danger fw-bold">No Tasks</div> : pt?.requirements.map((r, i) => (
                                                                                            <div className="" key={i}>
                                                                                                <div className="d-flex fs-5 flex-wrap justify-content-between align-items-center">
                                                                                                    <div className="text-wrap col-12">
                                                                                                        <div className="d-flex flex-wrap justify-content-between">
                                                                                                            <div className="fw-bold">
                                                                                                                {r?.title}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="fw-bold text-wrap">
                                                                                                            Desciption: <span className="fw-normal">{r?.description}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {pt?.requirements.length === i + 1 ? null : <hr className="my-3" />}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`col-md-2 col-12 p-md-2 d-flex justify-content-between ${displayUpdatedData > 0 ? 'align-content-start' : 'align-content-around'} flex-wrap`}>
                                                                        <button onClick={() => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                tId: pt?._id,
                                                                                taskTitle: pt?.taskTitle,
                                                                                taskDescription: pt?.taskDescription,
                                                                                assignedTo: pt?.assignedTo === null ? "" : pt?.assignedTo?._id,
                                                                                taskStatus: pt.taskStatus
                                                                            })
                                                                            setStartDateValue(pt.taskStartDate)
                                                                            setEndDateValue(pt.taskEndDate)
                                                                            setShowTaskForm(true)
                                                                            setIsUpdate(true)
                                                                        }} className="my-2 btn btn-sm btn-warning col-md-12 col-5 rounded">
                                                                            <FontAwesomeIcon icon={faEdit} /><br />
                                                                            {isMdScreen ? <span className=''>Update</span> : ''}
                                                                        </button>
                                                                        <button onClick={() => handleDeleteTask(pt._id)} className="my-2 btn btn-sm btn-danger col-md-12 col-5 rounded">
                                                                            <FontAwesomeIcon icon={faTrash} /><br />
                                                                            {isMdScreen ? <span className=''>Delete</span> : ''}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <hr className="d-md-none d-block" />
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </Collapse>
                                    <Collapse in={showTaskForm}>
                                        <div className="py-2">
                                            <div className="card bg-tranparent border-0 p-2">
                                                {openModifiedForm === true ?
                                                    <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                                        <button onClick={() => {
                                                            setOpenModifiedForm(false)
                                                        }} className="btn btn-warning rounded-pill">
                                                            <FontAwesomeIcon icon={faArrowLeft} />
                                                            <span className="px-2 fw-bold">To Form</span>
                                                        </button>
                                                        <h2>Update Requirements</h2>
                                                    </div> :
                                                    <div className="py-2 d-flex flex-wrap justify-content-around align-items-center">
                                                        <button onClick={() => {
                                                            setShowTaskForm(false)
                                                            setIsUpdate(false)
                                                        }} className="btn btn-warning rounded-pill">
                                                            <FontAwesomeIcon icon={faArrowLeft} />
                                                            <span className="px-2 fw-bold">To tasks</span>
                                                        </button>
                                                        <h2>Assign New Task</h2>
                                                    </div>}
                                                <Collapse in={!openModifiedForm}>
                                                    <div className="col-12">
                                                        <div className=''>
                                                            <TextField
                                                                style={{ width: '100%' }}
                                                                className='my-2'
                                                                error={error === "formError" ? true : false}
                                                                id="outlined-basic"
                                                                value={taskTitle}
                                                                label={error === 'formError' ? errorMessage : "Task Title"}
                                                                onChange={handleChange('taskTitle')}
                                                                variant="outlined" />
                                                        </div>
                                                        {error === 'taskTitleError' ? <div className="text-danger text-center">{errorMessage}</div> : null}
                                                        <div className=''>
                                                            <label htmlFor="taskDescription" className="form-label">Task Description</label>
                                                            <ReactQuill
                                                                id="taskDescription"
                                                                theme="snow"
                                                                value={taskDescription}
                                                                onChange={handleQuillChange}
                                                                placeholder="Write your task description here..."
                                                                modules={{
                                                                    toolbar: [
                                                                        ['bold', 'italic', 'underline'], // Text styles
                                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
                                                                        ['link'], // Insert links
                                                                    ],
                                                                }}
                                                            />
                                                        </div>
                                                        {error === 'dateError' ? <div className="text-danger text-center">{errorMessage}</div> : null}
                                                        <div className='col-12 d-flex flex-wrap justify-content-around'>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <MobileDatePicker
                                                                    label="Start Date"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    value={startDateValue}
                                                                    onChange={handleChangeStartDate}
                                                                    renderInput={(params) => <div className="py-2 col-12"><TextField style={{ width: '100%' }} {...params} /></div>}
                                                                />
                                                                <MobileDatePicker
                                                                    label="End Date"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    minDate={startDateValue}
                                                                    // maxDate={new Date()}
                                                                    value={endDateValue}
                                                                    onChange={handleChangeEndDate}
                                                                    renderInput={(params) => <div className="py-2 col-12"><TextField style={{ width: '100%' }} {...params} /></div>}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div>
                                                            {error === 'memberError' ? <div className="text-danger text-center">{errorMessage}</div> : null}
                                                            <label>Select a member to assign </label>
                                                            <StyledSelect
                                                                className="col-12"
                                                                id="demo-simple-select"
                                                                value={assignedTo}
                                                                variant="outlined"
                                                                onChange={handleChange('assignedTo')}
                                                            >
                                                                {projectData.projectTeam?.map((p, i) => (
                                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                                ))}
                                                            </StyledSelect>
                                                        </div>
                                                        <div className="py-3">
                                                            {isUpdate ?
                                                                <div className="d-flex flex-wrap justify-content-between aiign-items-center">
                                                                    <button onClick={() => handleUpdate({
                                                                        taskTitle,
                                                                        taskDescription,
                                                                        taskStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                                        taskEndDate: `${dayjs(endDateValue).format('MM/DD/YY')}`,
                                                                        assignedBy,
                                                                        assignedTo
                                                                    }, tId)} className="btn col-5 btn-warning">
                                                                        Update
                                                                    </button>
                                                                    <button onClick={() => setOpenModifiedForm(true)} className="btn col-5 btn-info">
                                                                        Mention New Requirements
                                                                    </button></div> :
                                                                <button onClick={() => handleSubmit({
                                                                    projectId,
                                                                    taskTitle,
                                                                    taskDescription,
                                                                    taskStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                                    taskEndDate: `${dayjs(endDateValue).format('MM/DD/YY')}`,
                                                                    assignedBy,
                                                                    assignedTo
                                                                })} className="btn col-12 btn-primary fw-bold rounded-pill">
                                                                    Submit Task
                                                                </button>}
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <Collapse in={openModifiedForm}>
                                                <div className="col-12">
                                                    <div className=''>
                                                        <TextField
                                                            style={{ width: '100%' }}
                                                            className='my-2'
                                                            error={errorModified === "formError" ? true : false}
                                                            id="outlined-basic"
                                                            value={requirement}
                                                            label={errorModified === 'formError' ? errorMessageModified : "Mention New Requirement"}
                                                            onChange={handleModifiedChange('requirement')}
                                                            variant="outlined" />
                                                    </div>
                                                    <button onClick={() => handleUpgrade({
                                                        requirement,
                                                        updatedBy
                                                    }, tId)} className="btn col-12 btn-warning">
                                                        Push Upgrade
                                                    </button>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Collapse>
                                </Collapse>
                            </Collapse>
                            <Collapse in={activeCollapse === 'discussions'}>
                                <div>
                                    <Discussions projectHost={projectData?.projectHost?._id} projectCoHost={projectData?.projectCoHost?._id} projectTech={projectData?.projectTech} />
                                </div>
                            </Collapse>
                            <Collapse in={activeCollapse === 'editor'}>
                                <div>
                                    <CollaborativeEditor />
                                </div>
                            </Collapse>
                            <Collapse in={activeCollapse === 'notes'} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                <div>
                                    <Notes projectTasks={projectTasks} teamMembers={projectData?.projectTeam} />
                                </div>
                            </Collapse>
                            <Collapse in={activeCollapse === 'transactions'} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                <div>
                                    <ProjectTransactions projectId={projectId} projectRequirements={projectData?.projectCurrentReq} projectTasks={projectTasks} teamMembers={projectData?.projectTeam} />
                                </div>
                            </Collapse>
                            <Collapse in={activeCollapse === 'requirements'} style={{ overflowY: "scroll", scrollbarWidth: 'none' }}>
                                <div>
                                    <Requirements projectId={projectId} projectRequirements={projectData?.projectCurrentReq} projectTasks={projectTasks} teamMembers={projectData?.projectTeam} />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </div>
    )
}

export default SingleProject