import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { getPublicDiscussions } from "../../actions/discussionActions";
import Navbar from "../../freeAccess/Navbar";
import Footer from "../../freeAccess/Footer";

const DiscussionList = () => {
    const [discussions, setDiscussions] = useState([]);

    useEffect(() => {
        getPublicDiscussions()
            .then((res) => setDiscussions(res || []))
            .catch((err) => console.error("Error fetching discussions:", err));
    }, []);

    return (
        <>
            <div>
                <Navbar />
            </div>
            <div className="container mt-4">
                <h1 className="text-center mb-4">Public Discussions</h1>
                {discussions.length > 0 ? (
                    discussions.map((discussion) => (
                        <div key={discussion._id} className="card border-0 shadow mb-3">
                            <div className="card-body">
                                <h4 className="card-title">
                                    <Link to={`/discussion/${discussion.slug}`} className="text-decoration-none text-primary fw-bold">
                                        {discussion.title}
                                    </Link>
                                </h4>
                                <p className="text-muted">
                                    By {discussion.createdBy.firstName} {discussion.createdBy.lastName}
                                    &nbsp; | &nbsp; {new Date(discussion.createdAt).toLocaleDateString()}
                                </p>
                                <p className="card-text text-truncate p-0 m-0" dangerouslySetInnerHTML={{ __html: discussion.description }}></p>

                                {/* Display Tags */}
                                {discussion.tags && discussion.tags.length > 0 && (
                                    <div className="">
                                        {discussion.tags.map((tag, index) => (
                                            <span key={index} className="badge bg-primary rounded-pill me-2">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                )}

                                <Link to={`/discussion/${discussion.slug}`} className="btn btn-sm btn-primary mt-3">
                                    <FontAwesomeIcon icon={faComments} /> View Discussion
                                </Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-muted text-center">No public discussions available.</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default DiscussionList;
