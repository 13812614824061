import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navbar from "./Navbar"

const TermsAndConditions = () => {
    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className="container my-5">
                <h1 className="text-center fw-bold mb-4">Terms and Conditions</h1>
                <p className="text-muted text-center">Last Updated: 18th December 2024</p>

                <section>
                    <p>
                        Welcome to DevSemble! By accessing or using our platform, services, or any related
                        tools, you agree to comply with and be bound by the following terms and conditions.
                        Please read them carefully before using the platform. If you do not agree, do not
                        access or use our services.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">1. Definitions</h3>
                    <ul>
                        <li>
                            <strong>"Platform":</strong> Refers to DevSemble, including its website,
                            applications, APIs, and related services.
                        </li>
                        <li>
                            <strong>"User":</strong> Refers to any individual or entity accessing or using the
                            platform.
                        </li>
                        <li>
                            <strong>"Content":</strong> Includes all materials such as text, images, code, and
                            other data on the platform.
                        </li>
                        <li>
                            <strong>"Project Host":</strong> Refers to users who list projects on DevSemble.
                        </li>
                        <li>
                            <strong>"Team Member":</strong> Refers to users who participate in listed projects.
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">2. Eligibility</h3>
                    <ul>
                        <li>Be at least 18 years old or have parental/guardian consent.</li>
                        <li>Comply with all applicable laws and regulations.</li>
                        <li>Not be barred from using the platform under applicable laws.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">3. User Responsibilities</h3>
                    <p>You agree to:</p>
                    <ul>
                        <li>Provide accurate, current, and complete information during registration.</li>
                        <li>Keep your account credentials confidential.</li>
                        <li>Use the platform only for its intended purpose.</li>
                        <li>Not engage in prohibited activities such as:</li>
                        <ul>
                            <li>Violating intellectual property rights.</li>
                            <li>Uploading harmful or malicious content.</li>
                            <li>Circumventing security measures.</li>
                            <li>Harassing or discriminating against other users.</li>
                            <li>Engaging in fraudulent or misleading activities.</li>
                        </ul>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">4. Account Security</h3>
                    <p>
                        You are responsible for maintaining the confidentiality of your account information
                        and are fully responsible for activities conducted under your account. Notify
                        DevSemble immediately if you suspect unauthorized use.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">5. Fees and Payments</h3>
                    <ul>
                        <li>Project hosts agree to pay the required fees, including any transaction costs, when posting a project.</li>
                        <li>Team members may receive payments based on agreed terms with the project host.</li>
                        <li>DevSemble is not liable for disputes regarding payments between users but offers a dispute resolution system.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">6. Intellectual Property</h3>
                    <ul>
                        <li>
                            <strong>Ownership:</strong> All content you upload remains your property. By posting, you grant DevSemble a non-exclusive, royalty-free license to use, display, and distribute your content.
                        </li>
                        <li>
                            <strong>Platform Content:</strong> DevSemble retains ownership of all platform-generated materials, branding, and designs.
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">7. Prohibited Conduct</h3>
                    <ul>
                        <li>Exploiting, harming, or attempting to harm minors in any way.</li>
                        <li>Using the platform for unlawful or unauthorized purposes.</li>
                        <li>Interfering with or disrupting the integrity of the platform.</li>
                        <li>Reverse engineering or disassembling any portion of the platform.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">8. Privacy Policy</h3>
                    <p>
                        Our privacy policy, available <Link to='/privacy-policy'>here</Link>, governs the collection, use, and storage of your data. By using DevSemble, you consent to the terms of the privacy policy.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">9. Termination</h3>
                    <ul>
                        <li>DevSemble reserves the right to suspend or terminate your account if:</li>
                        <ul>
                            <li>You violate these terms.</li>
                            <li>Your account is inactive for more than 12 months.</li>
                            <li>Required by law or other regulatory authorities.</li>
                        </ul>
                        <li>You may terminate your account at any time by contacting our support team.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">10. Liability Disclaimer</h3>
                    <ul>
                        <li>DevSemble is not liable for any user-generated content.</li>
                        <li>DevSemble is not responsible for:</li>
                        <ul>
                            <li>Loss of data or unauthorized access.</li>
                            <li>Financial disputes between users.</li>
                            <li>Third-party actions beyond our control.</li>
                        </ul>
                        <li>The platform is provided "as is" without warranties of any kind.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">11. Dispute Resolution</h3>
                    <ul>
                        <li>Disputes between users must first attempt resolution through our internal dispute resolution process.</li>
                        <li>Any unresolved disputes will be governed by the laws of [Insert Jurisdiction].</li>
                        <li>Claims must be brought within one year of the event giving rise to the dispute.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">12. Amendments</h3>
                    <p>
                        DevSemble reserves the right to update or modify these terms at any time. Significant changes will be notified to users, and continued use of the platform implies acceptance of the updated terms.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">13. Third-Party Services</h3>
                    <p>
                        DevSemble may integrate with third-party tools or services. By using such integrations, you agree to comply with the respective third-party terms.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">14. Community Guidelines</h3>
                    <ul>
                        <li>Respect all users and engage constructively.</li>
                        <li>No hate speech, harassment, or discriminatory behavior.</li>
                        <li>Report any violations promptly to DevSemble.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">15. Public Discussions</h3>
                    <ul>
                        <li>Discussions in public projects must adhere to community guidelines.</li>
                        <li>DevSemble reserves the right to moderate or remove content that violates terms or community guidelines.</li>
                        <li>Users must refrain from sharing sensitive or confidential information in public discussions.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">16. Beta Features Disclaimer</h3>
                    <p>
                        The DevSemble CLI and other beta features are provided "as is." DevSemble is not responsible for errors, data loss, or other issues arising from beta feature usage.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">17. User Data and Security</h3>
                    <ul>
                        <li>DevSemble employs industry-standard encryption and security measures to protect user data.</li>
                        <li>
                            However, no system is entirely secure. Users acknowledge this risk and agree not to hold DevSemble liable for unauthorized access beyond reasonable control.
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">18. Refund Policy</h3>
                    <ul>
                        <li>Refund requests are evaluated on a case-by-case basis. Refunds may be issued only if:</li>
                        <ul>
                            <li>The project is canceled before work begins.</li>
                            <li>There is a verified failure to meet agreed deliverables.</li>
                        </ul>
                        <li>Refund requests must be made within 14 days of payment.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">19. Ownership of Work</h3>
                    <ul>
                        <li>Unless otherwise agreed, the project host retains ownership of all work submitted by team members.</li>
                        <li>Team members grant a limited, non-exclusive license to project hosts to use the submitted work.</li>
                        <li>Disputes regarding ownership must be resolved through the dispute resolution process.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">20. Platform Downtime</h3>
                    <ul>
                        <li>DevSemble aims to ensure maximum platform availability but does not guarantee uninterrupted access.</li>
                        <li>Planned maintenance or unforeseen outages may lead to temporary downtime.</li>
                        <li>DevSemble is not liable for losses incurred due to downtime.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">21. Contact Information</h3>
                    <p>
                        For questions or concerns about these terms, please contact us at contact.devsemble@gmail.com or through our official contact page.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">22. Miscellaneous</h3>
                    <ul>
                        <li>If any part of these terms is deemed unenforceable, the remaining provisions will remain in effect.</li>
                        <li>DevSemble’s failure to enforce any right does not constitute a waiver of such rights.</li>
                        <li>These terms constitute the entire agreement between you and DevSemble regarding the platform.</li>
                    </ul>
                </section>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default TermsAndConditions