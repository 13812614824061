import { Link, useLocation, useParams } from "react-router-dom"
import Navbar from "./Navbar"
import { useEffect, useState } from "react"
import { fetchSingleRankedUser, fetchUsersByRankRange } from "../actions/projectActions"
import Footer from "./Footer"

const SinglePublicProfie = () => {

    let { rankId } = useParams()

    const [rankedUser, setRankedUser] = useState(null)

    const [rangeUsers, setRangeUsers] = useState([])

    useEffect(() => {
        fetchSingleRankedUser(rankId)
            .then(res => {
                setRankedUser(res);
                fetchUsersByRankRange(res?.rankRange)
                    .then(rangeUsers => {
                        const filteredUsers = rangeUsers?.users?.filter(user => user?._id !== rankId);
                        setRangeUsers(filteredUsers);
                    })
                    .catch(err => {
                        console.error("Error fetching users by rank range:", err);
                    });
            })
            .catch(err => {
                console.error("Error fetching single ranked user:", err);
            });
    }, [rankId]);

    return (
        <>
            <div>
                <Navbar />
            </div>
            <div className="d-flex flex-wrap justify-content-center rounded-3 py-md-5 py-2 gap-4">
                <div className={`col-md-3 col-11 order-md-1 order-2`}>
                    <div className="container-fluid card border-0 rounded-3 p-3" style={{ backgroundColor: '#daebf9' }}>
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <span className="fs-2 m-0"><b className="m-0">Users with Similar Ranks</b></span>
                        </div>
                        <div>
                            {/* <span className="fs-4"><span className="text-primary fw-bold">{rankedUser?.rankRange}</span></span> */}
                        </div>
                        <div>
                            <div className="pt-3">
                                {rangeUsers?.map((r, i) => (
                                    <div key={i} style={{
                                        borderBottom: '0.15rem solid grey',
                                        borderWidth: 'thin',
                                        borderTopLeftRadius: '0.30rem',
                                        borderTopRightRadius: '0.30rem',
                                        backgroundColor: 'white'
                                    }} className="p-2 my-2" >
                                        <Link to={`/public-display-rank-list/${r?._id}`} className="text-decoration-none text-dark">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 d-flex flex-wrap justify-content-between align-items-center ">
                                                    <div className="col-6 fs-5   ">
                                                        <span className="text-decoration-none text-dark">{r?.userId?.firstName} {r?.userId?.lastName}</span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {r?.rankRange === null ? 'Not Ranked' : <>{r?.rankRange}</>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-md-6 col-11  d-flex justify-content-center align-items-center rounded-3 p-3 order-md-2 order-1`} style={{ backgroundColor: '#daebf9' }}>
                    <div className="col-md-9 col-12">
                        <div className="">
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <span className="fs-2 m-0"><b className="m-0">{rankedUser?.userId?.firstName} {rankedUser?.userId?.lastName}</b></span>
                            </div>
                            <div>
                                <span className="fs-4"><span className="text-primary fw-bold">{rankedUser?.rankRange}</span></span>
                            </div>
                        </div>
                        <div>
                            <div className="py-3">
                                <div className="py-2">
                                    {rankedUser?.userId?.devSembleProfile?.aboutYou}
                                </div>
                            </div>
                            <div className="">
                                <div className="py-3">
                                    <div className='shadow py-3 bg-white rounded-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Work exprience</h3></div>
                                        {rankedUser?.userId?.devSembleProfile?.workExprience?.length !== 0 ? <div className='py-2'>{rankedUser?.userId?.devSembleProfile?.workExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.title}</h4></span>
                                                <div><b className=""></b>{w.description}</div><br />
                                                <div className="fw-bold" style={{ wordWrap: 'break-word' }}>
                                                    Tech Used: {w.techUsed.map((t, i) => <div key={i} className='badge bg-primary rounded-pill mx-1 px-2 fw-bold text-white' style={{ wordWrap: 'break-word' }}>{t}</div>)}
                                                </div>
                                                {rankedUser?.userId?.devSembleProfile?.workExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : null}
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className='shadow py-3 bg-white rounded-3'>
                                        <div align="center" className='d-flex justify-content-around align-items-center'><h3 className="fw-normal">Education exprience</h3></div>
                                        {rankedUser?.userId?.devSembleProfile?.educationExprience?.length !== 0 ? <div className='py-0'>{rankedUser?.userId?.devSembleProfile?.educationExprience?.map((w, i) =>
                                            <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.courseName}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                <div><b className=""></b>{w.institutionName}</div><br />
                                                {rankedUser?.userId?.devSembleProfile?.educationExprience?.length - 1 === i ? null : <hr />}
                                            </div>)}</div> : null}
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className="shadow py-3 bg-white rounded-3">
                                        <div align="center" className="d-flex justify-content-around align-items-center">
                                            <h3 className="fw-normal">Other Links</h3>
                                        </div>
                                        {rankedUser?.userId?.devSembleProfile?.otherLinks?.length !== 0 ? (
                                            <div className="py-0">
                                                {rankedUser?.userId?.devSembleProfile?.otherLinks?.map((w, i) => (
                                                    <div className="container-fluid border-primary my-0 p-2" key={i}>
                                                        <span className="d-flex align-items-center">
                                                            <h4>{w.linkTitle}</h4>
                                                        </span>
                                                        <div className="text-wrap">
                                                            <b>Link:</b>{" "}
                                                            <a
                                                                href={w.linkHref}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-decoration-none text-primary"
                                                                style={{ wordBreak: "break-word" }}
                                                            >
                                                                {w.linkHref}
                                                            </a>
                                                        </div>
                                                        {rankedUser?.userId?.devSembleProfile?.otherLinks?.length - 1 === i ? null : <hr />}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="py-3 text-center">
                                                <h4>No Links Added</h4>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </>
    )
}

export default SinglePublicProfie