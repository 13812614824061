import Footer from "./Footer";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {
    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div className="container my-5">
                <h1 className="text-center fw-bold mb-4">Privacy Policy</h1>
                <p className="text-muted text-center">Last Updated: 18th December 2024</p>
                <p>
                    At DevSemble, we value your privacy and are committed to protecting your
                    personal information. This Privacy Policy explains how we collect, use,
                    share, and safeguard your data when you use our platform, services, and
                    related tools. Please read this policy carefully. By accessing or using
                    DevSemble, you agree to the practices described herein.
                </p>

                <section className="py-3">
                    <h3 className="pb-2">1. Information We Collect</h3>
                    <ul>
                        <li>
                            <strong>Information You Provide to Us:</strong>
                            <ul>
                                <li>
                                    <strong>Account Information:</strong> Name, email address, password, and other registration details.
                                </li>
                                <li>
                                    <strong>Payment Information:</strong> Information for
                                    transactions on the platform.
                                </li>
                                <li>
                                    <strong>Profile Information:</strong> Skills, professional
                                    experience, portfolio links, and other details added to your
                                    profile.
                                </li>
                                <li>
                                    <strong>Project Details:</strong> Information related to
                                    projects you create, join, or contribute to.
                                </li>
                                <li>
                                    <strong>Communication Data:</strong> Messages, comments, and
                                    other content you share on the platform.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Information We Automatically Collect:</strong>
                            <ul>
                                <li>
                                    <strong>Device Information:</strong> IP address, browser type,
                                    operating system, and device identifiers.
                                </li>
                                <li>
                                    <strong>Usage Data:</strong> Pages viewed, time spent on the
                                    platform, clicks, and other interaction data.
                                </li>
                                <li>
                                    <strong>Cookies and Similar Technologies:</strong> Session
                                    cookies for authentication and tracking cookies to enhance your
                                    experience.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Information from Third Parties:</strong>
                            <ul>
                                <li>
                                    <strong>Social Media and Integrations:</strong> Data from
                                    linked accounts, such as GitHub, if you choose to integrate
                                    them.
                                </li>
                                <li>
                                    <strong>Payment Providers:</strong> Information related to
                                    transactions and payment confirmations.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">2. How We Use Your Information</h3>
                    <ul>
                        <li>To create and manage your account.</li>
                        <li>To facilitate collaboration between project hosts and team members.</li>
                        <li>To recommend projects, team members, or tools based on your profile and activity.</li>
                        <li>To send you notifications, updates, and support messages.</li>
                        <li>To process payments and issue invoices or refunds.</li>
                        <li>To monitor and secure the platform from fraud or unauthorized activity.</li>
                        <li>To comply with legal obligations.</li>
                        <li>To analyze usage trends and improve platform performance.</li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">3. How We Share Your Information</h3>
                    <ul>
                        <li>
                            <strong>With Other Users:</strong>
                            <ul>
                                <li>Public profile information, such as your name, skills, and portfolio.</li>
                                <li>Project-related details if you are a team member or project host.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>With Service Providers:</strong> We may share your
                            information with trusted third-party service providers for payment
                            processing, email and notification delivery, analytics, and data
                            processing.
                        </li>
                        <li>
                            <strong>Legal and Compliance:</strong> To comply with legal
                            obligations, such as court orders or subpoenas.
                        </li>
                        <li>
                            <strong>In Business Transfers:</strong> If DevSemble undergoes a
                            merger, acquisition, or sale of assets, your information may be
                            transferred as part of that transaction.
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">4. Your Rights and Choices</h3>
                    <ul>
                        <li>
                            <strong>Access and Control:</strong>
                            <ul>
                                <li>View and edit your profile information.</li>
                                <li>Request account deletion through our support team.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Communication Preferences:</strong>
                            <ul>
                                <li>Opt out of promotional emails by following the unsubscribe link.</li>
                                <li>Manage notification preferences in your account settings.</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">5. Data Retention</h3>
                    <p>
                        We retain your information for as long as your account is active or
                        as necessary to comply with legal obligations, resolve disputes, or
                        enforce agreements. Aggregated or anonymized data may be retained
                        indefinitely for research and analysis.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">6. Data Security</h3>
                    <p>
                        We use industry-standard security measures, including encryption,
                        access controls, and monitoring. While we strive to safeguard your
                        information, no system is completely secure. Users are encouraged to
                        take precautions, such as using strong passwords and enabling
                        two-factor authentication.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">7. Children's Privacy</h3>
                    <p>
                        DevSemble is not intended for individuals under the age of 18. We do
                        not knowingly collect personal information from children. If we learn
                        that we have collected data from a minor, we will take steps to
                        delete it promptly.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">8. Changes to this Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy periodically. Significant changes
                        will be communicated to users through email or platform
                        notifications. Continued use of the platform indicates acceptance of
                        the updated policy.
                    </p>
                </section>

                <section className="py-3">
                    <h3 className="pb-2">9. Contact Us</h3>
                    <p>
                        If you have questions or concerns about this Privacy Policy, please
                        contact us at:
                    </p>
                    <p>
                        <strong>Email:</strong> contact.devsemble@gmail.com
                    </p>
                </section>

                <p>
                    By using DevSemble, you acknowledge that you have read, understood, and
                    agreed to this Privacy Policy.
                </p>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;