import auth_flow from '../authFlow/assets/auth_form.png'
import integrate_github from '../authFlow/assets/integrate_git.png'
import team_requested from '../authFlow/assets/team_requested.png'
import display_projects from '../authFlow/assets/display_projects.png'
import riidl_svv_logo from '../authFlow/assets/logo_riidl_svv.png'
import startup_india_logo from '../authFlow/assets/startup_india_logo.png'
import git_integration_with_tasks from '../authFlow/assets/git_integration_with_tasks.png'
import React, { useState } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import {
    Container,
    Typography,
    Grid,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CardMedia,
    Box,
    Alert,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ArrowForward, Replay } from '@mui/icons-material'

const steps = [
    {
        id: 1,
        title: "Sign Up and Set Up Your Profile",
        description: `
            <ul>
                <li>Register yourself and be redirected to the <b>dashboard</b>.</li>
                <li>Add your <i>projects</i>, experiences, and <u>Git repositories</u>.</li>
                <li>Complete the registration form by adding your <b>education experiences</b> and other relevant links.</li>
            </ul>
        `,
        image: auth_flow, // Replace with your image path
    },
    {
        id: 2,
        title: "Integrate your GitHub",
        description: `
            <ul>
                <li>DevSemble recommends adding <b>Git repositories</b> to improve your ranking.</li>
                <li>Click the <b>'Integrate Your GitHub'</b> button to authorize DevSemble to access your GitHub account.</li>
                <li>Add your GitHub repositories to showcase contributions.</li>
            </ul>
        `,
        image: integrate_github, // Replace with your image path
    },
    {
        id: 3,
        title: "Apply to Projects",
        description: `
            <ul>
                <li>Request to join a <u>public project</u>.</li>
                <li>The project will move to the <b>Team Requested</b> tab.</li>
                <li>You can <i>accept the offer</i> or negotiate on the proposed amount.</li>
            </ul>
        `,
        image: display_projects, // Replace with your image path
    },
    {
        id: 4,
        title: "Team Requested",
        description: `
            <ul>
                <li>Projects requested by the <i>team</i> will appear here.</li>
                <li>Negotiate on the <b>proposed amount</b> or accept it directly.</li>
            </ul>
        `,
        image: team_requested, // Replace with your image path
    },
];


const UserTour = () => {

    const [expanded, setExpanded] = useState(1);

    const handleAccordionChange = (stepId) => () => {
        setExpanded(stepId);
    };

    const handleNextStep = () => {
        if (expanded < steps.length) {
            setExpanded((prev) => prev + 1);
        }
    };

    const handleRestartTour = () => {
        setExpanded(1);
        window.scrollTo({ top: 0, behavior: "smooth" })
    };

    return (
        <>
            <Navbar />
            <Container sx={{ mt: 5, mb: 5 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    DevSemble User Tour
                </Typography>
                <div>
                    <Alert
                        severity="info"
                        sx={{
                            mt: 4,
                            borderRadius: "8px",
                            backgroundColor: "#f1f9ff",
                            color: "#0056b3",
                            fontWeight: "bold",
                        }}
                    >
                        This guide covers only some aspects of DevSemble. More features and updates will be added soon!
                    </Alert>
                </div>
                <div className=''>
                    {steps.map((step) => (
                        <Accordion
                            key={step.id}
                            expanded={expanded === step.id}
                            onChange={handleAccordionChange(step.id)}
                            sx={{
                                mb: 2,
                                borderRadius: "12px",
                                background: "#ffffff",
                                boxShadow:
                                    "0px 4px 10px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.05)",
                                overflow: "hidden",
                            }}
                        >
                            <AccordionSummary
                                className='p-1 px-3'
                                expandIcon={<ExpandMore />}
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: expanded === step.id ? "#f8f9fa" : "#ffffff",
                                    fontWeight: "bold",
                                    fontSize: "1.1rem",
                                }}
                            >
                                <Typography variant='h6' >{step.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='bg-light'>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <CardMedia
                                            component="img"
                                            image={step.image}
                                            alt={step.title}
                                            sx={{
                                                maxHeight: "300px",
                                                objectFit: "contain",
                                                borderRadius: "8px",
                                                border: "0",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="body1"
                                            mb={2}
                                            dangerouslySetInnerHTML={{ __html: step.description }}
                                        />
                                        {expanded === step.id && (
                                            <Box textAlign="end">
                                                {expanded < steps.length ? (
                                                    <Button
                                                        variant="text"
                                                        endIcon={<ArrowForward />}
                                                        onClick={handleNextStep}
                                                    >
                                                        Next
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="text"
                                                        startIcon={<Replay />}
                                                        onClick={handleRestartTour}
                                                        sx={{
                                                            color: "#28a745",
                                                        }}
                                                    >
                                                        Restart Tour
                                                    </Button>
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default UserTour;