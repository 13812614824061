import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import AuthNavbar from "../AuthAccess/AuthNavbar";
import { apiUrl } from "../constants";
import Navbar from "./Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

const BlogLists = () => {

    useEffect(() => {
        getDevToBlogs()
    }, [])

    const [devToBlogs, setDevToBlogs] = useState([])
    const [loading, setLoading] = useState(true)

    const getDevToBlogs = () => {
        fetch(`${apiUrl}/get-dev-to-blogs`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setDevToBlogs(data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <div>
                <Navbar />
            </div>
            <div className="">
                <div className="">
                    <Link to="/">
                        <button className="btn btn-sm btn-warning rounded-pill fw-bold">
                            <FontAwesomeIcon className="pe-1" size="1x" icon={faArrowLeft} />
                            Go Back
                        </button>
                    </Link>
                    <div className="text-center fw-normal display-1">
                        Blogs
                    </div>
                </div>
                <div className="d-flex py-md-5 py-2 flex-wrap justify-content-center align-items-center">
                    {loading ? <div className="py-5">
                        <CircularProgress />
                    </div> :
                        <div className=" col-10 d-flex flex-wrap justify-content-center gap-3">
                            {devToBlogs.map((d, i) => (
                                <div key={i} className="card col-md-5 gap-4 bg-white border-0 shadow p-2 my-3 m-md-2 rounded-3">
                                    <Link to={`/single-blog/${d.id}`}>
                                        <div className="fs-3">{d.title}</div>
                                    </Link>
                                    <div>{d.description}</div>
                                </div>
                            ))}
                        </div>}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default BlogLists