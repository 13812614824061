import { useEffect, useState } from "react"
import { getWallet } from "../../actions/userProfileActions"
import { useAuthStore } from "../../store/store"
import { CircularProgress } from "@mui/material"

const WalletAndTranscations = () => {

    const data = useAuthStore(state => state.user)

    const [walletData, setWalletData] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getWalletData()
    }, [])

    const getWalletData = () => {
        getWallet(data.user._id).then(res => {
            setWalletData(res)
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="col-md-10 col-12">
            {loading ?
                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                        <div className="text-center">
                            <CircularProgress />
                            <div>
                                Loading Tasks please wait
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="d-flex flex-wrap justify-content-center py-md-5">
                    <div className="card col-12 shadow border-0">
                        <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                            <h4 className="m-0">Wallet and Transactions</h4>
                            <h5 className="m-0">Balance: ₹{walletData?.walletBalance}</h5>
                        </div>
                        <div className="card-body" style={{ overflow: 'scroll' }}>
                            <h5 className="pb-3">Transaction History</h5>
                            <table className="table table-bordered table-striped table-hover">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">Transaction ID</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Project ID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {walletData?.transactionHistory?.map((transaction) => (
                                        <tr key={transaction._id}>
                                            <td>{transaction._id}</td>
                                            <td>₹{transaction.amount}</td>
                                            <td>{transaction.transactionType}</td>
                                            <td>{transaction.projectId}</td>
                                            <td>
                                                <span
                                                    className={`badge ${transaction.status === "Success"
                                                        ? "bg-success"
                                                        : "bg-danger"
                                                        }`}
                                                >
                                                    {transaction.status}
                                                </span>
                                            </td>
                                            <td>
                                                {new Date(transaction.date).toLocaleString("en-IN", {
                                                    dateStyle: "short",
                                                    timeStyle: "short",
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default WalletAndTranscations