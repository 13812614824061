import { useEffect, useState } from "react"
// import { getScore } from "../actions/reviewActions"
import { fetchRankedUsers } from "../actions/projectActions"
import Navbar from "./Navbar"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import Footer from "./Footer"

const PublicDisplayRankList = () => {

    const [list, setList] = useState([])

    useEffect(() => {
        fetchRankedUsers().then(res => {
            setList(res?.reverse())
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div>
            <Helmet>
                <title>DevSemble Ranked Users - Talent Meets Recognition</title>
                <meta
                    name="description"
                    content="Browse the leaderboard of top-rated users on DevSemble. Discover skilled collaborators, learn from the best, and join a network of recognized talent."
                />
            </Helmet>
            <div>
                <Navbar />
            </div>
            <div className="py-md-5 py-2">
                <div>
                    <div className="container-fluid d-flex flex-wrap justify-content-center col-md-8 col-lg-6 col-11 p-md-4 p-2 align-items-center rounded-3" style={{
                        backgroundColor: '#daebf9'
                    }}>
                        <div className="pb-md-4 pb-2 text-center">
                            <span className="fs-4">Your contributions, your recognition — DevSemble’s ranking algorithm ensures your efforts shine.</span>
                        </div>
                        <div className="card border-0 bg-transparent col-md-10 col-lg-10 col-12">
                            {list.map((l, i) => (
                                <div key={i} style={{
                                    borderBottom: '0.15rem solid grey',
                                    borderWidth: 'thin',
                                    borderTopLeftRadius: '0.30rem',
                                    borderTopRightRadius: '0.30rem',
                                    backgroundColor: 'white'
                                }} className="p-2 my-1" >
                                    <div className="text-decoration-none text-dark">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className=" col-lg-7 col-12 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="fs-5">
                                                    <span className="text-decoration-none text-dark">{l?.userId?.firstName} {l?.userId?.lastName}</span>
                                                </div>
                                                <div className="text-lg-end">
                                                    {l?.rankRange === null ? 'Not Ranked' : <>{l?.rankRange}</>}
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-12 d-flex flex-wrap justify-content-end">
                                                <Link to={`${l._id}`}>
                                                    <button className="btn btn-sm btn-primary rounded-pill">
                                                        View Profile
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}

export default PublicDisplayRankList