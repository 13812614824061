import { useNavigate, Link, Outlet, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"
import { getProfiles } from "../../actions/userProfileActions"
import { Collapse } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { joinTeamRequest, getSingleProject } from "../../actions/projectActions"
import Select from '@mui/material/Select';

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#0275d8',
        },
        '&:hover fieldset': {
        borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'white'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'white',
    label: 'white',
    icon: 'white',
    '& label': {
        color: '#0275d8',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
  }));

const UserViewProjectTeam = () => {
    
    const navigate = useNavigate()
    // const project_id = useLocation().state
    const [project_id, setProject_id] = useState(useParams().pId)
    // const project_id = useParams().pId
    const [refresh, setRefresh] = useState(false)
    
    useEffect(() => {
        getProfiles().then(res => {
            setProfiles(res)
        }).catch(err => {
            console.log(err)
        })
        getSingleProject(project_id).then(res => {
            setProjectData(res)
        }).catch(err => {
            console.log(err)
        })
    }, [refresh])

    // let {pId} = useParams() 
    const [projectData, setProjectData] = useState({})
    const [showTeam, setShowTeam] = useState(true)
    const [searchUsers, setSearchUsers] = useState(false)
    const [showSelectedUsers, setShowSelectedUsers] = useState(false)
    const [showUpdateCoHost, setShowUpdateCoHost] = useState(false)
    const [displayOutlet, setDisplayOutlet] = useState(false)
    const [fetchedUser, setFetchedUser] = useState([])
    const [teamCollapsible, setTeamCollapsible] = useState(-1)
    const [showCollapsible, setShowCollapsible] = useState(-1);
    const [showSelectedCollapsible, setShowSelectedCollapsible] = useState(-1);

    const [profiles, setProfiles] = useState([])
    const [sudoRequest, setSudoRequest] = useState([])
    const [sudoUserRequestIds, setSudoUserRequestIds] = useState([])

    const onSubmitRequests = (projectId, selectedIds) => {
        joinTeamRequest({projectId, selectedIds}).then(res => {
            if (res) {
                navigate('/advanced-dashboard/manage-projects', {state:{status: 'positive', success : "Request send succesfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleRemoveTeamRequest = (userId) => {
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e) !== JSON.stringify(userId)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e) !== JSON.stringify(userId.userId._id)))
    }

    return (
        <AuthNavbar>
            <div className="d-flex m-auto row py-5">
                <div className="container pb-md-4 pb-3">
                    <span className="col-1">
                        <Link to={'/dashboard/teams-joined'} className="" style={{ textDecoration: 'none' }}>
                            <button className="btn btn-outline-warning col-md-1 rounded-pill">
                                <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">back</span>
                            </button>
                        </Link>
                    </span>
                </div>
                <div className={!showSelectedUsers ? "col-lg-6" : "col-lg-6 order-lg-1 order-2"}>
                    <Collapse in={!showSelectedUsers}>
                        <div className="card border-primary bg-transparent my-3 p-2">
                            <div className="d-flex col-12 justify-content-between align-items-center">
                                <h3 className="">{projectData.projectTitle}</h3>
                                {displayOutlet ?
                                <Link to={`/dashboard/teams-joined/single-project/${projectData._id}` }>
                                    <button onClick={() => {setDisplayOutlet(false)
                                    setRefresh(true)}} className="btn btn-primary rounded-pill">
                                        View Team
                                    </button>
                                </Link> : 
                                <Link to="project-tasks" state={projectData._id}>
                                    <button onClick={() => setDisplayOutlet(true)} className="btn btn-primary rounded-pill">
                                        View tasks
                                    </button>
                                </Link>
                                }
                            </div>
                            <div className="col-12 d-flex flex-wrap ">
                                {projectData.projectTech?.map((p, i) => (
                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                        <span className="fs-6">{p}</span>
                                    </div>
                                ))}
                            </div>
                            <Collapse in={!displayOutlet}>
                                <div>
                                    <p className="m-0 fs-5">{projectData.projectDescription}</p>
                                    <hr/>
                                    <div className="">
                                        <h4>Project Current Requirements</h4>
                                        {projectData.projectCurrentReq?.map((p, i) => (
                                            <div key={i}>
                                                <div className="d-flex align-items-start justify-content-start">
                                                    <span className="fs-5 col-1 text-center">{i+1}.</span>
                                                    <div className="col-9">
                                                        <p className="m-0 fs-5">{p?.title}</p>
                                                        <p className="m-0 fs-5">{p?.description}</p>
                                                    </div>
                                                </div>
                                                <hr className="mx-2 mx-md-5"/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={displayOutlet}>
                                <div>
                                    <span className="fs-4">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                    <hr/>
                                    <span className="fs-4"><b>Team Members</b></span>
                                    <div className="card border-primary bg-transparent my-3 p-2">
                                        {projectData.projectTeam?.map((p, i) => (
                                            <div key={i}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-7">
                                                    <span className="fs-4 ">{p?.firstName} {p?.lastName} </span>
                                                </div>
                                                <div className="col-12 col-md-5 d-flex justify-content-end align-items-center">
                                                    {teamCollapsible === i ? 
                                                    <button onClick={() => setTeamCollapsible(-1)} className="btn btn-outline-danger rounded-pill">
                                                        Close
                                                    </button> : 
                                                    <button onClick={() => setTeamCollapsible(i)} className="btn btn-outline-primary rounded-pill">
                                                        View Details
                                                    </button>}
                                                </div>
                                            </div>
                                            <Collapse className="" in={teamCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2"/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2"/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <hr/>
                                            </div>
                                        ))}    
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </Collapse>
                    <Collapse in={showSelectedUsers}>
                        <div className="card border-primary bg-transparent my-3 p-2">
                                <div className="d-flex flex-wrap justify-content-between align-items-start">
                                    <div className="">
                                        <span className="fs-4">Team Members you selected</span>
                                    </div>
                                    {sudoRequest.length === 0 ? null : 
                                    <button onClick={() => onSubmitRequests(projectData._id, sudoUserRequestIds)} className="btn my-2 btn-success col-md-4 rounded-pill">
                                        <span className="ps-lg-3">Send Requests</span>
                                    </button>}
                                </div>
                                <hr className="my-1"/>
                            <div className="conatiner-fluid card p-2 bg-transparent " style={{height: "50vh", overflowY: "scroll"}}>
                                {sudoRequest.map((s, i) => (
                                    <div key={i} className="">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 col-md-6">
                                                <span className="fs-4 ">{s.userId.firstName} {s.userId.lastName}</span>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                                            <button onClick={() => handleRemoveTeamRequest(s)} className="btn btn-outline-danger rounded-pill">
                                                    Deselect
                                                </button>
                                                {showSelectedCollapsible === i ? 
                                                <button onClick={() => setShowSelectedCollapsible(-1)} className="btn btn-outline-danger rounded-pill">
                                                    Close
                                                </button> : 
                                                <button onClick={() => setShowSelectedCollapsible(i)} className="btn btn-outline-primary rounded-pill">
                                                    View Details
                                                </button>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <Collapse className="" in={showSelectedCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {s?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2"/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {s?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2"/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <hr/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className={showTeam && !displayOutlet ? "col-lg-6" : "col-lg-6 order-lg-2 order-1"}>
                    <Collapse in={showTeam && !displayOutlet}>
                        <div className="">
                            <div className="card border-primary bg-transparent my-3 p-2">
                                {searchUsers ? <div className="d-flex container-fluid justify-content-between align-items-center">
                                    <button onClick={() => {setSearchUsers(false)
                                    setShowSelectedUsers(false)}} className="btn my-2 btn-outline-warning col-md-4 rounded-pill">
                                        <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">View team</span>
                                    </button>
                                </div> : <div>
                                    {projectData.projectCoHost === undefined ? <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <span className="fs-3">No Co-Host selected</span>
                                    </div> : <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <span className="fs-3">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                    </div>}
                                    <hr/>
                                </div>}
                                <div className="">
                                    <Collapse in={!searchUsers}>
                                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                                            <div className="col-12 py-3 d-flex row justify-content-around align-items-start">
                                                <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                                    {projectData.projectTeam?.length === 0 ? <div>
                                                        <span>No Team members</span>
                                                    </div> : <div>
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <div key={i}>
                                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                                <div className="col-12 col-md-7">
                                                                    <span className="fs-4 ">{p?.firstName} {p?.lastName}</span>
                                                                    <span className="px-2 fx-5">({p?.rankId?.rankRange})</span>
                                                                </div>
                                                                <div className="col-12 col-md-5 d-flex justify-content-end align-items-center">
                                                                    {teamCollapsible === i ? 
                                                                    <button onClick={() => setTeamCollapsible(-1)} className="btn btn-outline-danger rounded-pill">
                                                                        Close
                                                                    </button> : 
                                                                    <button onClick={() => setTeamCollapsible(i)} className="btn btn-outline-primary rounded-pill">
                                                                        View Details
                                                                    </button>}
                                                                </div>
                                                            </div>
                                                            <Collapse className="" in={teamCollapsible === i}>
                                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                        <div>
                                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                                            <div>
                                                                                {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                                    <div key={i} className="">
                                                                                        <span className="fs-4">{w.title}</span>
                                                                                        <div className="d-flex flex-wrap ">
                                                                                            {w?.techUsed.map((t, i) => (
                                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                                                    <span className="">{t}</span>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                        <hr className="mb-2"/>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                        <div>
                                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                                            <div>
                                                                                {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                                    <div key={i} className="">
                                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                                        <hr className="mb-2"/>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                            <hr/>
                                                            </div>
                                                        ))}    
                                                    </div>}
                                                </div>
                                                    <span className="fs-3"><b>Users Requested</b></span>
                                                <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                                    {projectData.projectTeamRequested?.length === 0 ? <div>
                                                        <span>No one requested</span>
                                                    </div> : <div>
                                                        {projectData.projectTeamRequested?.map((p, i) => (
                                                            <div key={i}>
                                                                <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                                    <div className="col-12 col-md-7">
                                                                        <span className="fs-4 ">{p?.firstName} {p?.lastName}</span>
                                                                        <span className="px-2 fx-5">({p?.rankId?.rankRange})</span>
                                                                    </div>
                                                                    <div className="col-12 col-md-5 d-flex justify-content-end align-items-center">
                                                                        {showCollapsible === i ? 
                                                                        <button onClick={() => setShowCollapsible(-1)} className="btn btn-outline-danger rounded-pill">
                                                                            Close
                                                                        </button> : 
                                                                        <button onClick={() => setShowCollapsible(i)} className="btn btn-outline-primary rounded-pill">
                                                                            View Details
                                                                        </button>}
                                                                    </div>
                                                                </div>
                                                                <Collapse className="" in={showCollapsible === i}>
                                                                    <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                            <div>
                                                                                <span className="fs-4"><b>Work Exprience</b></span>
                                                                                <div>
                                                                                    {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                                        <div key={i} className="">
                                                                                            <span className="fs-4">{w.title}</span>
                                                                                            <div className="d-flex flex-wrap ">
                                                                                                {w?.techUsed.map((t, i) => (
                                                                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                                                        <span className="">{t}</span>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                            <hr className="mb-2"/>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                            <div>
                                                                                <span className="fs-4"><b>Education Exprience</b></span>
                                                                                <div>
                                                                                    {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                                        <div key={i} className="">
                                                                                            <span className="fs-4">{e.eduTitle}</span>
                                                                                            <hr className="mb-2"/>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                                <hr/>
                                                            </div>
                                                        ))}    
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <Collapse in={!showTeam}>
                            <div className="container-fluid">
                                {profiles.map((p, i) => (
                                    <div key={i}>
                                        <div className="card bg-transparent my-3 p-2 border-primary">
                                            <p className="m-0">{p.userId.firstName} {p.userId.lastName}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                    </Collapse>
                    <Collapse in={displayOutlet}>
                        <div className="card border-primary bg-transparent my-3 p-2">
                            <Outlet context={[displayOutlet, setDisplayOutlet]} />
                        </div>
                    </Collapse>
                </div>
            </div>
        </AuthNavbar>
    )
}

export default UserViewProjectTeam