import Navbar from "./Navbar";
import devS_installation from '../authFlow/assets/devS_installation.png'
import { useEffect, useState } from "react";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import Collapse from "@mui/material/Collapse";

const CLIPage = () => {

    const [platform, setPlatform] = useState('');
    const [downloadLink, setDownloadLink] = useState('');

    const [openSection, setOpenSection] = useState(null);

    const [showModal, setShowModel] = useState(false)

    useEffect(() => {
        // Detect platform based on userAgent
        const userAgent = window.navigator.userAgent.toLowerCase();
        let detectedPlatform = '';
        let link = '';

        if (userAgent.includes('win')) {
            detectedPlatform = 'Windows';
            link = 'https://devsemble.com/files/setup-win.exe';
        } else if (userAgent.includes('mac')) {
            detectedPlatform = 'macOS';
            link = 'https://devsemble.com/files/setup-macos';
        } else if (userAgent.includes('linux')) {
            detectedPlatform = 'Linux';
            link = 'https://devsemble.com/files/setup-linux';
        } else {
            detectedPlatform = 'Unsupported';
        }

        setPlatform(detectedPlatform);
        setOpenSection(detectedPlatform)
        setDownloadLink(link);
    }, []);

    const handleToggle = (section) => {
        setOpenSection((prevSection) => (prevSection === section ? null : section));
    };

    const handleDownload = async () => {
        if (!downloadLink) return;

        try {
            const response = await fetch(downloadLink, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const filename = platform === 'Windows' ? 'setup-win.exe' : `setup-${platform.toLowerCase()}`;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const getInstructions = (os) => {
        const instructions = {
            Windows: (
                <ol>
                    <li>Click the download button for Windows and save the file.</li>
                    <li>Right-click the downloaded file and select <strong>"Run as Administrator"</strong>.</li>
                    <li>Follow the on-screen installation instructions.</li>
                    <li>
                        Open a new Command Prompt and verify the installation with:
                        <pre className="bg-light p-3 rounded">
                            <code>devS --version</code> or <code>devS help</code>
                        </pre>
                    </li>
                </ol>
            ),
            macOS: (
                <ol>
                    <li>Download the setup file for macOS.</li>
                    <li>
                        Open the terminal and navigate to the directory:
                        <pre className="bg-light p-3 rounded">
                            <code>cd ~/Downloads</code>
                        </pre>
                    </li>
                    <li>
                        Make the setup file executable:
                        <pre className="bg-light p-3 rounded">
                            <code>chmod +x setup-macos</code>
                        </pre>
                    </li>
                    <li>
                        Run the setup file:
                        <pre className="bg-light p-3 rounded">
                            <code>./setup-macos</code>
                        </pre>
                    </li>
                    <li>
                        Verify the CLI by running:
                        <pre className="bg-light p-3 rounded">
                            <code>devS --version</code> or <code>devS help</code>
                        </pre>
                    </li>
                </ol>
            ),
            Linux: (
                <ol>
                    <li>Download the setup file for Linux.</li>
                    <li>
                        Open the terminal and navigate to the directory:
                        <pre className="bg-light p-3 rounded">
                            <code>cd ~/Downloads</code>
                        </pre>
                    </li>
                    <li>
                        Make the setup file executable:
                        <pre className="bg-light p-3 rounded">
                            <code>chmod +x setup-linux</code>
                        </pre>
                    </li>
                    <li>
                        Run the setup file:
                        <pre className="bg-light p-3 rounded">
                            <code>./setup-linux</code>
                        </pre>
                    </li>
                    <li>
                        Verify the CLI installation with:
                        <pre className="bg-light p-3 rounded">
                            <code>devS --version</code> or <code>devS help</code>
                        </pre>
                    </li>
                </ol>
            ),
        };
        return instructions[os];
    };

    return (
        <>
            <Helmet>
                <title>DevSemble CLI - Streamline Your Workflow</title>
                <meta
                    name="description"
                    content="Explore the DevSemble CLI, designed to simplify project management and GitHub integration. Automate commits, manage tasks, and collaborate seamlessly."
                />
            </Helmet>
            <Navbar />
            <div className="container my-5">
                <header className="text-center mb-5">
                </header>

                {/* Download Section */}
                <section className="row align-items-center mb-5">
                    <div className="col-md-6">
                        <h1 className="display-5 fw-bold">devSemble CLI</h1>
                        <p className="fs-4 text-muted">Effortless Collaboration for Developers</p>
                        <p className="fs-5">
                            Get started by downloading the setup file and running it as an administrator. This process will automatically install the latest version of the CLI and configure it for your system.
                        </p>
                        {platform === 'Unsupported' ? (
                            <p>Sorry, your platform is not supported for this download.</p>
                        ) : (
                            <>
                                <p className="">Your platform is detected as: <strong>{platform}</strong></p>
                                <button
                                    onClick={() => setShowModel(!showModal)}
                                    className="btn fw-bold btn-primary"
                                >
                                    Download for {platform} coming soon
                                </button>
                            </>
                        )}
                    </div>
                    <div className="col-md-6 py-3 text-center">
                        <img
                            src={devS_installation}
                            alt="Download Illustration"
                            className="img-fluid rounded-3 shadow"
                        />
                    </div>
                </section>
                <div>
                    {showModal && (
                        <div
                            className="modal fade show rounded-3"
                            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                            tabIndex="-1"
                        >
                            <div className="modal-dialog border-0">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5 className="modal-title">Coming Soon!</h5>
                                        <button className="btn-close" onClick={() => setShowModel(!showModal)}></button>
                                    </div>
                                    <div className="modal-body border-0">
                                        The CLI is currently under development. Stay
                                        tuned for updates!
                                    </div>
                                    <div className="modal-footer border-0">
                                        <button className="btn btn-sm btn-danger" onClick={() => setShowModel(!showModal)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* Installation Instructions Section */}
                <div className="container my-5">
                    <h2 className="text-center mb-4">Installation Instructions</h2>

                    {/* Small Screen Layout */}
                    <div className="d-md-none">
                        <div className="accordion" id="installationAccordion">
                            {["Windows", "macOS", "Linux"].map((os, index) => (
                                <div key={os} className="accordion-item">
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className={`accordion-button ${openSection === os ? "" : "collapsed"}`}
                                            type="button"
                                            onClick={() => handleToggle(os)}
                                        >
                                            For {os}
                                        </button>
                                    </h2>
                                    <Collapse in={openSection === os}>
                                        <div id={`collapse${index}`} className="accordion-collapse collapse show">
                                            <div className="accordion-body">{getInstructions(os)}</div>
                                        </div>
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Large Screen Layout */}
                    <div className="d-none d-md-block">
                        <div style={{ cursor: 'pointer' }} className="d-flex flex-wrap justify-content-around align-items-center py-3">
                            {["Windows", "macOS", "Linux"].map((os) => (
                                <div
                                    key={os}
                                    onClick={() => handleToggle(os)}
                                    className={`col bg-white d-flex ${openSection === os ? "border-bottom border-2 border-primary" : ""
                                        } justify-content-center align-items-center`}
                                >
                                    <h4 className="mb-0">For {os}</h4>
                                </div>
                            ))}
                        </div>
                        <Collapse in={openSection === "Windows"}>
                            <div className="card border-0 shadow mb-3">
                                <div className="card-body">{getInstructions("Windows")}</div>
                            </div>
                        </Collapse>
                        <Collapse in={openSection === "macOS"}>
                            <div className="card border-0 shadow mb-3">
                                <div className="card-body">{getInstructions("macOS")}</div>
                            </div>
                        </Collapse>
                        <Collapse in={openSection === "Linux"}>
                            <div className="card border-0 shadow mb-3">
                                <div className="card-body">{getInstructions("Linux")}</div>
                            </div>
                        </Collapse>
                    </div>
                </div>

                {/* Features Section */}
                <section className="mb-5 py-3">
                    <h2 className="fw-bold mb-4 text-center">Features</h2>
                    <div className="row text-center g-4">
                        <div className="col-md-4">
                            <div className="p-3 shadow border-0 rounded-3">
                                <h3 className="fw-bold">Task Integration</h3>
                                <p className="fs-5">Easily manage tasks directly from your terminal with intuitive commands.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-3 shadow border-0 rounded-3">
                                <h3 className="fw-bold">GitHub Sync</h3>
                                <p className="fs-5">Seamlessly sync with GitHub for commits and task tracking.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="p-3 shadow border-0 rounded-3">
                                <h3 className="fw-bold">Streamlined Workflow</h3>
                                <p className="fs-5">Save time with shortcuts and automated setup processes.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Usage Section */}
                <div className="container my-5">
                    <header className="text-center">
                        {/* <h1 className="display-4 fw-bold text-primary">DevSemble CLI</h1> */}
                        <h1>Usage Commands</h1>
                    </header>
                    <div className="py-3">
                        <h3>Login</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS login</span>
                            </p>
                            <p className="output mb-0">
                                Enter email: user@example.com<br />
                                Enter password: ******<br />
                                Login successful!
                            </p>
                        </div>
                        <p className="description">
                            Logs in the user to their DevSemble account.<br />
                            <span className="highlight">Usage:</span> Prompts for email and password, validates credentials, and saves the profile locally.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Logout</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS logout</span>
                            </p>
                            <p className="output mb-0">Profile cleared. Logged out successfully.</p>
                        </div>
                        <p className="description">
                            Logs out the user from their DevSemble account.<br />
                            <span className="highlight">Usage:</span> Clears the stored profile and removes the <code>.devSprofile</code> file.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Show Projects and Tasks</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS show-projects-tasks</span>
                            </p>
                            <p className="output mb-0">
                                1. Project Alpha<br />
                                2. Project Beta<br />
                                Select a project: 1<br />
                                Task ID 123 saved for Project Alpha.
                            </p>
                        </div>
                        <p className="description">
                            Displays the list of projects and allows task selection.<br />
                            <span className="highlight">Usage:</span> Fetches tasks from the API and saves the selected task’s unique ID locally.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Commit</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS commit &quot;Initial setup&quot;</span>
                            </p>
                            <p className="output mb-0">[main abc1234] Task 123: Initial setup</p>
                        </div>
                        <p className="description">
                            Creates a Git commit with the task’s unique ID appended to the message.<br />
                            <span className="highlight">Usage:</span> Ensures the commit message includes the task ID.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Push</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS push</span>
                            </p>
                            <p className="output mb-0">
                                Pushing to origin/main...<br />
                                Push successful!
                            </p>
                        </div>
                        <p className="description">
                            Pushes the current branch to Git.<br />
                            <span className="highlight">Usage:</span> Executes the <code>git push</code> command after necessary checks.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Update</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS update</span>
                            </p>
                            <p className="output mb-0">
                                Checking for updates...<br />
                                CLI is up-to-date!
                            </p>
                        </div>
                        <p className="description">
                            Checks for and applies updates to the CLI.<br />
                            <span className="highlight">Usage:</span> Downloads the latest version if available.
                        </p>
                    </div>
                    <div className="py-3">
                        <h3>Help</h3>
                        <div className="terminal">
                            <p>
                                <span className="prompt">$</span>{" "}
                                <span className="command">devS help</span>
                            </p>
                            <p className="output mb-0">
                                Available commands:<br />
                                - login<br />
                                - logout<br />
                                - show-projects-tasks<br />
                                - commit &lt;message&gt;<br />
                                - push<br />
                                - update<br />
                                - help
                            </p>
                        </div>
                        <p className="description">
                            Lists all available commands and their descriptions.<br />
                            <span className="highlight">Usage:</span> Displays the command list and usage information.
                        </p>
                    </div>
                </div>

                <section className="text-center py-4 bg-light rounded-3">
                    <p className="fs-5 text-danger fw-bold">
                        Note: This is the beta version of the devSemble CLI. Please report any issues to our support team.
                    </p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default CLIPage;
