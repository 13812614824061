import { apiUrl } from './../constants'

export const askQuestion = (data) => {
    // const token = getToken();
    return fetch(`${apiUrl}/ask`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // ...(token && { Authorization: `Bearer ${token}` }), // Add Authorization only if token exists
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((error) => Promise.reject(error));
            }
            return response.json();
        })
        .catch((err) => {
            console.error('Error submitting question:', err);
            throw err;
        });
};
