import { useEffect, useState } from "react";
import {
    applyTeam,
    getProjectsByTech,
    getPublicProject,
    searchProjects,
} from "../../actions/projectActions"; // Updated actions
import { CircularProgress, Collapse, TextField } from "@mui/material";
import { useAuthStore } from "../../store/store";

const DisplayPublicProjects = () => {
    const [techProjects, setTechProjects] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search bar
    const data = useAuthStore((state) => state.user);
    const setStateUser = useAuthStore((state) => state.setUser);
    const [error, setError] = useState({
        errorType: "",
        errorMessage: "",
    });

    const [displayUniqueTech, setDisplayUniqueTech] = useState(null)

    const [isExpanded, setIsExpanded] = useState(-1);
    const [showReqs, setShowReqs] = useState(-1);
    const [title, setTitle] = useState("");

    useEffect(() => {
        setLoading(true);
        const allTech = data?.profile?.workExprience
            .map((exp) => exp.techUsed || [])
            .flat();

        const uniqueTechUsed = [...new Set(allTech)];
        setDisplayUniqueTech(uniqueTechUsed)
        getProjectsByTech(uniqueTechUsed)
            .then((res) => {
                if (res.status && res.projects.length > 0) {
                    setTechProjects(res.projects.reverse());
                    setTitle("Projects Matching Your Tech Stack");
                } else {
                    setError({
                        ...error,
                        errorType: "TECH_PROJECTS_EMPTY",
                        errorMessage: "No projects found for your tech stack.",
                    });
                    setTitle("No Projects Found for Your Tech Stack");
                }
            })
            .catch((err) => console.error("Error fetching projects by tech:", err));

        getPublicProject()
            .then((res) => {
                if (res.status && res.projects.length > 0) {
                    setAllProjects(res.projects.reverse());
                } else {
                    setError({
                        ...error,
                        errorType: "ALL_PROJECTS_EMPTY",
                        errorMessage: "No public projects available.",
                    });
                }
            })
            .catch((err) => console.error("Error fetching public projects:", err))
            .finally(() => setLoading(false));
    }, [data]);

    useEffect(() => {
        if (searchTerm.trim() !== "") {
            setLoading(true);
            searchProjects(searchTerm)
                .then((res) => {
                    if (res.status && res.projects.length > 0) {
                        setTechProjects(res.projects.reverse());
                        setTitle(`Results for "${searchTerm}"`);
                        setError({ ...error, errorType: "", errorMessage: "" });
                    } else {
                        setError({
                            ...error,
                            errorType: "SEARCH_EMPTY",
                            errorMessage: "No projects match your search query.",
                        });
                    }
                })
                .catch((err) => console.error("Error searching projects:", err))
                .finally(() => setLoading(false));
        } else {
            // If searchTerm is empty, reset to all projects
            setTechProjects(allProjects);
            setTitle('')
        }
    }, [searchTerm]); // Triggered whenever searchTerm changes    

    const handleApplyTeam = (data) => {
        applyTeam(data)
            .then((response) => {
                if (response && response.updatedProfile) {
                    const updatedProfile = response.updatedProfile;
                    const currentUser = JSON.parse(localStorage.getItem("user"));

                    const updatedUser = {
                        ...currentUser,
                        profile: {
                            ...currentUser.profile,
                            teamApplied: updatedProfile.teamApplied,
                        },
                    };

                    localStorage.setItem("user", JSON.stringify(updatedUser));
                    setStateUser(updatedUser);
                } else {
                    console.error("Response does not contain updatedProfile.");
                }
            })
            .catch((err) => {
                console.error("Error applying to the team:", err);
            });
    };

    const displayedProjects = searchTerm ? techProjects : allProjects;

    return (
        <div className="col-md-10 col-12">
            <div className="mb-2">
                <TextField
                    className="bg-light w-100"
                    type="text"
                    label="Search projects"
                    value={searchTerm}
                    variant="outlined"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div>
                <h2 className="m-0">{title}</h2>
                <div className="col-12 d-flex pt-2 flex-wrap">
                    {displayUniqueTech?.map((tech, idx) => (
                        <div key={idx} className="badge bg-primary rounded-pill px-2 me-1">
                            <span className="fw-bold m-0 text-white">{tech}</span>
                        </div>
                    ))}
                </div>
            </div>
            {loading ? (
                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                    <div className="text-center">
                        <CircularProgress />
                        <div>Loading Projects, please wait...</div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-wrap justify-content-center">
                    {displayedProjects.length > 0 ? (
                        displayedProjects.map((project, index) => (
                            <div key={index} className="py-2 col-12">
                                <div className="shadow bg-white my-3 p-2 rounded-3">
                                    <div className="d-flex col-12 justify-content-between align-items-center">
                                        <div className="col-7">
                                            <h3 className="fw-bold text-dark">{project?.projectTitle}</h3>
                                        </div>
                                        <div className="col-5 d-flex flex-wrap justify-content-end align-items-center">
                                            {data?.profile?.teamJoined?.some((joined) => joined.projectId === project._id) ? (
                                                <span className="text-secondary fw-bold" disabled>
                                                    Joined
                                                </span>
                                            ) : data?.profile?.teamApplied?.some((applied) => applied.projectId === project._id) ? (
                                                <span className="text-warning fw-bold" disabled>
                                                    Applied
                                                </span>
                                            ) : (
                                                <button
                                                    className="btn btn-success rounded-pill fw-bold"
                                                    onClick={() =>
                                                        handleApplyTeam({
                                                            projectId: project._id,
                                                            profileId: data.profile._id,
                                                        })
                                                    }
                                                >
                                                    Apply
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex pb-4 pt-2 flex-wrap">
                                        {project?.projectTech.map((tech, idx) => (
                                            <div key={idx} className="badge bg-primary rounded-pill px-2 me-1">
                                                <span className="fw-bold m-0 text-white">{tech}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <p className="fs-5">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: isExpanded === index
                                                    ? project?.projectDescription
                                                    : `${project?.projectDescription?.substring(0, 100)}...`,
                                            }}
                                        />
                                        {project?.projectDescription?.length > 100 && (
                                            <small
                                                className="text-primary fw-bold"
                                                onClick={() => setIsExpanded(isExpanded === index ? -1 : index)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {isExpanded === index ? "Show Less" : "Show More"}
                                            </small>
                                        )}
                                    </p>
                                    <hr className="" />
                                    <div className="py-1">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div>
                                                <h4>Project Current Requirements</h4>
                                            </div>
                                            <div>
                                                {showReqs === index ? (
                                                    <button className="btn btn-danger rounded-pill btn-sm" onClick={() => setShowReqs(-1)}>
                                                        Close
                                                    </button>
                                                ) : (
                                                    <>
                                                        {project?.projectCurrentReq?.length === 0 ? (
                                                            <div className="text-center">
                                                                <span className="fs-5 text-danger">No requirements Added</span>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="btn btn-info rounded-pill btn-sm"
                                                                onClick={() => setShowReqs(index)}
                                                            >
                                                                Show
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <Collapse in={showReqs === index}>
                                            {project?.projectCurrentReq?.length !== 0 ? (
                                                <div>
                                                    {project?.projectCurrentReq?.map((p, index) => (
                                                        <div key={index}>
                                                            <div className="d-flex align-items-start justify-content-start">
                                                                <span className="fs-5 col-1 text-center">{index + 1}.</span>
                                                                <div className="col-9">
                                                                    <p className="m-0 fs-5">{p?.title}</p>
                                                                    <p className="m-0 fs-5" dangerouslySetInnerHTML={{ __html: p.desc }}></p>
                                                                </div>
                                                            </div>
                                                            {index !== project?.projectCurrentReq.length - 1 && <hr className="mx-2 mx-md-5" />}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="text-center">
                                                    <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                                </div>
                                            )}
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center">
                            <span className={`fw-bold fs-5 ${error.errorType === "EMPTY_LIST" ? "text-danger" : "text-warning"}`}>
                                {error.errorMessage}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DisplayPublicProjects;
