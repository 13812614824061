import {apiUrl} from "../constants"

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Safely retrieve user
    return user?.token || ""; // Return token or fallback to empty string
};

export const updateAdvAccount = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-advanced-account/${data.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}